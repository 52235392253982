import React, { useContext, useEffect } from 'react';
import { Spinner } from '@airbus/components-react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooksTypes';
import { RootState } from '../../../../store/store';
import { appContext } from '../../../../utils/context/userContext';
import { secureLS } from '../../../../utils/localStorageUtil/lsHelper';
import { createRepositoryTaskList } from '../../../../models/mpdRepositoryModel/mpdRepositoryAsyncThunk';
import MpdRepositoryCard from '../../../MpdRepositoryCard/MpdRepositoryCard';
import MpdEmptyDataComponent from '../../../Shared/MpdEmptyDataComponent/MpdEmptyDataComponent';
import MpdRepositoryEmtlDeltaCard from '../../../MpdRepositoryEmtlDeltaCard/MpdRepositoryEmtlDeltaCard';
import { CONSTANTS } from './constants';

function displayCardsClassStyle(selectedFiles: string[], banners: BannerType[], tab: string, noOfRecords: number) {
  let cssStyle = 'repository-list-container repository-list-container-default';

  if (selectedFiles.length && banners.length && tab === 'EMTL') {
    cssStyle = ('repository-list-container repository-list-container-bar-banner');
  } else if (selectedFiles.length && tab === 'EMTL') {
    cssStyle = ('repository-list-container repository-list-container-selection-bar');
  } else if (banners.length) {
    cssStyle = ('repository-list-container repository-list-container-banner');
  }

  if (noOfRecords > 3) {
    cssStyle += ' repository-scroll';
  }
  return cssStyle;
}

const MpdRepositoryEmtlList = () => {
  const dispatch = useAppDispatch();
  const {
    RepositoryTaskListDataType: repoTaskListData,
    RepositoryTaskListRowCountType: totalRowCount,
    RepositoryEMTLTabType: tab,
    RepositoryTaskListCurPageNumber: page,
    RepositoryTaskListRowsPerPage: rowsPerPage,
    RepositoryTaskListLoading: loading,
    RepositorySelectedFilesList: selectedFiles,
    RepositoryFilterGroup: filterGroup,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  const {
    banners,
  } = useAppSelector((state: RootState) => state.banner);
  const { email } = useContext(appContext);
  const acProgram = secureLS.get('ac_program');
  const displayCardsClass = displayCardsClassStyle(selectedFiles, banners, tab, totalRowCount);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    email !== '' && dispatch(createRepositoryTaskList(rowsPerPage, page, acProgram, filterGroup, tab)());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, email, filterGroup]);

  let emtlCards = (
    <div className={displayCardsClass}>
      {
        repoTaskListData && repoTaskListData.map((val: repositoryFileType) => {
          let dataList = (
            <MpdRepositoryCard
              key={val.filterModuleVersion}
              fileName={val.filename}
              createAt={val.createdAt}
              fileKey={val.filterModuleVersion}
            />
          );
          /* istanbul ignore else */
          if (tab !== 'EMTL') {
            dataList = (
              <MpdRepositoryEmtlDeltaCard
                key={val.filterModuleVersion}
                comparedFileName={val.filename}
                createAt={val.createdAt}
                fileKey={val.filterModuleVersion}
                inputFileName={val.inputFileName}
                refFileName={val.refFileName}
                hashKey={val.hashKey}
              />
            );
          }
          return dataList;
        })
      }
    </div>
  );

  const noDataForFilter = filterGroup.length > 0 && totalRowCount === 0;
  if (loading) {
    emtlCards = (<Spinner size="small" className="repository-spinner-cls" aria-label="repositorySpinner" />);
  } else if (noDataForFilter) {
    emtlCards = (<MpdEmptyDataComponent iconName={CONSTANTS.noDataForFilterIcon} title={CONSTANTS.noDataForFilter} subTitle={CONSTANTS.noDataForFilterSubTitle} />);
  } else if (totalRowCount === 0) {
    if (tab === 'EMTL') {
      emtlCards = (<MpdEmptyDataComponent iconName={CONSTANTS.emptyContentIconName} title={CONSTANTS.noExportedFiles} subTitle={CONSTANTS.noExportedFilesSubTitle} />);
    } else {
      emtlCards = (<MpdEmptyDataComponent iconName={CONSTANTS.emptyContentIconName} title={CONSTANTS.noDeltaFiles} subTitle={CONSTANTS.noDeltaFilesSubTitle} />);
    }
  }
  return emtlCards;
};

export default MpdRepositoryEmtlList;
