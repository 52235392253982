export const taskInfoArray = {
  header: [{
    title: 'Task Information',
    type: 'Label',
    align: 'left',
  },
  ],
  oldHeader: [{
    title: 'Task Information',
    type: 'Label',
    align: 'left',
  },
  ],
  body: [{
    label: 'Task Number',
    type: 'Label',
    value: '711321-01-1',
  },
  {
    label: '100% Threshold',
    type: 'Label',
    value: '7500.0 FC OR 15000.0 FH',
  },
  {
    label: 'Description',
    type: 'Label',
    value: 'GENERAL VISUAL INSPECTION OF FAN COWL DOORS HINGES AND SURROUNDING STRUCTURE',
  },
  {
    label: 'Source Task Reference',
    type: 'Label',
    value: 'MRB 711321-01-1',
  },
  {
    label: '100% Interval',
    type: 'Label',
    value: '7500.0 FC OR 15000.0 FH',
  },
  {
    label: 'Task Code',
    type: 'Label',
    value: 'GVI',
  },
  {
    label: 'Source',
    type: 'Label',
    value: 'MRB',
  },
  {
    label: '',
    type: 'Empty',
    value: '',
  },
  {
    label: 'Applicability',
    type: 'Label',
    value: ' ALL',
  },
  ],
  noOfElementsPerRow: 3,
  config: {
    className: 'card-full-screen card-min-height',
  },
};
