import {
  AC_SERIES, AC_TYPE, ATA_2D, ATA_4D, ENGINE_SERIES, ID_AIRCRAFT, TIME_FRAME_YEAR,
} from '../../models/programAnalysis/constants';

export const comboOptions = [
  {
    filterId: AC_TYPE,
    filterText: 'A/C Type',
  },
  {
    filterId: AC_SERIES,
    filterText: 'A/C Series',
  },
  {
    filterId: ID_AIRCRAFT,
    filterText: 'MSN Reported',
  },
  {
    filterId: TIME_FRAME_YEAR,
    filterText: 'Reporting Timeframe',
  },
  {
    filterId: ENGINE_SERIES,
    filterText: 'Engine Series',
  },
  {
    filterId: ATA_2D,
    filterText: 'ATA 2 Digits',
  },
  {
    filterId: ATA_4D,
    filterText: 'ATA 4 Digits',
  },
];

export const allFiltersKeys = [AC_TYPE, AC_SERIES, ID_AIRCRAFT, ENGINE_SERIES, ATA_2D, ATA_4D];

export const timeframes = ['2Y', '3Y', '5Y', '6Y', '8Y', '12Y'];

export const FILTER_DRAWER_OPEN_TRUTHY_VALUES = ['', '/'];
