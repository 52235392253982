/* eslint-disable  @typescript-eslint/no-explicit-any */
import { CHART_OPTIONS, COLOR_CODES } from './chartLib';
import { getMaxY, mergeStackData } from './stackedChartUtil';

type CustomLabelsType = {
  text: string;
  fillStyle: string;
  strokeStyle: string;
  textAlign: string;
  pointStyle: string;
};
/**
 *
 * @param title chart title
 * @param axisTitles chart axis titles
 * @param customLabels list of custom labels
 * @param maxY max limit of y axis
 * @returns chart cosmetic options
 */

const getChartOptions = (
  title: string,
  axisTitles: Array<string>,
  customLabels: CustomLabelsType[],
  maxY: number,
  details?: any,
) => ({
  plugins: {
    title: {
      display: true,
      text: title,
    },
    datalabels: {
      display: false,
    },
    legend: {
      labels: {
        usePointStyle: true,
        generateLabels: () => customLabels,
      },
      position: 'bottom' as const,
      align: 'center',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text: axisTitles && axisTitles.length ? axisTitles[0] : '',
        font: {
          weight: 'bold',
        },
      },
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text: axisTitles && axisTitles.length > 1 ? axisTitles[1] : '',
        font: {
          weight: 'bold',
        },
      },
      grace: '5%',
    },
    y1: {
      stacked: false,
      /* hiding the 2nd Y-axis if specific Y-axis request is present */
      display: !details.displayYAxis,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
      title: {
        display: !details.displayYAxis,
        text: axisTitles && axisTitles.length > 2 ? axisTitles[2] : '',
        font: {
          weight: 'bold',
        },
      },
      grace: '5%',
    },
    frAxis: {
      grid: {
        drawOnChartArea: false, // only want the grid lines for one axis to show up
      },
      position: 'right',
      title: {
        display: true,
        text: axisTitles && axisTitles.length > 2 ? axisTitles[2] : '',
        font: {
          weight: 'bold',
        },
      },
      grace: '5%',
    },
  },
});
/**
 *
 * @param mergedDatasets dataset after merging based on label
 * @param mixType type of charts to be plotted
 * @returns return chart data object to support mix chart
 */
const prepareMixedDataset = (mergedDatasets: Array<any>, mixType: any) => Object.keys(mergedDatasets).map((dataKey, id) => ({
  label: dataKey,
  data: mergedDatasets[dataKey as keyof typeof mergedDatasets],
  backgroundColor: COLOR_CODES[id],
  type: mixType && mixType.length === 0 ? '' : mixType[dataKey],
  maxBarThickness: CHART_OPTIONS.maxBarThickness,
}));
/**
 *
 * @param datasets chart dataset
 * @returns custom labels for chart with diff point styles
 */
export const getCustomLabels = (datasets: any[]) => datasets.map((dataset) => ({
  text: dataset.label,
  fillStyle: dataset.backgroundColor,
  strokeStyle: dataset.backgroundColor,
  textAlign: 'left',
  pointStyle: dataset.type === 'line' ? 'line' : 'rect',
}));
/**
 *
 * @param chartData => data from query
 * @param labelName => label which need to be segregated
 * @param chartTitle => chart title
 * @param details => other informations for options
 * @returns labels => chart labels tobe shown,
 * datasets=> used to prepare chart,
 * options=> chart cosmetic options
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
export const getMixedChartProps = (
  chartData: Array<any>,
  labelName: string,
  chartTitle: string,
  details?: any,
) => {
  const {
    mixType, axisTitles, yAxisArray, displayYAxis,
  } = details;
  const mergedDatasets = mergeStackData(chartData);
  const labels = mergedDatasets[labelName];
  delete mergedDatasets[labelName];
  const tempDataset = prepareMixedDataset(mergedDatasets, mixType);
  const datasetSize = tempDataset.length - 1;
  const datasets = tempDataset.map((item: any, index: number) => {
    let yAxisID = '';
    if (item.type === 'line') {
      yAxisID = displayYAxis;
    } else {
      yAxisID = (yAxisArray && yAxisArray[index]) || 'y';
    }
    return {
      ...item,
      yAxisID,
      ...{ borderColor: item.backgroundColor },
      ...{ order: datasetSize - index + 1 },
    };
  });
  const options = getChartOptions(
    chartTitle,
    axisTitles,
    getCustomLabels(datasets),
    details?.scales?.y?.max || getMaxY(datasets, 'mix'),
    details,
  );
  return { labels, datasets, options };
};
