import { initialState } from './effectiveMaintenenceTaskListSlice';

export const updateMpdGenInfoFulfilled = (state: effectiveMaintenenceTaskListState, action: MpdGenInfoAction) => ({
  ...state,
  EffectiveMaintenenceTaskListIssueData: [
    {
      title: 'Issue',
      value: action.payload.data.Issue,
    },
    {
      title: 'Issue Date',
      value: action.payload.data['Issue Date'],
    },
  ],
  EffectiveMaintenenceTaskListEstablishedFrom: [
    {
      title: 'MPD Revision',
      value: action.payload.data['MPD Revision'],
    },
    {
      title: 'Revision Date',
      value: action.payload.data['Revision Date'],
    },
  ],
});

export const updateEffectiveMaintenenceTaskListRejected = (state: effectiveMaintenenceTaskListState, action: emtlGeneralInfoRejectedAction) => ({
  ...state,
  EffectiveMaintenenceTaskListIssueData: initialState.EffectiveMaintenenceTaskListIssueData,
  EffectiveMaintenenceTaskListEstablishedFrom: initialState.EffectiveMaintenenceTaskListEstablishedFrom,
  error: action.payload || action.error?.message,
});
