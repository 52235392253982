/* eslint-disable @typescript-eslint/no-explicit-any */
import { FETCH_MPD_TASKS, CREATE_MPD_FILTER, FILTER_API_PATH } from './constants';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { Methods } from '../../utils/customHooks/apiTypes';

export const createMpdFilter: any = (acProgramValue: string) => {
  const customAction = {
    sliceName: FETCH_MPD_TASKS,
    actionType: CREATE_MPD_FILTER,
  };
  const apiRequestObj = {
    path: FILTER_API_PATH,
    method: 'post' as Methods,
    body: {
      maintenance_program: acProgramValue,
      module_name: 'customization',
      select: [
        'ac_series',
        'msn_registration',
      ],
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
