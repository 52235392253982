import {
  Pie, Line, Bar, Doughnut, Radar, PolarArea, Scatter, Bubble, Chart,
} from 'react-chartjs-2';
import { CHART_TYPE } from './chartLib';
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getChartComponent = (chartType:string) : any => {
  const COMPONENT_MAP = {
    [CHART_TYPE.PIE]: Pie,
    [CHART_TYPE.LINE]: Line,
    [CHART_TYPE.BAR]: Bar,
    [CHART_TYPE.DOUGHNUT]: Doughnut,
    [CHART_TYPE.RADAR]: Radar,
    [CHART_TYPE.POLARAREA]: PolarArea,
    [CHART_TYPE.BUBBLE]: Bubble,
    [CHART_TYPE.SCATTER]: Scatter,
    [CHART_TYPE.MIX]: Bar,
    [CHART_TYPE.STACKED]: Bar,
    [CHART_TYPE.CHART]: Chart,
  };
  return COMPONENT_MAP[chartType as keyof typeof COMPONENT_MAP] ? COMPONENT_MAP[chartType as keyof typeof COMPONENT_MAP] : ('');
};
