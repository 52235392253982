import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Divider,
  Checkbox,
  Button,
} from '@airbus/components-react';
import './MpdHomepageDisclaimerDialog.scss';

/* eslint-disable react/no-danger */
type mpdHomepageDisclaimerDialogProps = {
  open: boolean;
  onClose: () => void;
  data: string;
};

const MpdHomepageDisclaimerDialog = (props: mpdHomepageDisclaimerDialogProps) => {
  const {
    open, onClose, data,
  } = props;

  const [isChecked, setIsChecked] = useState(false);
  const renderfun = () => {
    return (
      <div className="" dangerouslySetInnerHTML={{ __html: atob(data) }} />
    );
  };

  return (
    <Dialog className="homepage-disclaimer-dialog-cls homepage-disclaimer-dialog-cls-custom" open={open}>
      <DialogHeader><b>Disclaimer</b></DialogHeader>
      <Divider />
      <DialogContent>
        {renderfun()}
      </DialogContent>
      <Divider />
      <div className="homepage-disclaimer-footer">
        <Checkbox aria-label="homepage-disclaimer-checkbox" className={isChecked ? 'homepage-disclaimer-checkbox homepage-disclaimer-checkbox-custom' : ''} label="I hereby agree to and accept terms and conditions herein" checked={isChecked} onChange={() => { setIsChecked(!isChecked); }} />
        <Button aria-label="homepage-disclaimer-proceed" className="homepage-disclaimer-button" variant="primary" onClick={onClose} disabled={!isChecked}>Proceed</Button>
      </div>
    </Dialog>
  );
};

export default MpdHomepageDisclaimerDialog;
