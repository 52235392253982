const headerTabs = [
  {
    id: 1,
    title: 'Home',
    routeTo: '/',
    disabled: false,
  },
  {
    id: 2,
    title: 'Program Customization',
    routeTo: '/mpd-customise',
    disabled: false,
  },
  {
    id: 3,
    title: 'Program Analysis',
    routeTo: '/program-analysis',
    disabled: false,
  },
  {
    id: 4,
    title: 'Repository',
    routeTo: '/repository',
    disabled: false,
  },
];

export default headerTabs;
