/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, useMemo, FunctionComponent, useContext, useCallback,
} from 'react';
import { Button, Container } from '@airbus/components-react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@airbus/icons/react';

import { fetchTaskInfo } from '../../models/taskReportModel/taskreportAsyncThunks';
import { clearPrecomputeRawTask, clearTaskReportState, resetMetaData } from '../../models/taskReportModel/taskReportSlice';
import { getTaskInfoCardBody } from '../../models/taskReportModel/taskReportUtils';
import CardComponent from '../../components/Shared/Card/CardComponent';
import EmptyContent from '../../components/Shared/EmptyContent/EmptyContent';
import { taskInfoArray } from '../../__mocks__/TaskReportAnalysis/Task';
import TabsUI from '../../components/Shared/Tabs/TabsUI';
import { tabs } from '../../__mocks__/TaskReportAnalysis/Tabs';
import { RootState } from '../../store/store';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { getBuild, getBuildMetadata, getPAFilters } from '../../models/programAnalysis/programAnalysisAsyncThunks';
import { updateTasksObject } from '../../models/programAnalysis/programAnalysisSlice';

import { BUILD_ID, MAINTENANCE_PROGRAM, OPERATOR_ICAO_CODE } from '../../models/programAnalysis/constants';
import BannerComponent from '../../components/Shared/BannerComponent/BannerComponent';
import { CSAF_SOURCE_DISCLAIMER, LSDR_TASK_DISCLAIMER, bannerLocationMap } from '../../models/bannerModel/constants';
import { addBanner, clearBannerLocation } from '../../models/bannerModel/bannerSlice';
import { appContext } from '../../utils/context/userContext';
import { featureSwitchConfig } from '../../utils/FeatureToggleUtil/FeatureToggleUtil';
import { updateTaskNumber } from '../../models/unscheduledEventsPrecompModel/unscheduledEventsPrecompSlice';
import './TaskReport.scss';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';
import { allFiltersKeys } from '../../components/FilterDrawer/constants';

function TaskReport() {
  const dispatch = useAppDispatch();
  const { userType } = useContext(appContext);
  const { taskNumber, filterId } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const task = useAppSelector((state: RootState) => state.task);
  const paObj = useAppSelector((state: RootState) => state.programAnalysis);
  const buildInfo = useAppSelector((state) => state.programAnalysis.build);

  const navigate = useNavigate();

  const cardBody = useMemo(() => getTaskInfoCardBody(task.taskInfo), [task.taskInfo]);

  // Feature Toggle for Tabs
  const tabsToggled = useCallback(featureSwitchConfig(
    { name: 'EnhancedAnalysis', userType },
    () => tabs(userType).tabs,
    () => tabs(userType).oldTabs,
  ), []);
  tabs(userType).tabs = useMemo(() => tabsToggled(), []);

  // Feature Toggle for TaskInfoArray
  const taskInfoArrayToggled = useCallback(featureSwitchConfig(
    { name: 'EnhancedAnalysis', userType },
    () => taskInfoArray.header,
    () => taskInfoArray.oldHeader,
  ), []);
  taskInfoArray.header = useMemo(() => taskInfoArrayToggled(), []);

  const isCalculable = task.taskInfo?.is_calculable;

  /** check if the task code is 'LSDR_TASK' */
  const isLSDRTask: boolean = useMemo(() => {
    return isCalculable?.includes('LSDR_TASK');
  }, [isCalculable]);

  /** check if the source type is 'CSAF_Source' */
  const isCSAFSource: boolean = useMemo(() => {
    return isCalculable?.includes('CSAF_source');
  }, [isCalculable]);

  useEffect(() => {
    // dispatch resetMetaData to clear the previous API status
    // resetMetaData helps the clear the previous apis calls stored in store
    dispatch(resetMetaData());
    // to clear the raw table state from store
    dispatch(clearPrecomputeRawTask());
    /* API - fetch task info for card */
    dispatch(fetchTaskInfo(filterId, taskNumber)());
    if (!paObj.tasks.data || paObj.tasks.data.length === 0) {
      /* we don't have data, must come from a manual refresh of the page, we get back the info we lost */
      dispatch(getBuildMetadata(filterId)());
      dispatch(getBuild(filterId)());
      dispatch(updateTasksObject({ selectedTaskReference: taskNumber }));
      dispatch(getPAFilters({ [OPERATOR_ICAO_CODE]: secureLS.get('operator'), [MAINTENANCE_PROGRAM]: secureLS.get('ac_program'), select: allFiltersKeys })({ initial: true }));
    }

    // Reset Unscheduled Events Task Number State
    return () => {
      dispatch(updateTaskNumber(''));
    };
  }, []);

  useEffect(() => {
    dispatch(clearBannerLocation(bannerLocationMap.PROGRAM_ANALYSIS));
    /** Display both the banners if conditions met */
    if (isLSDRTask) {
      dispatch(addBanner(LSDR_TASK_DISCLAIMER));
    }
    if (isCSAFSource) {
      dispatch(addBanner(CSAF_SOURCE_DISCLAIMER));
    }
  }, [isCalculable]);

  /** Display unauthorized content for whole page by hiding tabs if task info api throws 401 */
  if (task.httpStatus === 401) {
    return <EmptyContent title="Unauthorized access" subtitle="Either you don't have access to this build, or it doesn't exist" status="UNAUTHORIZED" />;
  }

  const areAPIsLoaded = () => {
    return !task.loading
      && !task.findingRateByGeoRegion?.loading
      && !task.taskInfoFleetCard?.loading
      && !task.taskInfoOperatorLevelReportReceivedYearly?.loading
      && !task.taskInfoOperatorLevelFindingChart?.loading
      && !task.taskInfoOperatorLevelFindingRateByMsn?.loading
      && !task.taskReportAnalysisOperatorCard?.loading
      && !task.taskReportByActualInterval?.loading
      && !task.taskReportByCummulativeInterval?.loading
      && !task.fleetWideFindingsByFindingType?.loading
      && !paObj.filters?.fetchInfo?.loading
      && !buildInfo.build?.loading
      && !paObj.tasks.fetchInfo?.loading;
  };

  return (
    <Container className="task-report-container-cls">
      <BannerComponent location={bannerLocationMap.PROGRAM_ANALYSIS} />
      <Button
        size="xlarge"
        onClick={() => {
          dispatch(clearTaskReportState());
          dispatch(clearBannerLocation(bannerLocationMap.PROGRAM_ANALYSIS));
          navigate(`/program-analysis/${buildInfo[BUILD_ID]}`);
        }}
        className="back-button"
        disabled={!areAPIsLoaded()}
      >
        {' '}
        <ArrowBack />
        Back to Overview
      </Button>
      <CardComponent cardHeader={taskInfoArray.header} cardBody={cardBody} noOfElementsPerRow={taskInfoArray.noOfElementsPerRow} config={taskInfoArray.config} loading={task.loading} status={task.status} />
      {!(isLSDRTask || isCSAFSource || task.loading) && (
        <>
          <TabsUI tabs={tabs(userType).tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          {tabs(userType).tabs[selectedTab].element}
        </>
      )}
    </Container>
  );
}

export default TaskReport as FunctionComponent;
