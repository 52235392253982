import React from 'react';
import {
  Col,
  Row,
  Stack,
  Tooltip,
} from '@airbus/components-react';
import './mpdTooltip.scss';
import { Info } from '@airbus/icons/react';
import { TooltipElementProps, TooltipIconDataList } from './mpdTooltipTypes';

/**
 * This Tooltip component is used to generate the pop up with a static title, data, columnWidth.
 *
 * To use this component, import this TooltipComponent in your file and add after the button to generate the pop up.
*/
/* eslint-disable react/no-array-index-key */

const TooltipComponent = (props: TooltipElementProps) => {
  const {
    title, data, placement,
  } = props;

  return (
    <div
      className="icon data-table-header-icon tooltip-icon-align"
      role="presentation"
      aria-label="HideIcon"
    >
      <Tooltip
        large
        title={title}
        className="header-info-tooltip"
        placement={placement}
        arrowClassName="header-info-tooltip-arrow"
        label={
          (
            typeof (data) === 'string'
              ? (
                <div style={{ width: '95%' }}>
                  {data}
                </div>
              )
              : (
                <Stack spacing="2-x" padding="2-x" className="bg-primaryblue-90">
                  {
                    data.data.map((element: TooltipIconDataList['data'][0], index) => (
                      <Row key={index}>
                        <Col xxs={data.columnWidth[0]}>{element.icon}</Col>
                        <Col xxs={data.columnWidth[1]}><span>{element.message}</span></Col>
                      </Row>
                    ))
                  }
                </Stack>
              )
          )
        }
      >
        <Info />
      </Tooltip>
    </div>
  );
};

TooltipComponent.defaultProps = {
  placement: undefined,
};

export default TooltipComponent;
