import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Container } from '@airbus/components-react';
import { WarningOutline } from '@airbus/icons/react';

import './AccessRestrictComponent.scss';

/**
 * Access restricted component when user doesnt have the access for the requesting page/component
 */
const AccessRestrictComponent = () => {
  const navigate = useNavigate();
  const title = 'Access Restricted';
  const message = 'We\'re sorry, but this page is not available to your profile.';
  return (
    <Container className="access-restrict-main">
      <div className="access-restrict-child access-restrict-icon">
        <WarningOutline />
      </div>
      <div className="access-restrict-child">
        <b>
          { title }
        </b>
      </div>
      <div className="access-restrict-child">
        { message }
      </div>
      <div className="access-restrict-child" style={{ marginTop: '1rem' }}>
        <Button variant="primary" size="small" onClick={() => navigate('/')}> Go to home page </Button>
      </div>
    </Container>
  );
};

export default AccessRestrictComponent;
