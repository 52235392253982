// Slice
export const PA_REPOSITORY_SLICE = 'PARepository';

// Async thunk actions
export const FETCH_REPOSITORY_DATA = 'fetchRepositoryData';
export const DELETE_BUILD = 'deleteBuild';
export const RETRY_BUILD = 'retryBuild';
export const UPDATE_TITLE = 'updateTitle';
export const CREATE_REPOSITORY_FILTER = 'createPARepositoryFilter';

// API Path
export const SCOPES_API_PATH = 'api/smpa/scopes';
export const PRECOMPUTE_API_PATH = 'api/precompute/scopes';
export const AC_TYPE_MAINTENANCE_PROGRAM_MAPPING :{[key:string]:string} = {
  A320: 'A320 Family',
  A319: 'A320 Family',
  A321: 'A320 Family',
  A318: 'A320 Family',
  A330: 'A330',
  A340: 'A340',
  A350: 'A350',
  A380: 'A380',
};

// Variables
export const ID = 'id';
export const VERSION = 'version';
export const DATASETS = 'datasets';
export const CSM_MPD_FILTERED = 'csm_mpd_filtered';
export const STATUS = 'status';
export const ERRORS = 'errors';
export const CREATION_TIME = 'creation_time';
export const BUILD_TYPE = 'build_type';
export const CREATED_BY = 'created_by';
export const OPERATOR_ICAO_CODE = 'operator_icao_code';
export const UPDATE_TIME = 'update_time';
export const USER_ORIGIN = 'user_origin';
export const TITLE = 'title';
export const NUMBER_OF_DAYS = 'number_of_days_old';
export const UPDATE_BY = 'update_by';
export const IMPACTED_BY_ANNEX12 = 'is_annex13_impacted';
