import { createSlice } from '@reduxjs/toolkit';
import {
  updateEffectiveMaintenenceTaskListRejected,
  updateMpdGenInfoFulfilled,
} from './effectiveMaintenenceTaskListReducers';
import { createMpdGeneralInfo } from './effectiveMaintenenceTaskListAsyncThunk';

export const initialState: effectiveMaintenenceTaskListState = {
  EffectiveMaintenenceTaskListIssueData: [
    {
      title: 'Issue',
      value: '',
    },
    {
      title: 'Issue Date',
      value: '',
    },
  ],
  EffectiveMaintenenceTaskListEstablishedFrom: [
    {
      title: 'MPD Revision',
      value: '',
    },
    {
      title: 'Revision Date',
      value: '',
    },
  ],
  error: '',
};

const effectiveMaintenenceTaskListSlice = createSlice({
  name: 'effectiveMaintenenceTaskList',
  initialState,
  reducers: {
    // sync action creators
  },
  // Async action creators
  extraReducers: (builder) => {
    builder.addCase(createMpdGeneralInfo().fulfilled, updateMpdGenInfoFulfilled);
    builder.addCase(createMpdGeneralInfo().rejected, updateEffectiveMaintenenceTaskListRejected);
  },
});

export default effectiveMaintenenceTaskListSlice;
