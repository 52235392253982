import React, {
  Dispatch, ReactElement, SetStateAction,
} from 'react';
import {
  Stack,
  Tab,
  Tabs,
} from '@airbus/components-react';
import Crown from '../Crown/Crown';
import './TabsUI.scss';

interface TabsProp {
  tabName: string;
  iconName: string;
  route: string;
  componentMaped: string;
  isPremimum: boolean;
  class: string;
  path: string;
  element: ReactElement;
  disable ?: boolean;
}

interface TabsProps {
  tabs: TabsProp[];
  selectedTab: number;
  setSelectedTab: Dispatch<SetStateAction<number>>;
}

export default function TabsUI(props: TabsProps): JSX.Element {
  const { tabs, selectedTab, setSelectedTab } = props;

  return (
    <Stack className="bg-coolgrey-10 tab-ui-cls">
      <Tabs
        className="taskReportTabs"
        aria-label="Tabs purpose label"
        variant="container"
        value={selectedTab}
        onChange={(event, value) => setSelectedTab(value)}
      >
        {
          tabs.map(
            (tab) => (
              <Tab
                key={tab.class}
                className="p-4 tab-ui"
                icon={tab.isPremimum && <Crown />}
                disabled={tab?.disable}
              >

                <b>
                  {tab.tabName}
                </b>
              </Tab>
            ),
          )
        }
      </Tabs>
    </Stack>
  );
}
