import React from 'react';
import { Chip, GridSize } from '@airbus/components-react';

export const emtlDeltaViewTooltipData = [
  {
    icon: (<span className="header-info-dot" />),
    message: (<span className="delta-no-change">MSN is listed in both files</span>),
  },
  {
    icon: (<span className="header-info-dot success" />),
    message: (<span className="delta-added">MSN is added in the reference file</span>),
  },
  {
    icon: (<span className="header-info-dot error" />),
    message: (<span className="delta-deleted">MSN is deleted from the reference file</span>),
  },
];

export const comparePopupTooltipData = [
  {
    icon: (<Chip label="U" style={{ backgroundColor: '#919cb0', color: '#fff' }} />),
    message: 'Aircraft series/MSN is listed in both files',
  },
  {
    icon: (<Chip label="N" style={{ backgroundColor: '#255fcc', color: '#fff' }} />),
    message: 'Aircraft series/MSN is added in the reference file',
  },
  {
    icon: (<Chip label="D" style={{ backgroundColor: '#f23346', color: '#fff' }} />),
    message: 'Aircraft series/MSN is deleted from the reference file',
  },
];

export const socTableTooltipData = [
  {
    icon: (<Chip label="R" style={{ backgroundColor: '#919cb0', color: '#fff' }} />),
    message: 'Revised in the reference file.',
  },
  {
    icon: (<Chip label="N" style={{ backgroundColor: '#255fcc', color: '#fff' }} />),
    message: 'New in the reference file.',
  },
  {
    icon: (<Chip label="D" style={{ backgroundColor: '#f23346', color: '#fff' }} />),
    message: 'Deleted from the reference file.',
  },
];

export const chipColumnWidth:GridSize[] = [2, 10];
