import React from 'react';

export const userContext = {
  userDetails: {
    userType: '',
    icao: '',
    userAccess: '',
    email: '',
    firstname: '',
    lastname: '',
    loader: true,
  },
};

export const appContext = React.createContext(
  userContext.userDetails,
);
