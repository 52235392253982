/* eslint-disable @typescript-eslint/no-explicit-any */
import { Methods } from '../../utils/customHooks/apiTypes';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import {
  BELL_NOTIFICATION, CREATE_NOTIFICATION_DATA, UPDATE_NOTIFICATIONS_AS_READ, GET_NOTIFICATION_API_PATH, PUT_NOTIFICATION_API_PATH,
} from './constants';

export const fetchNotificationData: any = (offset: number, limit: number) => {
  const customAction = {
    sliceName: BELL_NOTIFICATION,
    actionType: CREATE_NOTIFICATION_DATA,
  };
  const apiRequestObj = {
    path: `${GET_NOTIFICATION_API_PATH}?offset=${offset}&limit=${limit}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const updateNotificationsAsRead: any = (notificationIds: string[]) => {
  const customAction = {
    sliceName: BELL_NOTIFICATION,
    actionType: UPDATE_NOTIFICATIONS_AS_READ,
  };
  const apiRequestObj = {
    path: `${PUT_NOTIFICATION_API_PATH}`,
    method: 'put' as Methods,
    body: {
      notificationIds,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
