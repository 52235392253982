import { createSlice } from '@reduxjs/toolkit';
import { NotificationsState } from './BellNotificationTypes';
import { BELL_NOTIFICATION } from './constants';
import {
  clearNotificationDataReducer, stopNotificationReducer, bellNotificationReducerFulfilled,
} from './BellNotificationReducer';
import { fetchNotificationData } from './BellNotificationAsyncThunk';

export const initialState: NotificationsState = {
  notifications: [],
  unreadCount: 0,
  isScrollStopped: false,
};

const BellNotificationSlice = createSlice({
  name: BELL_NOTIFICATION,
  initialState,
  reducers: {
    clearNotificationData: clearNotificationDataReducer,
    stopNotification: stopNotificationReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotificationData().fulfilled, bellNotificationReducerFulfilled);
  },
});

export const { clearNotificationData, stopNotification } = BellNotificationSlice.actions;
export default BellNotificationSlice;
