import { getStackedChartProps } from './stackedChartUtil';
import { getCircularChartProps } from './circularChartUtil';
import { getMixedChartProps } from './mixedChartUtil';
import { getLineChartProps } from './lineChartUtil';

export const getChartProps = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  chartData: Array<any>,
  labelName: string,
  type: string,
  chartTitle: string,
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  details: any,
): chartDataProps => {
  switch (type) {
    case 'stacked':
      return getStackedChartProps(chartData, labelName, chartTitle, details);
    case 'pie':
      return getCircularChartProps(chartData, labelName, chartTitle, type);
    case 'doughnut':
      return getCircularChartProps(chartData, labelName, chartTitle, type);
    case 'line':
      return getLineChartProps(chartData, labelName, chartTitle);
    case 'mix':
      return getMixedChartProps(chartData, labelName, chartTitle, details);
    default:
      return { labels: [], datasets: [], options: {} };
  }
};
