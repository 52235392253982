export const updateEnhanceStateReducer = (state: unscheduledEventState) => (
  {
    ...state,
    enhanceState: !state.enhanceState,
  }
);

export const updateOIDataReducer = (state: unscheduledEventState, action: unscheduledEventAction) => (
  {
    ...state,
    oiData: action.payload.data ? action.payload.data : [],
    loading: false,
    responseMessage: action.payload.message as string,
  }
);

export const clearOIDataReducer = (state: unscheduledEventState) => (
  {
    ...state,
    oiData: [],
  }
);

export const updateOIDataTaskReducer = (state: unscheduledEventState, action: unscheduledEventAction) => (
  {
    ...state,
    oiTaskData: action.payload.data ? action.payload.data : [],
    loading: false,
    responseMessage: action.payload.message as string,
  }
);

export const clearOIDataTaskReducer = (state: unscheduledEventState) => (
  {
    ...state,
    oiTaskData: [],
  }
);

export const updateTableDataReducer = (state: unscheduledEventState, action: unscheduledEventAction) => {
  return (
    {
      ...state,
      tableData: action.payload.data ? action.payload.data : [],
      responseMessage: action.payload.message as string,
    }
  );
};

export const updateTableDataSearchPairReducer = (state: unscheduledEventState, action: unscheduledEventTableFiltersAction) => ({
  ...state,
  tableFilters: {
    ...state.tableFilters,
    searchPairData: action.payload as searchPair,
  },
});

export const updateTableDataSearchOptionsReducer = (state: unscheduledEventState, action: unscheduledEventTableFiltersAction) => ({
  ...state,
  tableFilters: {
    ...state.tableFilters,
    searchOptions: action.payload as object[],
  },
});

export const updateTableDataFiltersGroupReducer = (state: unscheduledEventState, action: unscheduledEventTableFiltersAction) => {
  const sameFilter: searchPair[] = state.tableFilters.filterGroup.find((col) => col.columnName === (action.payload as searchPair[])[0].columnName) ? [] as searchPair[] : action.payload as searchPair[];
  return ({
    ...state,
    tableFilters: {
      ...state.tableFilters,
      filterGroup: [...state.tableFilters.filterGroup, ...sameFilter],
    },
  });
};

export const emptyTableFiltersGroupReducer = (state: unscheduledEventState) => ({
  ...state,
  tableFilters: {
    ...state.tableFilters,
    filterGroup: [],
  },
});

export const deleteTableDataFiltersGroupReducer = (state: unscheduledEventState, action: unscheduledEventTableFiltersAction) => ({
  ...state,
  tableFilters: {
    ...state.tableFilters,
    filterGroup: state.tableFilters.filterGroup.filter((element) => element.columnName !== (action.payload as searchPair[])[0].columnName),
  },
});

export const updateOIDataReducerPending = (state: unscheduledEventState) => ({
  ...state,
  loading: true,
});

export const updateOIDataReducerRejected = (state: unscheduledEventState) => ({
  ...state,
  responseMessage: 'rejected',
});

export const updateOIDataTaskReducerPending = (state: unscheduledEventState) => ({
  ...state,
  loading: true,
});

export const updateOIDataTaskReducerRejected = (state: unscheduledEventState) => ({
  ...state,
  responseMessage: 'rejected',
});

export const updateTableDataReducerPending = (state: unscheduledEventState) => ({
  ...state,
  loading: true,
});

export const updateTableDataReducerRejected = (state: unscheduledEventState) => ({
  ...state,
  responseMessage: 'rejected',
});
