import { PARepositoryAction } from '../programAnalysisRepository/PARepositoryTypes';
import { PABuildListState, updatePABuildListAction } from './PABuildListTypes';

export const updatePABuildListReducerFulfilled = (state: PABuildListState, action: PARepositoryAction) => ({
  ...state,
  BuildIdList: (action.payload.data.map((item) => item.id)),
  BuildIdListCount: (action.payload.data.map((item) => item.id)).length,
  loading: false,
});

export const updatePABuildListReducerPending = (state: PABuildListState) => ({
  ...state,
  loading: true,
});

export const updatePABuildListReducerRejected = (state: PABuildListState) => ({
  ...state,
  loading: true,
  error: 'rejected',
});

export const updatePABuildListReducer = (state: PABuildListState, action: updatePABuildListAction) => ({
  ...state,
  BuildIdList: action.payload,
});

export const clearPABuildListReducer = (state: PABuildListState) => ({
  ...state,
  BuildIdList: [],
  BuildIdListCount: 0,
});
