/* eslint-disable  @typescript-eslint/no-explicit-any */
import { CHART_OPTIONS, COLOR_CODES } from './chartLib';

/**
 * Function to sum up the similar index values of multiple arrays
 * @param chartDataObj chart dataset object
 * @returns array of vertical sum of arrays
 */
const getDataArrSum = (chartDataObj: Array<any>) => chartDataObj.reduce(
  (acc, dataset) => dataset.data.map(
    (value: string, index: number) => Number(value) + (Number(acc[index]) || 0),
  ),
  [],
);
/**
 *
 * @param chartDataObj chart dataset object
 * @param type chart type
 * @returns returns the maximum Y axis value in a chart
 */
export const getMaxY = (chartDataObj: Array<any>, type?: string) => {
  let maxY;
  const increaseLimit = (val: number) => Math.abs(val + val / 10);
  if (type === 'mix') {
    const stackBarDatasets = chartDataObj.filter((data) => data.type === 'bar');
    const otherDatasetsMax = chartDataObj.map((data) => ((data.type !== 'bar' && Array.isArray(data.data)) ? Math.max(...data.data) : 0));
    maxY = Math.max(...getDataArrSum(stackBarDatasets), ...otherDatasetsMax);
  } else {
    maxY = Math.max(...getDataArrSum(chartDataObj));
  }
  return increaseLimit(maxY);
};
/**
 *
 * @param title chart title
 * @param datasets chart dataset
 * @param axisTitles titles for different axis
 * @param details details of the graph
 * @returns prepare chart options
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
export const getChartOptions = (
  title: string,
  datasets: Array<any>,
  axisTitles?: any,
  details?: any,
) => ({
  plugins: {
    title: {
      display: true,
      text: title,
    },
    datalabels: {
      display: false,
    },
    legend: {
      position: 'bottom' as const,
      display: true,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text:
          axisTitles && axisTitles.length && axisTitles[0] ? axisTitles[0] : '',
        font: {
          weight: 'bold',
        },
      },
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text:
          axisTitles && axisTitles.length && axisTitles[1] ? axisTitles[1] : '',
        font: {
          weight: 'bold',
        },
      },
      grace: '5%',
    },
  },
});
/**
 * merges the data at similar level from array of objects
 * @param chartData chart dataset as array of object
 * @returns single object with merged data as array
 */
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const mergeStackData = (chartData: Array<any>) => chartData.reduce((acc, data) => {
  Object.keys(data).forEach((key) => {
    acc[key] = data[key];
  }, {});
  return acc;
}, {});
/**
 * prepares the acceptable stacked object for chart
 * @param mergedDatasets
 * @returns
 */
export const prepareStackedDataset = (mergedDatasets: Array<any>, multiColourFlag ? : boolean) => Object.keys(mergedDatasets).map((dataKey, id) => ({
  label: dataKey,
  data: mergedDatasets[dataKey as keyof typeof mergedDatasets],
  backgroundColor: multiColourFlag ? COLOR_CODES.slice(mergedDatasets.length) : COLOR_CODES[id],
  maxBarThickness: CHART_OPTIONS.maxBarThickness,
}));
/**
 *
 * @param chartData => data from query
 * @param labelName => label which need to be segregated
 * @param chartTitle => chart title
 * @param details => other informations for options
 * @returns labels => chart labels tobe shown,
 * datasets=> used to prepare chart,
 * options=> chart cosmetic options
 */
/* eslint-disable @typescript-eslint/no-unused-vars */
export const getStackedChartProps = (
  chartData: Array<any>,
  labelName: string,
  chartTitle: string,
  details?: any,
) => {
  const axisTitles = (details && details.axisTitles) || [];
  const multiColourFlag : boolean = details && details.multiColour;
  const hideLegends : boolean = details && details.hideLegends;
  const mergedDatasets = mergeStackData(chartData);
  const labels = mergedDatasets[labelName];
  delete mergedDatasets[labelName];
  const datasets = prepareStackedDataset(mergedDatasets, multiColourFlag);
  const options = getChartOptions(chartTitle, datasets, axisTitles, details);
  if (hideLegends) { options.plugins.legend = { display: false, position: 'bottom' }; }
  return { labels, datasets, options };
};
