export const filterColumn = ['mpd_100_threshold', 'mpd_100_interval'];
export const missingSBReport = 'missing_sb_report';
export const hasApplicabilityWarning = 'has_applicability_warning';
export const applicabilityText = 'applicability_text';
export const applicabilityTextTooltip = 'Free text applicability, the MSN effectivity list is conservative.';
export const missingSBReportTooltip = 'Reporting status is unknown.';
export const filterData = 'CE NOTE';
export const mpdTablewithoutAppendixData = {
  totalHits: 1,
  data: {
    records: [
      {
        mpd_task_revision_code: 'U',
        mpd_task_reference: '212141-01-1',
        mpd_task_revision_description: null,
        mpd_task_revision_justification: null,
        source_document_type: 'MRB L/HIRF',
        source_document_reference_new: null,
        source_document_reference: 'MRB L/HIRF 20-00-01-2\nMRB L/HIRF 20-00-01-3',
        related_tasks: null,
        access_point_number_list_panel: '\n521EB 521GB 522AB\n\n621EB 621GB 622AB',
        working_zone_preparation_list: 'SLATS FULLY EXTENDED',
        zone_number: '511\n520\n611\n620',
        mpd_description: 'HIRF/LIGHTNING\n\n PROTECTION-WING\n\n\n\nCHECK THAT THE CONNECTOR IS CORRECTLY\n\n TIGHTENED BY MEASUREMENT OF THE RESISTANCE WITH A LOOP TESTER: 4005VC, 4007VC, 4009VC, 4011VC, LH SIDE 4013VC, 4015VC, 4017VC, 4019VC, LH SIDE 4006VC, 4008VC, 4010VC, 4012VC, RH SIDE 4014VC, 4016VC, 4018VC, 4020VC, RH SIDE\n\n',
        mpd_task_skill_code: 'EL',
        mpd_task_code: 'FNC',
        sample_threshold: null,
        sample_interval: null,
        mpd_100_threshold: null,
        mpd_100_interval: 'CE NOTE',
        mpd_note: null,
        mpd_task_tci: '0',
        rsc: null,
        mpd_task_tps: 'Not Applicable',
        mpd_document_reference: 'AMM 249200-210-023',
        working_zone_nbr_persons: '1\n1\n1\n1',
        working_zone_men_hours: '0.50\n0.50\n0.50\n0.50',
        access_point_men_hours: '\n0.27\n\n0.27',
        working_zone_preparation_men_hours: '0.02',
        applicability_text: 'ALL',
        has_applicability_warning: true,
        effectivity_adjusted: null,
        missing_sb_report: '02636 02742 02744',
        effectivity_calculated: '02608 02642 02651 02703 02766 02787 03474 03495 03668 03717 03783 03899 03916 03917 03948 04177 04178 04229 04245 04257 04303 04343',
      },
    ],
    filter_data: [
      'Rev Code',
      'Source',
      'Skill Code',
      'TCI',
      'MEN',
    ],
    headers: [
      {
        Header: 'Rev Code',
        accessor: 'mpd_task_revision_code',
        width: 80,
      },
      {
        Header: 'MPD Task Number',
        accessor: 'mpd_task_reference',
        width: 120,
      },
      {
        Header: 'Revision Description',
        accessor: 'mpd_task_revision_description',
        width: 240,
      },
      {
        Header: 'Justification Description',
        accessor: 'mpd_task_revision_justification',
        width: 120,
      },
      {
        Header: 'Source',
        accessor: 'source_document_type',
        width: 120,
      },
      {
        Header: 'Source Document Reference',
        accessor: 'source_document_reference_new',
        width: 220,
      },
      {
        Header: 'Source Task Reference',
        accessor: 'source_document_reference',
        width: 180,
      },
      {
        Header: 'Related Task(s)',
        accessor: 'related_tasks',
        width: 180,
      },
      {
        Header: 'Access',
        accessor: 'access_point_number_list_panel',
        width: 180,
      },
      {
        Header: 'Preparation',
        accessor: 'working_zone_preparation_list',
        width: 180,
      },
      {
        Header: 'Zone',
        accessor: 'zone_number',
        width: 120,
      },
      {
        Header: 'Description',
        accessor: 'mpd_description',
        width: 350,
      },
      {
        Header: 'Skill Code',
        accessor: 'mpd_task_skill_code',
        width: 80,
      },
      {
        Header: 'Task Code',
        accessor: 'mpd_task_code',
        width: 80,
      },
      {
        Header: 'Sample Threshold',
        accessor: 'sample_threshold',
        width: 110,
      },
      {
        Header: 'Sample Interval',
        accessor: 'sample_interval',
        width: 100,
      },
      {
        Header: '100% Threshold',
        accessor: 'mpd_100_threshold',
        width: 110,
      },
      {
        Header: '100% Interval',
        accessor: 'mpd_100_interval',
        width: 110,
      },
      {
        Header: 'Note',
        accessor: 'mpd_note',
        width: 350,
      },
      {
        Header: 'TCI',
        accessor: 'mpd_task_tci',
        width: 90,
      },
      {
        Header: 'RSC',
        accessor: 'rsc',
        width: 90,
      },
      {
        Header: 'TPS',
        accessor: 'mpd_task_tps',
        width: 90,
      },
      {
        Header: 'Reference',
        accessor: 'mpd_document_reference',
        width: 110,
      },
      {
        Header: 'MEN',
        accessor: 'working_zone_nbr_persons',
        width: 90,
      },
      {
        Header: 'Task M.H.',
        accessor: 'working_zone_men_hours',
        width: 90,
      },
      {
        Header: 'Access M.H.',
        accessor: 'access_point_men_hours',
        width: 90,
      },
      {
        Header: 'Prep M.H.',
        accessor: 'working_zone_preparation_men_hours',
        width: 90,
      },
      {
        Header: 'Applicability',
        accessor: 'applicability_text',
        width: 180,
      },
      {
        Header: 'Effectivity Calculated',
        accessor: 'effectivity_calculated',
        width: 200,
      },
      {
        Header: 'Effectivity Adjusted',
        accessor: 'effectivity_adjusted',
        width: 200,
      },
      {
        Header: 'Missing SB report',
        accessor: 'missing_sb_report',
        width: 200,
      },
    ],
  },
  message: 'Success',
};
