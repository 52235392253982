/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { Navigate, Outlet } from 'react-router-dom';
import Loader from '../Loader/Loader';

function PrivateRoute(props: { isLoading: boolean }) {
  // Loading state for Feature Toggle API
  const { isLoading } = props;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>();
  const [stopLoading, setStopLoading] = useState(false);
  Hub.listen('auth', (data) => {
    // the user is redirected from onelogin
    const { payload } = data;
    switch (payload.event) {
      case 'signOut':
        setIsAuthenticated(false);
        break;
      case 'signIn':
        setIsAuthenticated(!!payload.data.username);
        break;
      default:
        break;
    }
  });
  useEffect(() => {
    // the users comes directly to signin
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setIsAuthenticated(!!user.username);
      })
      .catch(() => {
        // Stop loading when logged out, since `isLoading` prop won't become false.
        setStopLoading(true);
        setIsAuthenticated(false);
      });
  }, [isAuthenticated]);

  return (
    <div>
      {(!stopLoading && isLoading) ? (
        <Loader isBlocking={isLoading} />
      ) : isAuthenticated ? (
        <Outlet />
      ) : (
        <Navigate to="/signIn" />
      )}
    </div>
  );
}

export default PrivateRoute;
