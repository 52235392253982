import React, {
  Dispatch, SetStateAction,
} from 'react';
import {
  VisibilityOff,
} from '@airbus/icons/react';
import './DataTableHeader.scss';
import SkywiseCrown from '../SkywiseCrown/SkywiseCrown';

type DataTableHeaderProps = {
  title: string;
  id?: string;
  premium?: boolean;
  hideMode?: boolean;
  onClickHandler?: Dispatch<SetStateAction<string>>;
  tooltip?: JSX.Element;
};

/* eslint-disable operator-linebreak */
const DataTableHeader: React.FC<DataTableHeaderProps> = function HeaderComponent(props: DataTableHeaderProps) {
  const {
    id,
    title,
    premium,
    hideMode,
    onClickHandler,
    tooltip,
  } = props;

  const renderHideIcon = () => (
    <div
      className="icon data-table-header-icon"
      role="presentation"
      aria-label="HideIcon"
      onClick={() => {
        if (onClickHandler && id) {
          onClickHandler(id);
        }
      }}
    >
      <VisibilityOff />
    </div>
  );

  return (
    <>
      <p className="data-table-header-title">{title}</p>
      {hideMode && renderHideIcon()}
      {tooltip && !hideMode && tooltip}
      {premium && <SkywiseCrown />}
    </>
  );
};

DataTableHeader.defaultProps = {
  premium: false,
  hideMode: false,
  id: '',
  onClickHandler: () => null,
  tooltip: undefined,
};
export default DataTableHeader;
