import { sasPermissions } from '../userPermissionUtils/userPermissionSas';

interface PermissionMapperType {
  [key: string]: string[];
}

interface ModuleMapperType {
  [key: string]: string;
}

export const DASHBOARD_MODULE = 'dashboard';
export const PROGRAM_ANALYSIS_MODULE = 'ProgramAnalysis';
export const PROGRAM_CUSTOMIZATION_MODULE = 'ProgramCustomization';
export const PROGRAM_CUSTOMIZATION_REPOSITORY_MODULE = 'ProgramCustomizationRepository';

export const ALL_PERMISSION = '_ALL_';
export const SKYWISE_PERMISSION = '_SKYWISE_';
export const PREMIUM_PERMISSION = '_PREMIUM_';
export const MPE_PERMISSION = '_MPE_';

export const PERMISSION_MAPPER: PermissionMapperType = {
  [DASHBOARD_MODULE]: Object.values(sasPermissions.Dashboard) as string[],
  [PROGRAM_ANALYSIS_MODULE]: Object.values(sasPermissions.ProgramAnalysis) as string[],
  [PROGRAM_CUSTOMIZATION_MODULE]: Object.values(sasPermissions.ProgramCustomization) as string[],
  [PROGRAM_CUSTOMIZATION_REPOSITORY_MODULE]: Object.values(sasPermissions.ProgramCustomizationRepository) as string[],
};

export const MODULE_MAPPER: ModuleMapperType = {
  [DASHBOARD_MODULE]: '_DB_',
  [PROGRAM_ANALYSIS_MODULE]: '_PA_',
  [PROGRAM_CUSTOMIZATION_MODULE]: '_PC_',
  [PROGRAM_CUSTOMIZATION_REPOSITORY_MODULE]: '_PC_REPOSITORY_',
};
