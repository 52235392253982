import React from 'react';
import { Radio } from '@airbus/components-react';

type RadioBtnProps = {
  isSelected: boolean;
};

const RadioButton: React.FC<RadioBtnProps> = function RadioButton(props: RadioBtnProps) {
  const { isSelected } = props;

  return (
    <div
      style={{
        flex: 1,
        textAlign: 'center',
      }}
    >
      <Radio checked={isSelected} />
    </div>
  );
};

export default RadioButton;
