// Slice
export const MPD_REPOSITORY_TAST_LIST_SLICE = 'repositoryTaskList';

// Async thunk actions
export const FETCH_MPD_REPOSITORY_TAST_LIST_EXPORT = 'fetchMpdRepositoryTaskListExport';
export const CREATE_MPD_REPOSITORY_TAST_LIST_EXPORT = 'createMpdRepositoryTaskListExport';
export const FETCH_MPD_REPOSITORY_EXPORT_FILE = 'fetchMpdRepositoryExportFile';
export const CREATE_MPD_REPOSITORY_EXPORT_FILE = 'createMpdRepositoryExportFile';
export const CREATE_MPD_REPOSITORY_COMPARE_FILE = 'createMpdRepositoryCompareFile';
export const CREATE_MPD_REPOSITORY_COMPARE_DELTA_FILE = 'createMpdRepositoryCompareDDeltaFile';
export const FETCH_MPD_REPOSITORY_FILTER = 'fetchMpdRepositoryFilter';
export const CREATE_MPD_REPOSITORY_FILTER = 'createMpdRepositoryFilter';
export const FETCH_MPD_REPOSITORY_DELETE = 'createMpdRepositoryDelete';
export const FETCH_MPD_REPOSITORY_DELTA_VIEW = 'fetchMpdRepositoryDeltaView';
export const CREATE_MPD_REPOSITORY_DELTA_VIEW = 'createMpdRepositoryDeltaView';
export const FETCH_MPD_REPOSITORY_DELTA_VIEW_FILTER = 'fetchMpdRepositoryDeltaViewFilter';
export const CREATE_MPD_REPOSITORY_DELTA_VIEW_FILTER = 'createMpdRepositoryDeltaViewFilter';
export const FETCH_MPD_REPOSITORY_VIEW_GENERAL_INFO_DATA = 'fetchViewGeneralInfoData';
export const CREATE_MPD_REPOSITORY_VIEW_GENERAL_INFO_DATA = 'createViewGeneralInfoData';
export const FETCH_MPD_REPOSITORY_PREVIOUS_REVISION = 'fetchPreviousRevision';
export const CREATE_MPD_REPOSITORY_PREVIOUS_REVISION = 'createPreviousRevision';
export const FETCH_MPD_REPOSITORY_PREVIOUS_REVISION_EXPORT_TASK = 'fetchExportTask';
export const CREATE_MPD_REPOSITORY_PREVIOUS_REVISION_EXPORT_TASK = 'createExportTask';

// API Path
export const FETCH_MPD_REPOSITORY_TAST_LIST_API_PATH = 'api/repo/exportedfiles';
export const FETCH_MPD_REPOSITORY_EXPORT_FILE_API_PATH = 'api/repo/fileurl';
export const CREATE_MPD_REPOSITORY_COMPARE_FILE_PATH = 'api/repo/exportdeltaexcel';
export const FETCH_MPD_REPOSITORY_FILTER_API_PATH = 'api/repo/repofilter';
export const FETCH_MPD_REPOSITORY_DELETE_API_PATH = 'api/repo/removetask';
export const CREATE_MPD_REPOSITORY_COMPARE_DELTA_FILE_PATH = 'api/repo/repoexportdeltarecord';
export const CREATE_MPD_REPOSITORY_DELTA_VIEW_PATH = 'api/repo/exportview';
export const CREATE_MPD_REPOSITORY_DELTA_VIEW_FILTER_PATH = 'api/repo/viewfilterrecord';
export const CREATE_MPD_REPOSITORY_VIEW_GENERAL_INFO_PATH = 'api/repo/exportgeneralinfoview';
export const FETCH_MPD_REPOSITORY_PREVIOUS_REVISION_PATH = 'api/repo/previous-revision';
