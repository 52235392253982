import React from 'react';
import {
  Row, Col, Container, Typography,
} from '@airbus/components-react';
import './MpdContentData.scss';
import MpdTaskTable from '../../components/MpdTaskTable/MpdTaskTable';
import EffectiveMaintenanceTaskList from '../../components/EffectiveMaintenanceTaskList/EffectiveMaintenanceTaskList';

const MpdContentData = function MpdContentData() {
  return (
    <Container>
      <Row>
        <Col className="align-emtl-general-info-cls">
          <Typography variant="large">
            <b>EMTL General Information</b>
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col className="mpd-emtl-general-info-col">
          <EffectiveMaintenanceTaskList />
        </Col>
      </Row>
      <Row>
        <Col className="mpd-col-main-frame">
          <MpdTaskTable />
        </Col>
      </Row>
    </Container>
  );
};

export default MpdContentData;
