import React, { useState } from 'react';
import { Button, Typography } from '@airbus/components-react';
import { Delete } from '@airbus/icons/react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooksTypes';
import { RootState } from '../../../../store/store';
import {
  MPD_DELETE_MESSAGE, MPD_DELETE_PRIMARY_BUTTON_NAME, MPD_DELETE_SECONDARY_BUTTON_NAME, MPD_DELETE_TITLE, MPD_COMPARISON_FILE, MPD_COMPARISON_MESSAGE,
} from '../../../Shared/DialogComponent/Constants';
import { setButtonDisabled, updateSelectedFile } from '../../../../models/mpdRepositoryModel/mpdRepositorySlice';
import { addBanner, clearBannerLocation } from '../../../../models/bannerModel/bannerSlice';
import {
  REPO_COMPARE_FILE_DELETE_FAILED, REPO_COMPARE_FILE_DELETE_SUCCESS, REPO_FILE_DELETE_FAILED, REPO_FILE_DELETE_SUCCESS, bannerLocationMap,
} from '../../../../models/bannerModel/constants';
import { createMpdRepositoryDelete, createRepositoryTaskList } from '../../../../models/mpdRepositoryModel/mpdRepositoryAsyncThunk';
import DialogComponent from '../../../Shared/DialogComponent/DialogComponent';
import { secureLS } from '../../../../utils/localStorageUtil/lsHelper';

type mpdRepoListDeleteButtonProps = {
  fileName: string;
  fileKey: string;
  comparedFileName: string;
  inputFileName?: string;
  refFileName?: string;
};

const MpdRepositoryCardDelete = (props: mpdRepoListDeleteButtonProps) => {
  const {
    fileName, fileKey, inputFileName, refFileName, comparedFileName,
  } = props;
  const dispatch = useAppDispatch();
  const acProgram = secureLS.get('ac_program');
  const {
    RepositoryEMTLTabType: tab,
    RepositorySelectedFilesList: selectedFiles,
    RepositorySelectedFileKeys: selectedKeys,
    RepositoryButtonDisabled: btnsDisabled,
    RepositoryTaskListCurPageNumber: page,
    RepositoryTaskListRowsPerPage: rowsPerPage,
    RepositoryFilterGroup: filterGroup,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    dispatch(updateSelectedFile({ fileName: [], fileKey: [] }));
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let deleteTitle = MPD_DELETE_TITLE;
  let confirmationMessage = MPD_DELETE_MESSAGE;
  let fileTitle = (<b>{fileName}</b>);
  let disableFileKey = fileName;
  let deleteSuccess = REPO_FILE_DELETE_SUCCESS;
  let deleteFailed = REPO_FILE_DELETE_FAILED;
  if (tab === 'EMTL_DELTA') {
    deleteTitle = MPD_COMPARISON_FILE;
    confirmationMessage = MPD_COMPARISON_MESSAGE;
    fileTitle = (
      <>
        <b>{inputFileName}</b>
        <span>|</span>
        <b>{refFileName}</b>
      </>
    );
    disableFileKey = comparedFileName;
    deleteSuccess = REPO_COMPARE_FILE_DELETE_SUCCESS;
    deleteFailed = REPO_COMPARE_FILE_DELETE_FAILED;
  }

  const deleteMessage = (
    <Typography component="span">
      {confirmationMessage}
      <br />
      <div className="delete-file-title">{fileTitle}</div>
    </Typography>
  );

  const handleOnDelete = async () => {
    handleClose();
    dispatch(setButtonDisabled(disableFileKey));
    // Remove the file if its selected for comparison
    /* istanbul ignore else */
    if (tab === 'EMTL') {
      dispatch(updateSelectedFile({
        fileName: selectedFiles.filter((file) => file !== fileName),
        fileKey: selectedKeys.filter((key) => key !== fileKey),
      }));
    }

    const clearAndAddNewBanner = (banner: BannerType) => {
      dispatch(clearBannerLocation(bannerLocationMap.REPOSITORY_MODULE));
      dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
      dispatch(addBanner({
        ...banner,
        bannerMessage: banner.bannerMessage.replace('`<FILENAME>`', `"${fileName}"`),
      }));
    };

    try {
      await dispatch(createMpdRepositoryDelete(fileKey)()).unwrap();
      dispatch(createRepositoryTaskList(rowsPerPage, page, acProgram, filterGroup, tab)());
      clearAndAddNewBanner(deleteSuccess);
    } catch (error) {
      clearAndAddNewBanner(deleteFailed);
      dispatch(setButtonDisabled(''));
    }
  };

  return (
    <>
      <Button aria-label="mpdConfirmationDialog" aria-haspopup="dialog" className="action-btn-cls action-btn-cls-delete-button" onClick={handleOpen} disabled={btnsDisabled === disableFileKey}>
        <Delete className="action-btn-cls-delete-button-icon" />
      </Button>
      <DialogComponent
        message={deleteMessage}
        dialogTitle={deleteTitle}
        primmaryButtonName={MPD_DELETE_PRIMARY_BUTTON_NAME}
        secondaryButtonName={MPD_DELETE_SECONDARY_BUTTON_NAME}
        open={open}
        onClose={handleClose}
        onSubmit={handleOnDelete}
      />
    </>
  );
};

MpdRepositoryCardDelete.defaultProps = {
  inputFileName: '',
  refFileName: '',
};

export default MpdRepositoryCardDelete;
