/**
 * This component is used to show any error notification recieved from API.
 * Apart from error this component can be used to show any other messages as well.
 *
 * Currently this component is used in createCustomAsyncThunk file to catch error
 * response(apert from status code 200 and 201), also a local toast notification in EmtlCardsContainer file.
 */
import React, { useEffect } from 'react';
import { Toast } from '@airbus/components-react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearAllToast, clearToast, setToastStatus } from '../../../models/ToastModel/toastSlice';
import { useAppSelector } from '../../../store/hooksTypes';
import { RootState } from '../../../store/store';
import './ToastComponent.scss';

const ToastComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { toastList } = useAppSelector((state: RootState) => state.toastSlice);

  useEffect(() => {
    dispatch(clearAllToast());
  }, [location.pathname, dispatch]);

  const toastStyle = (message: toastObject, index: number) => {
    // For responsiveness
    let response = (toastList.length > 1 && index === 0) ? 'for-big-desktop-up ' : ' ';

    if (toastList.length < 2) {
      response += 'entry-single-toast';
    } else if (index === 0) {
      response += 'entry-first-toast';
    } else {
      response += index > 1 ? 'entry-third-toast' : 'entry-second-toast';
    }
    // For toast exit animation
    response += !message.status ? ' exit-toast' : '';
    return response;
  };

  const toastExitHandler = (index: number, message: toastObject) => {
    dispatch(setToastStatus({ id: message.toast.id, status: false }));
    setTimeout(() => {
      dispatch(clearToast(message.toast.id));
    }, 380);
  };

  return (
    <div className="toast-container-cls">
      {
        toastList.length ? (
          toastList.slice(0, 3).map((mes, index) => {
            const className = `${mes.toast.cssClass} ${toastStyle(mes, index)}`;
            return (
              <Toast
                allowClose
                inline
                className={className}
                variant={mes.toast.variant}
                onClose={() => toastExitHandler(index, mes)}
                title={mes.toast.title}
                key={mes.toast.id}
              >
                {mes.toast.content.map((cont) => {
                  return (
                    <div className="repo-tost-body-cls">
                      <span>{cont}</span>
                      <br />
                    </div>
                  );
                })}
              </Toast>
            );
          })
        ) : null
      }
    </div>
  );
};
export default ToastComponent;
