// Filter coloumn dropdown action
export const updateEmtlTableFilterReducer = (state: emtlTableState, action: emtlTableFilterColAction) => ({
  ...state,
  EmtlTableColumnType: action.payload,
  EmtlTableColumnDataOptionType: [],
  EmtlTableColSanitizedOptionType: [],
});

// Search dropdown action
export const updateEmtlTableFilterValueReducer = (state: emtlTableState, action: emtlTableFilterColAction) => ({
  ...state,
  EmtlTableColumnDataType: action.payload,
});

// Reset EMTL table data
export const resetMpdEmtlTableValueReducer = (state: emtlTableState) => ({
  ...state,
  loading: false,
  EmtlTableDataRowFilteredDataType: [],
  EmtlTableDataRowCountType: 0,
  error: '',
});

// Set the stae of emtl export button
export const setEmtlTableExportButtonStateReducer = (state: emtlTableState, action: emtlTableExportButtonAction) => ({
  ...state,
  EmtlTableExportButton: action.payload,
});

// Async reducers
// Search dropdown action
export const fetchEmtlTableFilterColumnValReducer = (state: emtlTableState, action: emtlTableFilterFullfilledAction) => ({
  ...state,
  EmtlTableColumnDataOptionType: action.payload.data.column_value,
  EmtlTableColSanitizedOptionType: action.payload.data.column_value.map((val: string) => (val.replace('\u00a0', ' ')).replace(/[\n\r]/g, ' ')),
});
// Async reducers
export const createMpdEmtlTableFilterPending = (state: emtlTableState) => ({
  ...state,
  loading: true,
});

export const createMpdEmtlTableFilterFulfilled = (state: emtlTableState, action: CreateMpdEmtlTableFilterFulfilledAction) => ({
  ...state,
  loading: false,
  EmtlTableDataRowFilteredDataType: action.payload.data.emtl_data as EmtlTableType[],
  EmtlTableFilterColType: action.payload.data.filter_data as string[],
  EmtlTableDataRowCountType: action.payload.totalHits,
  error: '',
});

export const createMpdTableFulfilled = (state: emtlTableState, action: CreateMpdTableFulfilledAction) => ({
  ...state,
  loading: false,
  EmtlTableDataRowFilteredDataType: action.payload.data.records as EmtlTableType[],
  EmtlTableHeaders: state.EmtlTableHeaders.EMTL.length === 0 ? action.payload.data.headers : state.EmtlTableHeaders,
  EmtlTableFilterColType: action.payload.data.filter_data as string[],
  EmtlTableDataRowCountType: action.payload.totalHits,
  error: '',
});

export const createMpdEmtlTableFilterRejected = (state: emtlTableState, action: CreateMpdEmtlTableFilterRejectedAction) => ({
  ...state,
  loading: false,
  EmtlTableDataRowFilteredDataType: [],
  error: action.payload || action.error?.message,
});

export const updateEmtlTableFilterGroupReducer = (state: emtlTableState, action: emtlFilterGroup) => ({
  ...state,
  EmtlTableFilterGroup: action.payload,
});

export const deleteOneEmtlTableFilterReducer = (state: emtlTableState, action: emtlFilterGroup) => ({
  ...state,
  EmtlTableFilterGroup: state.EmtlTableFilterGroup.filter((payload) => payload.filter_column !== action.payload[0].filter_column),
});

export const clearAllEmtlTableFilterReducer = (state: emtlTableState, action: emtlTableFilterColAction) => ({
  ...state,
  EmtlTableColumnType: action.payload === 'mpdSubmit' ? 'mpd_task_revision_code' : action.payload,
  EmtlTableFilterGroup: [],
  EmtlTableColumnDataOptionType: action.payload === 'mpdSubmit' ? [] : state.EmtlTableColumnDataOptionType,
  EmtlTableColSanitizedOptionType: action.payload === 'mpdSubmit' ? [] : state.EmtlTableColSanitizedOptionType,
});

export const createMpdEmtlTableExportTaskFulfilled = (state: emtlTableState, action: emtlTableExportTask) => ({
  ...state,
  EmtlTableExportId: action.payload.data.taskId,
});

export const createMpdEmtlTableExportTaskRejected = (state: emtlTableState) => ({
  ...state,
  EmtlTableExportId: 'error',
});

export const updateEmtlTablePageNumberReducer = (state: emtlTableState, action: emtlTablePageNumberAction) => ({
  ...state,
  EmtlTableDataPageNumberType: action.payload as number,
});
