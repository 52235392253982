import { Methods } from '../../../utils/customHooks/apiTypes';
import createCustomAsyncThunk from '../../../utils/thunkUtils/createCustomAsyncThunk';
import {
  CREATE_DELTA_VIEW_SOC_TABLE, CREATE_MPD_REPOSITORY_DELTA_VIEW_FILTER, CREATE_MPD_REPOSITORY_DELTA_VIEW_FILTER_PATH, DELTA_VIEW_SOC_PATH, FETCH_DELTA_VIEW_SOC, FETCH_MPD_REPOSITORY_DELTA_VIEW_FILTER,
} from './constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const createDeltaSocTableView: any = (hashKey: string, pageNumber: number, pageSize: number, filterGroup: Array<{ filter_column: string, filter_value: string }>) => {
  const customAction = {
    sliceName: FETCH_DELTA_VIEW_SOC,
    actionType: CREATE_DELTA_VIEW_SOC_TABLE,
  };

  const apiRequestObj = {
    path: `${DELTA_VIEW_SOC_PATH}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    method: 'post' as Methods,
    body: {
      hash_key: hashKey,
      filter_group: filterGroup,
    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const createDeltaSocTableViewFilter: any = (hashKey: string, filterGroup: repositoryFilterGroup) => {
  const customAction = {
    sliceName: FETCH_MPD_REPOSITORY_DELTA_VIEW_FILTER,
    actionType: CREATE_MPD_REPOSITORY_DELTA_VIEW_FILTER,
  };

  const apiRequestObj = {
    path: CREATE_MPD_REPOSITORY_DELTA_VIEW_FILTER_PATH,
    method: 'post' as Methods,
    body: {
      hash_key: hashKey,
      filter_group: filterGroup,
      filter_type: 'SUMMARY',
    },
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
