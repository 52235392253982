/* eslint-disable @typescript-eslint/no-explicit-any */
import { Methods } from '../../utils/customHooks/apiTypes';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import {
  CREATE_UE_FILTER,
  DATASET_TASK_AGGREGATES,
  FETCH_OI_DATA,
  FETCH_OI_TASK_DATA,
  FETCH_TABLE_DATA,
  SCOPES_API_PATH,
} from './constants';

export const fetchOIInfos: any = (filterId: string, dataset: string, taskType?:string, taskNumber?: string) => {
  const customAction = {
    sliceName: FETCH_OI_DATA,
    actionType: CREATE_UE_FILTER,
  };
  const apiRequestObj = {
    path: `${SCOPES_API_PATH}/${filterId}/datasets/${dataset}?${taskType}=${taskNumber}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchOITaskInfos: any = (filterId: string, taskNumber?: string) => {
  const customAction = {
    sliceName: FETCH_OI_TASK_DATA,
    actionType: CREATE_UE_FILTER,
  };
  const apiRequestObj = {
    path: `${SCOPES_API_PATH}/${filterId}/datasets/${DATASET_TASK_AGGREGATES}?mpd_task_reference=${taskNumber}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTableInfos: any = (filterId: string, dataset: string, taskType?:string, taskNumber?: string) => {
  const customAction = {
    sliceName: FETCH_TABLE_DATA,
    actionType: CREATE_UE_FILTER,
  };

  const apiRequestObj = {
    path: `${SCOPES_API_PATH}/${filterId}/datasets/${dataset}?${taskType}=${taskNumber}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
