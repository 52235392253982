// Slice
export const UNSCHEDULED_EVENT_SLICE = 'unscheduledEvent';

// Async thunk actions
export const FETCH_OI_DATA = 'fetchOIData';
export const FETCH_OI_TASK_DATA = 'fetchOITaskData';
export const CREATE_UE_FILTER = 'createUnscheduledEventFilter';
export const FETCH_TABLE_DATA = 'fetchTableData';

// API Path
export const SCOPES_API_PATH = 'api/smpa/scopes';

// Datasets
export const DATASET_DETAILS_ATA2D = 'smac_oi_by_ata2d_details';
export const DATASET_DETAILS_TADA = 'smac_tada_oi_by_mpd_details';
export const DATASET_ATA_2D_AGGREGATES = 'smac_oi_by_ata2d_aggregates';
export const DATASET_TASK_AGGREGATES = 'smac_finding_rate_by_mpd';
export const DATASET_TADA_AGGREGATES = 'smac_tada_oi_by_mpd_aggregates';

// Variables
export const AC_AGE = 'ac_age';
export const AC_AGE_FINDING_RATE = 'ac_age_finding_rate';
export const AC_AGE_FINDING_RATE_TADA = 'ac_age_finding_rate_tada';
export const ATA_2D = 'ata_2d';
export const MPD_TASK_REFERENCE = 'mpd_task_reference';
export const AVERAGE_OF_OI_BY_YEAR_FW = 'average_of_oi_by_year_for_fleet_wide';
export const AVERAGE_OF_OI_BY_YEAR_OPERATOR = 'average_of_oi_by_year_operator';
export const ID = 'id';
export const LAST_BUILD_TIME = 'last_build_time';
export const NB_OF_OI = 'number_of_oi';
export const NB_OF_OI_BY_AC_AGE = 'number_of_oi_by_ac_age';
export const NB_OF_OI_IN_CANCELLATION = 'number_of_oi_in_cancellation';
export const NB_OF_OI_IN_DELAY = 'number_of_oi_in_delay';
export const NB_OF_OI_IN_DIVERSION = 'number_of_oi_in_diversion';
export const NB_OF_OI_IN_FLIGHT_TURN_BACK = 'number_of_oi_in_flight_turn_back';
export const OI_AVERAGE_DELAY_IN_MINUTES = 'oi_average_delay_in_minutes';
export const OPERATOR_FINDING_RATE = 'operator_finding_rate';
export const RSI_BY_AC_AGE = 'rsi_by_ac_age';
export const RSI = 'rsi';
export const YEAR_FLEET_WIDE = 'year_fleet_wide';
export const YEAR_OPERATOR = 'year_operator';
export const AC_TYPE = 'ac_type';
export const ATA_PERFORMED = 'ata_performed';
export const ID_AIRCRAFT = 'id_aircraft';
export const OCCURENCE_DATE = 'occurrence_date';
export const OI_DESCRIPTION = 'oi_description';
export const OI_DURATION = 'oi_duration';
export const OI_RESOLUTION_DESCRIPTION = 'oi_resolution_description';
export const OI_TYPE = 'oi_type';
export const END_DATE = 'end_date';
export const START_DATE = 'start_date';
export const MAX_AC_AGE = 'max_current_ac_age';
export const OI_EVENT_ID = 'oi_event_id';
export const OI_DURATION_NAMING = 'Duration (min)';
