import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Col,
  Container,
  Stack,
  RangeSlider,
} from '@airbus/components-react';
import Chart from '../Charts/Chart';
import './RangeChart.scss';

type ChartComponentDropDown = {
  chartData: chartProps;
  rangeSelector: string;
  xLabel: string;
  status ?: string;
};

export default function RangeChart(props: ChartComponentDropDown) {
  const {
    rangeSelector, chartData, xLabel, status,
  } = props;
  const xAxis = chartData && chartData.chartDataset && chartData.chartDataset[0][`${xLabel}`];
  const dataSize = xAxis && xAxis.length;
  const defaultMax = dataSize <= 15 ? dataSize : 15;
  const [range, setRange] = useState({
    min: 0,
    max: defaultMax,
  });
  const [chartDS, setChartDS] = useState(
    dataSize ? chartData.chartDataset : [],
  );

  useEffect(() => {
    const dataForChart = chartData && chartData.chartDataset;
    const chartDatavar = [];
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const variable: { [key: string]: any[] } = {};
    /* eslint-disable @typescript-eslint/no-explicit-any */
    dataForChart.map((element: any) => {
      return Object.keys(element).forEach((key) => {
        variable[`${key}`] = element[`${key}`]?.slice(range.min, range.max) || [];
      });
    });
    chartDatavar.push(variable);
    setChartDS(dataSize ? chartDatavar : []);
  }, [range, dataSize, chartData]);

  const setThumbLabel = (id: number, val: number) => {
    const index = id !== 0 ? val - 1 : val;
    return dataSize ? chartData.chartDataset[0][`${xLabel}`][index] : val;
  };

  return (
    <div>
      <Stack className="chart-screen-cls">
        <Card className="chart-screen">
          <CardContent>
            <Container>
              <Col>
                <Chart
                  chartData={{ ...chartData, ...{ chartDataset: chartDS } }}
                  apiStatus={status}
                />
              </Col>
            </Container>
          </CardContent>
          {rangeSelector === 'slider' && (
            <div className="range-slider-container">
              <RangeSlider
                aria-label="chart-range-slider"
                max={dataSize}
                defaultValue={[0, defaultMax]}
                onChange={(value) => setRange({ min: value[0], max: value[1] })}
                getThumbLabel={(id, value) => setThumbLabel(id, value)}
              />
            </div>
          )}
        </Card>
      </Stack>
    </div>
  );
}

RangeChart.defaultProps = {
  status: null,
};
