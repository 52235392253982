import {
  Container,
  Row,
  Col,
} from '@airbus/components-react';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MpdFilterPanel from '../../components/MpdFilterPanel/MpdFilterPanel';
import './MpdCustomisation.scss';
import MpdContentData from '../MpdContentData/MpdContentData';
import MpdContent from '../../components/MpdContent/MpdContent';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { addBanner, clearBannerLocation } from '../../models/bannerModel/bannerSlice';
import { EFFECTIVITY_DISCLAIMER, bannerLocationMap } from '../../models/bannerModel/constants';
import BannerComponent from '../../components/Shared/BannerComponent/BannerComponent';
import { RootState } from '../../store/store';

const MpdCustomisation: React.FC = function MpdCustomisation() {
  const [showContent, setContentShown] = React.useState(false);
  const { banners } = useAppSelector((state: RootState) => state.banner);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Redirect to homepage if entered via URL
  useEffect(() => {
    if (!location.state) {
      navigate('/');
    }
  }, [location.state, navigate]);

  useEffect(() => {
    dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
    dispatch(addBanner(EFFECTIVITY_DISCLAIMER));
  }, [dispatch]);

  return (
    <>
      <BannerComponent location={bannerLocationMap.PROGRAM_CUSTOMISATION} />
      <Container className="mpd-container-cls">
        <Row>
          <Col className="mpd-col-drawer">
            <MpdFilterPanel showContent={setContentShown} isBannerShown={banners.length > 0} />
          </Col>
          <Col className="cust-col-main-frame">
            {
              !showContent ? <MpdContent /> : <MpdContentData />
            }
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MpdCustomisation;
