/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useContext } from 'react';
import {
  Button,
  Inline,
} from '@airbus/components-react';
import './MpdRepositorySelectedFileBar.scss';
import {
  Clear,
} from '@airbus/icons/react';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState, store } from '../../store/store';
import { resetPreviousRevisionBanner, updateEmtlPageNumber, updateSelectedFile } from '../../models/mpdRepositoryModel/mpdRepositorySlice';
import {
  createPreviousRevisionData, createRepoCompareDeltaTask, createRepositoryTaskList,
} from '../../models/mpdRepositoryModel/mpdRepositoryAsyncThunk';
import { getFilteredItems } from '../MpdTaskTable/mpdTableUtils';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';
import { addBanner, clearBannerLocation } from '../../models/bannerModel/bannerSlice';
import {
  COMPARE_CREATION_FAILED,
  COMPARE_CREATION_IN_PROGRESS,
  COMPARE_CREATION_SUCCESS,
  bannerLocationMap,
} from '../../models/bannerModel/constants';
import { TOAST_COMPONENT_TITTLE, TOAST_COMPONENT_MESSAGE1, TOAST_COMPONENT_MESSAGE2 } from '../Shared/Toast/ToastComponentConstants';
import { featureSwitchConfig } from '../../utils/FeatureToggleUtil/FeatureToggleUtil';
import { appContext } from '../../utils/context/userContext';
import FeatureErrorBoundry from '../Shared/FeatureErrorBoundry/FeatureErrorBoundry';
import { clearToast, createToast } from '../../models/ToastModel/toastSlice';
import { EMTL_TABLE_EXPORT_STATUS_POLLING_API_PATH } from '../../utils/APIPollingUtil/constants';
import { startPolling } from '../../utils/APIPollingUtil/APIPolling';
import { PollRequest } from '../../utils/APIPollingUtil/APIPollingTypes';
import { findRevision } from '../../models/mpdRepositoryModel/util';

const MpdRepositorySelectedFileBar = () => {
  const dispatch = useAppDispatch();
  const { userType } = useContext(appContext);
  const {
    RepositorySelectedFilesList: selectedFiles,
    RepositorySelectedFileKeys: selectedKeys,
    RepositoryTaskListRowsPerPage: repoEmtlRowsPerPage,
    RepositoryFilterGroup: filterGroup,
    RepositoryPreviousRevision,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  const { prevRevExportInProgress } = RepositoryPreviousRevision;
  const acProgram = secureLS.get('ac_program');
  const toastId = 'repoEmtlFileSelectionId';
  useEffect(() => {
    if (selectedFiles.length === 1 && !prevRevExportInProgress) {
      dispatch(createToast({
        id: toastId,
        cssClass: 'repo-tost-cls',
        variant: 'success',
        title: TOAST_COMPONENT_TITTLE,
        content: [TOAST_COMPONENT_MESSAGE1, TOAST_COMPONENT_MESSAGE2],
      }));
    } else {
      dispatch(clearToast(toastId));
    }

    return () => {
      dispatch(clearToast(toastId));
    };
  }, [selectedFiles, prevRevExportInProgress, dispatch]);

  // To handle 'previous revision' use cases
  useEffect(() => {
    const toggledPrevRev = featureSwitchConfig(
      { name: 'PreviousRevision', userType },
      () => dispatch(createPreviousRevisionData('EMTL', findRevision(selectedFiles[0]))()),
      () => null,
    );

    if (selectedFiles.length >= 2) {
      dispatch(resetPreviousRevisionBanner());
    } else if (selectedFiles.length === 1) {
      toggledPrevRev();
    }
  }, [selectedFiles, dispatch, userType]);

  const clearBannersOnTop = () => {
    dispatch(clearBannerLocation(bannerLocationMap.REPOSITORY_MODULE));
    dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
  };

  const emtlDeltaPollingPayload = (taskId: string, file1: string, file2: string, hashKey: string): PollRequest => {
    const clearBanners = () => {
      dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
      dispatch(clearBannerLocation(bannerLocationMap.PROGRAM_CUSTOMISATION));
    };

    const refreshRepository = (tab: string) => {
      /* istanbul ignore next */
      if (window.location.pathname === '/repository/emtl' && tab === 'EMTL_DELTA') {
        dispatch(updateEmtlPageNumber(1));
        dispatch(createRepositoryTaskList(repoEmtlRowsPerPage, 1, acProgram, filterGroup, tab)());
      }
    };

    const handleFailure = (error?: unknown) => {
      if (error) {
        dispatch(addBanner({
          ...COMPARE_CREATION_FAILED,
          bannerMessage: `${JSON.stringify(error)}: please check the EMTL Delta repository in sometime.`,
        }));
      } else {
        dispatch(addBanner({
          ...COMPARE_CREATION_FAILED,
          bannerMessage: COMPARE_CREATION_FAILED.bannerMessage
            .replace('`<FILENAME1>`', `"${file1}"`)
            .replace('`<FILENAME2>`', `"${file2}"`),
        }));
      }
    };

    return {
      data: {
        url: `${EMTL_TABLE_EXPORT_STATUS_POLLING_API_PATH}?export-task-id=${taskId}`,
        interval: 5500,
        successStatus: ['DONE'],
        failureStatus: ['FAIL', 'DELETE'],
      },
      onSuccess: () => {
        clearBanners();
        const bannerLink = { text: 'Check now', address: `/repository/emtl/view/${hashKey}` };
        dispatch(addBanner({
          ...COMPARE_CREATION_SUCCESS,
          bannerMessage: COMPARE_CREATION_SUCCESS.bannerMessage
            .replace('`<FILENAME1>`', `"${file1}"`)
            .replace('`<FILENAME2>`', `"${file2}"`),
          link: bannerLink,
        }));

        const tab = store.getState().repositoryTaskList.RepositoryEMTLTabType;
        refreshRepository(tab);
      },
      onFailure: (_, error?: unknown) => {
        clearBanners();
        handleFailure(error);
      },
      onTimeout: () => {
        clearBanners();
        dispatch(addBanner({
          ...COMPARE_CREATION_FAILED,
          bannerMessage: 'Comparison creation is taking longer than expected, please check the EMTL Delta repository in sometime.',
        }));
      },
    };
  };

  const compareHandler = async () => {
    dispatch(updateSelectedFile({ fileName: [], fileKey: [] }));
    clearBannersOnTop();
    dispatch(addBanner({
      ...COMPARE_CREATION_IN_PROGRESS,
      bannerMessage: COMPARE_CREATION_IN_PROGRESS.bannerMessage
        .replace('`<FILENAME1>`', `"${selectedFiles[0]}"`)
        .replace('`<FILENAME2>`', `"${selectedFiles[1]}"`),
    }));

    try {
      const response = await dispatch(createRepoCompareDeltaTask('EMTL_DELTA', acProgram, selectedKeys[0], selectedKeys[1])()).unwrap();
      dispatch(startPolling(emtlDeltaPollingPayload(response.data.taskId, selectedFiles[0], selectedFiles[1], response.data.hashKey)));
    } catch (error) {
      clearBannersOnTop();
      dispatch(addBanner({
        ...COMPARE_CREATION_FAILED,
        bannerMessage: COMPARE_CREATION_FAILED.bannerMessage
          .replace('`<FILENAME1>`', `"${selectedFiles[0]}"`)
          .replace('`<FILENAME2>`', `"${selectedFiles[1]}"`),
      }));
    }
  };

  const cancelHandler = () => {
    dispatch(updateSelectedFile({ fileName: [], fileKey: [] }));
    dispatch(clearToast(toastId));
  };

  const removeFileHandler = (val: string) => {
    if (prevRevExportInProgress) return;
    const removeSelectedFiles = getFilteredItems(selectedFiles, val);
    let selectedKeyIndex = -1;
    selectedFiles.forEach((item, index) => {
      /* istanbul ignore else */
      if (item !== val) {
        selectedKeyIndex = index;
      }
    });

    dispatch(updateSelectedFile({ fileName: removeSelectedFiles, fileKey: [selectedKeys[selectedKeyIndex]] }));
    dispatch(clearToast(toastId));
  };

  const fileClass = `mpd-repository-selected-files-content-center ${prevRevExportInProgress ? 'disabled' : ''}`;

  return (
    selectedFiles.length >= 1
      ? (
        <div className="mpd-repository-selected-bar">
          <div className="mpd-repository-selected-files-card" aria-label="mpdRepositorySelectionBar">
            <Inline wrap>
              {selectedFiles.map((val: string) => {
                return (
                  <p key={val} className={fileClass}>
                    {val}
                    <Clear
                      aria-label="mpdClearSelectedFiles"
                      onClick={() => removeFileHandler(val)}
                    />
                  </p>
                );
              })}
              <Inline className="mpd-repository-selected-files-buttons" align="end">
                <Button aria-label="mpdCancelSelectedFiles" className="card-action-selected-files-cancel-button-cls" variant="ghost" onClick={cancelHandler} disabled={prevRevExportInProgress}>Cancel</Button>
                <FeatureErrorBoundry>
                  <Button aria-label="mpdCompareSelectedFiles" className="mpd-repository-selected-files-compare-button" disabled={selectedFiles.length !== 2 || prevRevExportInProgress} onClick={compareHandler}>Compare</Button>
                </FeatureErrorBoundry>
              </Inline>
            </Inline>
          </div>
        </div>
      )
      : null
  );
};

export default MpdRepositorySelectedFileBar;
