export const CHART_TYPE = {
  PIE: 'pie',
  MAINTTASKPIE: 'maintTaskPie',
  LINE: 'line',
  BAR: 'bar',
  DOUGHNUT: 'doughnut',
  RADAR: 'radar',
  POLARAREA: 'polararea',
  BUBBLE: 'bubble',
  SCATTER: 'scatter',
  MIX: 'mix',
  STACKED: 'stacked',
  CHART: 'chart',
};

export const CHART_OPTIONS = {
  maxBarThickness: 50, // In Pixel (px)
};

export const mpdCategories = [
  'ALI',
  'CMP',
  'ZONAL',
  'MRB SYSTEM SAFETY',
  'MRB SYSTEM NON SAFETY',
  'MRB STRUCTURE',
];
export const costCategories = [
  'Scheduled',
  'Unscheduled',
];
export const smpcCategories = [
  'EASA AD',
  'ISB',
  'MRB 9',
  'CPCP',
  'ALI MRB',
  'MRB L/HIRF',
];

export const COLOR_CODES = [
  '#255FCC',
  '#2E9FE0',
  '#08875B',
  '#9CCA32',
  '#2ED19B',
  '#7CCDDE',
  '#6692AC',
  '#BCCFF3',
  '#C974BC',
  '#E6B3DF',
  '#CB5563',
  '#FF9436',
  '#FCD766',
  '#E7E632',
  '#b6fcd5',
  '#088da5',
  '#ccff00',
  '#ffc0cb',
  '#81d8d0',
  '#afeeee',
  '#ff6666',
  '#ffb6c1',
  '#008080',
  '#bada55',
  '#d4d4ff',
  '#9c8f96',
  '#67363a',
  '#16828c',
  '#168c16',
  '#788c16',
  '#f07160',
  '#f013dd',
  '#838f97',
  '#c0ff38',
  '#ffe1a9',
  '#ff387a',
  '#38fff5',
];
