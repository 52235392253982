import './SMPCTableControls.scss';
import React, {
  SetStateAction,
} from 'react';
import {
  Button, Inline,
} from '@airbus/components-react';
import {
  VisibilityOff,
} from '@airbus/icons/react';
import { SearchableColumns } from '../../../../models/programAnalysis/programAnalysisTypes';
import MpdTableSearch from '../../../MpdTableSearch/MpdTableSearch';

type SMPCTableControlsProps = {
  searchColumns: SearchableColumns[];
  showHiddenCols: React.Dispatch<SetStateAction<boolean>>;
};

const SMPCTableControls: React.FC<SMPCTableControlsProps> = function SMPCTableControls(props: SMPCTableControlsProps) {
  const {
    searchColumns,
    showHiddenCols,
  } = props;
  return (
    <Inline wrap className="smpc-table-controls-cls content-right">
      <span>
        <MpdTableSearch
          columnsOptions={searchColumns}
        />
      </span>
      <span>
        <Inline spacing="1-x" wrap>
          <Button
            variant="secondary"
            aria-label="Customized Columns"
            size="small"
            onClick={() => {
              showHiddenCols(true);
            }}
          >
            <div className="icon">
              <VisibilityOff />
            </div>
            Customize Columns
          </Button>
        </Inline>
      </span>
    </Inline>
  );
};

export default SMPCTableControls;
