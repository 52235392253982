// NM-TO-BE-CHANGED: Remove it after <UnscheduledEventsPrecomp/> component is complete
import React, { useEffect, useState } from 'react';
import { Stack } from '@airbus/components-react';

import UnscheduledEvents from '../UnscheduledEvents/UnscheduledEvents';
import UnscheduledEventsPrecomp from '../UnscheduledEventsPrecomp/UnscheduledEventsPrecomp';

import BannerComponent from '../Shared/BannerComponent/BannerComponent';
import { addBanner, clearBannerLocation } from '../../models/bannerModel/bannerSlice';
import { bannerLocationMap, UNSCHEDULED_EVENTS_DISCLAIMER } from '../../models/bannerModel/constants';

import { architecture } from '../../models/programAnalysisRepository/PARepositoryAsyncThunk';
import { NEW_ARCH } from '../TaskReportAnalysis/constants';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';

const UnscheduledEventsToggled = () => {
  const [displayTabDataCalculated, setDisplayTabDataCalculated] = useState<boolean>(false);
  const [displayTabData, setDisplayTabData] = useState<boolean>(false);
  const taskInfo = useAppSelector((state: RootState) => state.task.taskInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let disableTab = false;
    const taskSource = taskInfo.source_document_type;
    const mpdTaskNumber = taskInfo.mpd_task_reference;

    // Business Logic: https://confluence.airbus.corp/display/ASHM/Unscheduled+Events+-%3E+Rules+to+display+disclaimer+and+hide+the+graphs
    if (!taskSource || !mpdTaskNumber) {
      disableTab = true;
    } else {
      disableTab = taskSource?.includes('ALI')
          || taskSource === 'MRB CPCP'
          || taskSource === 'MRB FMP'
          || mpdTaskNumber?.substring(0, 3) === 'ZL-'
          || mpdTaskNumber?.substring(0, 2) === '20';
    }
    setDisplayTabData(!disableTab);
    setDisplayTabDataCalculated(true);
  }, [taskInfo]);

  // display disclaimer banner if the conditions not met
  useEffect(() => {
    dispatch(clearBannerLocation(bannerLocationMap.UNSCHEDULED_EVENTS));
    if (!displayTabData) {
      dispatch(addBanner(UNSCHEDULED_EVENTS_DISCLAIMER));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayTabData]);

  if (architecture() === NEW_ARCH) {
    // display nothing if showing disclaimer logic is not calculated yet
    if (!displayTabDataCalculated) {
      return <> </>;
    }
    if (displayTabData) {
      return <UnscheduledEventsPrecomp />;
    }
    return (
      <Stack spacing="2-x" wrapChildren className="disclaimer">
        <BannerComponent location={bannerLocationMap.UNSCHEDULED_EVENTS} />
      </Stack>
    );
  }

  return <UnscheduledEvents />;
};

export default UnscheduledEventsToggled;
