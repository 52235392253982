import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DataTable,
  Divider,
} from '@airbus/components-react';
import './MpdCustomizationEmtlTablePopup.scss';
import { tableColsType } from '../Shared/GenericTable/GenericTable';
import DataTableCell from '../Shared/DataTable/DataTableCell';
import { useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';

type MpdCustomizationEmtlTablePopupProps = {
  open: boolean;
  onClose: () => void;
  data: mpdTask[];
};

export const headersData = (headers: tableColsType[]) => {
  const newTableHeaders: mpdTableColsType[] = [];
  const columnName = headers;
  columnName.forEach((column) => {
    const tmpObject = {
      ...column,
      Header: (
        <b className="mpd-dialog-headers">{column.Header}</b>
      ),
      disableSortBy: true,
      title: column.Header,
      accessor: column.accessor,
      id: column.accessor,
      width: column.width,
      disableResizing: true,
      Cell: ({ row }: mpdTask) => <DataTableCell maxLength={100} maxLines={6} cellValue={row.values[column.accessor]} />,
    };
    newTableHeaders.push(tmpObject);
  });

  return newTableHeaders;
};

/**
 * Component to represent a Appendix3 cell in the DataTable component.
 * Data greater than a predefined length can be hidden/expanded using a show more/show less button.
 * @param {boolean} open Value used for dialog open/close status
 * @param {() => void} onClose Function used on dialog box close
 * @param {tableColsType[]} headers Value to be used as appendix3 table headers
 * @param {mpdTask[]} data Value to be displayed in appendix3 table cell
 */

const MpdCustomizationEmtlTablePopup = (props: MpdCustomizationEmtlTablePopupProps) => {
  const {
    open, onClose, data,
  } = props;
  const { EmtlTableHeaders: columnHeaders } = useAppSelector((state: RootState) => state.emtlTable);
  const [tableHeaders, setTableHeaders] = useState<mpdTableColsType[]>([]);
  useEffect(() => {
    setTableHeaders(headersData(columnHeaders.appendix));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Dialog className="mpd-dialog-cls mpd-dialog-cls-custom" open={open} onClose={onClose}>
      <DialogHeader><b>Customizable Equipment</b></DialogHeader>
      <Divider className="mpd-dialog-divider" />
      <DialogContent>
        <div className="mpd-dialog-table">
          <DataTable
            className="margin-table"
            columns={tableHeaders}
            data={data}
            disablePagination
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MpdCustomizationEmtlTablePopup;
