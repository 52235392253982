export const operatorInfoArray = {
  header: [{
    title: 'Operator',
    type: 'Label',
    align: 'left',
  },
  ],
  body: [{
    label: 'Nb. of TR Reported',
    type: 'Label',
    value: '220',
  },
  {
    label: 'Operator sample finding rate',
    type: 'Label',
    value: '12%',
  },
  {
    label: 'Sufficient reported data for optimization',
    type: 'Label',
    value: 'YES',
    className: 'display-font-green',
  },
  {
    label: 'Nb. of AC Reported',
    type: 'Label',
    value: '50',
  },
  {
    label: 'Operator enhanced finding rate',
    type: 'Label',
    value: '3%',
    // isPremium: true,
  },
  {
    label: '',
    type: 'Progress',
    value: 'true',
  },
  {
    label: 'Task execution average yield',
    type: 'Label',
    value: '80%',
  },
  {
    label: 'Margin of error',
    type: 'Label',
    value: '2%',
    // isPremium: true,
  },

  ],
  noOfElementsPerRow: 3,
  config: {
    className: 'card-full-screen card-min-height',
  },
};
