import React from 'react';

import AccessRestrictComponent from '../Shared/AccessRestrictComponent/AccessRestrictComponent';
import useUserPermissionCheck from '../../utils/customHooks/useUserPermissionCheck';

interface ProtectedComponentProps {
    permissionFor: string;
    children: JSX.Element;
}

/**
 * @param children JSX Element - component to display if user has access to it
 * @param permissionFor string - request page access. Defined in PERMISSION_MAPPER util
 * @returns JSX Element - children or Access Restricted component
 */
const ProtectedRoute = (props: ProtectedComponentProps): JSX.Element => {
  const { children, permissionFor } = props;
  const isAllowed = useUserPermissionCheck(permissionFor);

  if (isAllowed) {
    return children;
  }

  return (
    <AccessRestrictComponent />
  );
};

export default ProtectedRoute;
