import React, { ReactElement } from 'react';
import EmtlCardsContainer from '../../components/MpdRepository/ProgCustomization/EmtlCardsContainer';
import BuildListContainer from '../../components/MpdRepository/ProgAnalysis/BuildListContainer';

export type MpdRepoPanelSubItem = {
  title: string;
  key: string;
  componentToRender: Partial<ReactElement>;
  disabled: boolean;
  path: string;
}

export type MpdRepoPanelItem = {
  title: string;
  key: string;
  subItems: MpdRepoPanelSubItem[];
}

export const itemsInLeftDrawer: MpdRepoPanelItem[] = [{
  title: 'PROGRAM CUSTOMIZATION',
  key: 'program-customization-item',
  subItems: [{
    title: 'EMTL',
    key: 'pc-emtl-item',
    componentToRender: <EmtlCardsContainer />,
    disabled: false,
    path: 'emtl',
  },
  {
    title: 'Other Sources',
    key: 'pc-other-item',
    componentToRender: <EmtlCardsContainer />,
    disabled: true,
    path: '',
  }],
}, {
  title: 'PROGRAM ANALYSIS',
  key: 'program-analysis-item',
  subItems: [{
    title: 'Saved results',
    key: 'pa-builds-item',
    componentToRender: <BuildListContainer />,
    disabled: false,
    path: 'analysis-list',
  }],
}];

/**
 * A function to download file from S3 using presigned URL as input
 *
 * @param downloadUrl Presigned URL source for downloading file
 */
const downloadFile = (downloadUrl: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = downloadUrl;
  downloadLink.click();
};

export {
  downloadFile,
};
