import { COLOR_CODES } from './chartLib';

export const getCircularChartProps = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  chartData: Array<any>,
  labelName: string,
  chartTitle: string,
  type: string,
) => {
  let labels: Array<string> = [];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  let datasets: Array<any> = [];
  let options = {};

  if (type === 'pie') {
    const data = Object.values(chartData);
    labels = Object.keys(chartData);
    const backgroundColor = Array.from(Array(data.length).keys()).map(
      (id) => COLOR_CODES[id],
    );
    datasets = [{ label: labelName, data, backgroundColor }];
    options = {
      responsive: true,
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      plugins: {
        title: {
          display: true,
          text: chartTitle,
        },
        tooltips: {
          enabled: true,
        },
        datalabels: {
          // eslint-disable-next-line  @typescript-eslint/no-explicit-any
          formatter(value:any, context:any) {
            return context.chart.data.labels[context.dataIndex];
          },
          labels: {
            title: {
              font: {
                weight: 'bold',
              },
            },
          },
          color: 'white',
        },
      },
    };
  }
  return { labels, datasets, options };
};
