import React, { useContext, useState } from 'react';
import Fleet from '../../components/HomePage/Fleet/Fleet';
import ApplicationList from '../../components/HomePage/ApplicationList/ApplicationList';
import { appContext } from '../../utils/context/userContext';
import Loader from '../../components/Loader/Loader';

const HomePage: React.FC = function HomePage() {
  const { loader } = useContext(appContext);
  const [, updateState] = useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  return (
    <div>
      {loader
        ? <Loader isBlocking={loader} />
        : (
          // eslint-disable-next-line react/jsx-fragments
          <React.Fragment>
            <Fleet forceUpdate={forceUpdate} />
            <ApplicationList />
          </React.Fragment>
        ) }
    </div>
  );
};

export default HomePage;
