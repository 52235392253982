import { getChartOptions, mergeStackData, prepareStackedDataset } from './stackedChartUtil';

export const getLineChartProps = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  chartData: Array<any>,
  labelName: string,
  chartTitle: string,
) => {
  const mergedDatasets = mergeStackData(chartData);
  const labels = mergedDatasets[labelName];
  delete mergedDatasets[labelName];
  const datasets = prepareStackedDataset(mergedDatasets);
  const options = getChartOptions(chartTitle, datasets);
  return { labels, datasets, options };
};
