export const tabsCumulativeInterval = {
  tabs: [{
    tabName: 'A/C Age',
    class: 'ac-age',
  },
  {
    tabName: 'Cumulative Flight Hours',
    class: 'cumulative-Flight-hours',
  },
  {
    tabName: 'Cumulative Flight Cycles',
    class: 'cumulative-Flight-cycles',
  },
  ],
};

export const tabsActualInterval = {
  tabs: [{
    tabName: 'Flight Hours',
    class: 'flight-hours',
  },
  {
    tabName: 'Flight Cycles',
    class: 'flight-cycles',
  },
  {
    tabName: 'Calendar',
    class: 'calendar',
  },
  ],
};

export const chartFindingTypeDropdown = [
  { label: 'Finding type', value: 'Finding type' },
  { label: 'Missing', value: 'Missing' },
  { label: 'Crack', value: 'Crack' },
  { label: 'Corrosion', value: 'Corrosion' },
  { label: 'Broken', value: 'Broken' },
  { label: 'Pressure out of limit', value: 'Pressure out of limit' },
  { label: 'Abnormal wear', value: 'Abnormal wear' },
];
export const chartRangeSelectionDropdown = [
  { label: 'Range selection', value: 'Range selection' },
  { label: '0-5', value: '0-5' },
  { label: '5-10', value: '5-10' },
  { label: '10-15', value: '10-15' },
  { label: '15-20', value: '15-20' },
];
export const yearRangeSelectionDropdown = [
  { label: 'Range selection', value: 'Range selection' },
  { label: '2016', value: '2016' },
  { label: '2017', value: '2017' },
  { label: '2018', value: '2018' },
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
];
