import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { Methods } from '../../utils/customHooks/apiTypes';
import featureToggleSlice from './FeatureToggleSlice';
/* eslint-disable @typescript-eslint/no-explicit-any */

export const createFeatureToggle: any = (operator: string) => {
  const customAction = {
    sliceName: featureToggleSlice.name,
    actionType: 'FeatureToggling',
  };

  const apiRequestObj = {
    path: 'api/home/feature-toggle',
    method: 'get' as Methods,
    header: {
      operator,
    },
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
