const cards = [
  {
    id: 1,
    title: 'Program Customization',
    cardFeatureCode: 'PROGRAM_CUSTOMIZATION_CARD',
    description:
      'Automated selection of applicable scheduled maintenance tasks at MSN level.',
    routeTo: '/mpd-customise',
    disabled: false,
    buttonName: 'Access',
  },
  {
    id: 2,
    title: 'Program Analysis',
    cardFeatureCode: 'PROGRAM_ANALYSIS_CARD',
    description:
      'Analyse your scheduled maintenance program efficiency based on task reporting.',
    routeTo: '/program-analysis',
    disabled: false,
    buttonName: 'Access',
  },
  {
    id: 3,
    title: 'Deliverables Repository',
    cardFeatureCode: 'DELIVERY_REPOSITORY_CARD',
    description: 'Access to exported files and services delivery.',
    routeTo: '/repository/emtl',
    disabled: false,
    buttonName: 'Access',
  },
  {
    id: 4,
    title: 'Planned Maintenance Services',
    cardFeatureCode: 'PLAN_MAINTENANCE_SERVICE_CARD',
    description: 'Know more about maintenance services solutions.',
    routeTo: '/planned-maintenance-services',
    disabled: false,
    buttonName: 'Access',
  },
  {
    id: 5,
    title: 'S. Maintenance Performance',
    cardFeatureCode: 'SMP_PREMIUM_CARD',
    description: 'Advanced capabilities.',
    routeTo: '',
    disabled: true,
    buttonName: 'Coming Soon',
  },
  {
    id: 6,
    title: 'Access to useful applications',
    description: 'Direct to Airbus applications.',
    routeTo: '',
    disabled: false,
    links: [
      {
        btn: 'AirnavX',
        routeTo: 'https://w3.airbus.com/1T40/library/catalog?wc=doctype:AMM',
      },
      {
        btn: 'Tech Request',
        routeTo: 'https://w3.airbus.com/crs/TRM/ContentGadgets/index_nce.html',
      },
      { btn: 'SB Reporting', routeTo: 'https://w3.airbus.com/sbr/sb' },
      {
        btn: 'Skywise Reliability Services',
        routeTo:
          'https://core.skywise.com/workspace/foundry-slate/app/skywise-reliability-services',
      },
    ],
  },
];

export default cards;
