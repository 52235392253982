import React, { useState, useEffect } from 'react';
import ScrollTopSvg from '../../../assets/icons/scrollTopBtn.svg';
import './ScrollTopBtn.scss';

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const onScroll = () => {
    const scrolled = document.documentElement.scrollTop;
    /* Set scroll-to-top btn visible if the scroll height is more than '300'px  */
    if (scrolled > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* Can also use 'auto' behaviour in place of 'smooth' */
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      aria-label="Scroll To Top Button"
      style={{ display: visible ? 'inline' : 'none' }}
      onClick={scrollToTop}
      tabIndex={0}
      className="scroll-to-top-div"
    >
      <p className="scroll-to-top-text"> Scroll Up </p>
      <img
        className="scroll-to-top-btn"
        src={ScrollTopSvg}
        alt="scroll To Top Icon"
      />
    </div>
  );
};

export default ScrollToTopButton;
