import React, { useMemo } from 'react';
import '../../../Shared/DataTable/DataTableCell.scss';
import './EmtlDeltaViewTableCell.scss';
import { Link } from '@airbus/components-react';

interface EmtlDeltaViewTableCellProps {
  deltaValue: Array<Array<string>> | string | null;
  maxLength: number;
}

const generateDeltaCellValue = (cellValue: Array<Array<string>>, maxLength: number | null = null) => {
  let deltaValue = cellValue;
  if (maxLength) {
    let currLength = 0;
    deltaValue = cellValue.filter((delta) => {
      currLength += delta[0].length;
      return currLength <= maxLength;
    });
    deltaValue.push(['...', '']);
  }
  return deltaValue.map((value) => (
    React.createElement('span', { style: { color: `${value[1]}`, textDecoration: `${value.length > 2 && value[2] ? 'underline solid' : 'none'}`, fontWeight: `${value.length > 3 && value[3] ? '600' : 'none'}` }, key: `${value[0]}` }, `${value[0]} `)
  ));
};

const generateCellValue = (cellValue: string | null, maxLength: number | null = null) => {
  /* istanbul ignore else */
  if (cellValue !== null) {
    return (maxLength ? `${cellValue.substring(0, maxLength)}...` : cellValue);
  }
  return cellValue;
};

const isLengthTrimRequired: (deltaValue: Array<Array<string>> | string, maxLength: number) => boolean = (deltaValue, maxLength) => {
  const dataLength = Array.isArray(deltaValue) ? deltaValue.reduce((length, delta) => length + delta[0].length, 0) : deltaValue.length;
  return dataLength > maxLength;
};

/**
 * Component to represent a cell of delta data in the DataTable component.
 * Data greater than a predefined length can be hidden/expanded using a show more/show less button.
 * @param {string} deltaValue Value to be displayed in cell
 * @param {number} maxLength The maximum length (number of charecters) of data that can be displayed without truncating/hiding data
 */
const EmtlDeltaViewTableCell: React.FC<EmtlDeltaViewTableCellProps> = function DataTableCell(props: EmtlDeltaViewTableCellProps) {
  const { deltaValue, maxLength } = props;

  const deltaCellValue = useMemo(() => (Array.isArray(deltaValue) ? generateDeltaCellValue(deltaValue) : generateCellValue(deltaValue)), [deltaValue]);
  const trimmedDeltaCellValue = useMemo(() => (Array.isArray(deltaValue) ? generateDeltaCellValue(deltaValue, maxLength) : generateCellValue(deltaValue, maxLength)), [deltaValue, maxLength]);
  const lengthTrimRequired: boolean = (deltaValue === null) ? false : isLengthTrimRequired(deltaValue, maxLength);

  const [showTrimData, setShowTrimData] = React.useState(true);

  const isCellEmpty = () => (
    deltaValue == null
  );

  const getTrimValue = () => (
    lengthTrimRequired ? trimmedDeltaCellValue : deltaCellValue
  );

  const getCellValue = () => {
    return !isCellEmpty() && showTrimData ? getTrimValue() : deltaCellValue;
  };

  const renderShowMoreLessButton = () => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        className="data-table-cell-link"
        variant="underlined"
        aria-label="ShowMoreLess"
        onClick={(e) => {
          e.preventDefault();
          setShowTrimData((prevState) => !prevState);
        }}
      >
        {showTrimData ? 'more' : 'less'}
      </Link>
    );
  };

  return (
    <div className="data-table-cell-cls emtl-delta-view-table-cell-cls">
      <p>
        {deltaValue != null && getCellValue()}
      </p>
      {deltaValue != null && lengthTrimRequired && renderShowMoreLessButton()}
    </div>
  );
};

export default EmtlDeltaViewTableCell;
