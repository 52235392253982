export const MPD_DELETE_MESSAGE = 'Are you sure you want to delete this file? This action will completely delete the file from the repository.';
export const MPD_COMPARISON_MESSAGE = 'Are you sure you want to delete this file? This action will completely delete the below comparison file from repository.';
export const MPD_DELETE_TITLE = 'Delete Exported File';
export const MPD_COMPARISON_FILE = 'Delete Comparison File';
export const MPD_DELETE_PRIMARY_BUTTON_NAME = 'Delete';
export const MPD_DELETE_SECONDARY_BUTTON_NAME = 'Cancel';

export const ANALYSIS_DELETE_TITLE = 'Delete analysis';
export const ANALYSIS_DELETE_MESSAGE = 'Are you sure you want to delete this analysis? This action will completely delete the analysis from the repository.';

export const ANALYSIS_BUILD_EXCEED_TITLE = 'Analysis limit reached';
export const ANALYSIS_BUILD_EXCEED_SUBMIT = 'Proceed to delete';
export const ANALYSIS_BUILD_EXCEED_OKAY = 'OK';
