export const fleetInfoArray = {
  header: [{
    title: 'Worldwide fleet',
    type: 'Label',
    align: 'left',
  },
  ],
  body: [{
    label: 'Worldwide fleet sample finding rate',
    type: 'Label',
    value: '4%',
  },
  {
    label: 'Worldwide fleet enhanced finding rate',
    type: 'Label',
    value: '4%',
  },
  ],
  noOfElementsPerRow: 1,
  config: {
    className: 'card-full-screen card-min-height',
  },
};
