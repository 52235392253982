/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-fragments */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import {
  Container, Typography, Row, Col,
} from '@airbus/components-react';
import { Star } from '@airbus/icons/react';
import Dropdown from '../../Shared/Dropdown/Dropdown';
import './Fleet.scss';
import { useFetch } from '../../../utils/customHooks/useFetch';
import { Methods } from '../../../utils/customHooks/apiTypes';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import { appContext } from '../../../utils/context/userContext';
import { secureLS } from '../../../utils/localStorageUtil/lsHelper';
import { updateSelectedFile } from '../../../models/mpdRepositoryModel/mpdRepositorySlice';
import { useAppDispatch } from '../../../store/hooksTypes';
import { NEW_ARCH } from '../../TaskReportAnalysis/constants';
import { features } from '../../../utils/userPermissionUtils/userPermissionFeatures';
import { checkFeaturePermission } from '../../../utils/userPermissionUtils/userPermissionUtils';

type FleetProps = {
  forceUpdate: (obj: object) => void;
}

const Fleet = function Fleet(props: FleetProps) {
  const dispatch = useAppDispatch();
  const { forceUpdate } = props;
  const API_METHOD_GET: Methods = 'get';
  const PATH = 'api/home/getdata';
  const [response, error] = useFetch(PATH, API_METHOD_GET);
  const [program, setProgram] = useState('');
  const [operator, setOperator] = useState('');
  const { icao, firstname, userType } = useContext(appContext);
  icao !== '' && secureLS.set('operator', icao);
  const dropDownSelectionHandlerProgram = (event: string) => {
    setProgram(event);
    event === '' && secureLS.remove('ac_program');
    forceUpdate({});
    secureLS.set('architecture', NEW_ARCH); // always set to new architecture since old arch is depreciated from EIS 1
    dispatch(updateSelectedFile({ fileName: [], fileKey: [] }));
  };

  const dropDownSelectionHandlerOperator = (event: string) => {
    if (event === '') {
      secureLS.remove('operator');
      secureLS.remove('ac_program');
      secureLS.remove('architecture');
    }
    operator !== event && secureLS.remove('ac_program');
    setOperator(event);
    setProgram('');
    forceUpdate({});
  };

  // This function helps to sort the operator response[alphabets, number].
  const segregateOperator = () => {
    const checkType = /^\d/;
    const num: string[] = [];
    const char: string[] = [];
    const ops = Object.keys(response.message);
    // eslint-disable-next-line no-restricted-syntax
    for (const item of ops) {
      /* eslint-disable no-unused-expressions */
      checkType.test(item) ? num.push(item) : char.push(item);
    }
    return [...char.sort(), ...num.sort()];
  };

  const sortProgram = () => {
    return operator !== '' ? response.message[operator] && (response.message[operator]).sort() : [];
  };

  useEffect(() => {
    const localStorageOperator = secureLS.get('operator');
    const localStorageProgram = secureLS.get('ac_program');
    setOperator(localStorageOperator || icao || '');
    setProgram(localStorageProgram || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="fleet-container">
      <div className="fleet-container-outer">
        <Row alignItems="center" justify="space-evenly" className="fleet-row">
          <Col xxs={3} xl={2}>
            <div className="fleet-typo-fleet">
              <Typography className="welcome-typo">
                <strong className="fleet-typo-fleet-strong">{`Welcome, ${firstname}!`}</strong>
              </Typography>
            </div>
          </Col>
          {response ? (
            <>
              <Col xxs={4} className="column-xxs-4">
                {userType === 'internal' && (
                  <Row justify="center">
                    <Col xxs={3} lg={2} className="fleet-typo">
                      <strong className="fleet-typo-strong">
                        Operator
                        <div className="asterik-icon-container">
                          <Star className="asterik-icon" />
                        </div>
                      </strong>
                    </Col>
                    <Col xxs={9} xl={10} className="fleet-dropdown">
                      <Dropdown
                        disabled={!checkFeaturePermission([features.Dashboard.OPERATOR_SELECTION])}
                        onSelectingDrp={dropDownSelectionHandlerOperator}
                        data={segregateOperator()}
                        value={operator}
                        placeholder="Operator"
                        forceUpdate={forceUpdate}
                        lsName="operator"
                      />
                    </Col>
                  </Row>
                )}
              </Col>
              <Col xxs={5} className="column-xxs-4">
                <Row justify="start">
                  <Col xxs={4} xl={3} className="fleet-typo">
                    <strong className="fleet-typo-strong">
                      Maintenance Program
                      <div className="asterik-icon-container">
                        <Star className="asterik-icon" />
                      </div>
                    </strong>
                  </Col>
                  <Col xxs={8} className="fleet-dropdown">
                    <Dropdown
                      disabled={!operator}
                      onSelectingDrp={dropDownSelectionHandlerProgram}
                      data={sortProgram()}
                      value={program}
                      placeholder="Maintenance Program"
                      forceUpdate={forceUpdate}
                      lsName="ac_program"
                    />
                  </Col>
                </Row>
              </Col>
            </>
          ) : (
            <SpinnerComponent size="small" label="" />
          )}
        </Row>
      </div>
    </Container>
  );
};

export default Fleet;
