// Slice
export const MPD_EMTL_GENEREL_INFO_SLICE = 'effectiveMaintenenceTaskList';

// Async thunk actions
export const FETCH_EFFECTIVE_MAINTAINENENCE_TASK_LIST = 'fetchEffectiveMaintenenceTaskList';
export const CREATE_EFFECTIVE_MAINTAINENENCE_TASK_LIST = 'createEffectiveMaintenenceTaskList';
export const FETCH_MPD_GEN_INFO = 'fetchMpdGenInfo';
export const CREATE_MPD_GEN_INFO = 'createMpdGenInfo';

// API Path
export const FETCH_API_PATH = 'api/csm/emtlCardInformation';
export const FETCH_API_PATH_MPD_GEN_INFO = 'api/csm/mpdgeneralinfo';
