import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// Extend dayjs with the necessary plugins :  UTC handling,localized date formatting,advanced formatting features
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

/**
 * Formats a given date string to a specified format.
 *
 * @param {string} dateString - UTC format date string
 * @returns {string} - The formatted date string based on usertimezone eg : Sep 26, 2024, 3:13:53 PM
 */
export const formatRepositoryDate = (dateString: string) => {
  if (dateString === undefined) {
    return 'Invalid Date';
  }
  return dayjs.utc(dateString).local().format('MMM D, YYYY, h:mm:ss A');
};
