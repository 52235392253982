import { store } from '../../store/store';

type featureInfoType = {
  name: string,
  userType: string
}

function isToggle<T>(isFeatureAvailable: featureForToggling | undefined, payload: T, userType: string) {
  if (isFeatureAvailable && isFeatureAvailable.visibility[userType as keyof typeof isFeatureAvailable.visibility]) {
    return payload;
  }
  return null;
}

function isSwitch<T>(isFeatureAvailable: featureForToggling | undefined, payload1: T, payload2: T, userType: string) {
  if (isFeatureAvailable && isFeatureAvailable.visibility[userType as keyof typeof isFeatureAvailable.visibility]) {
    return payload1;
  }
  return payload2;
}

const commonUtil = <T>(featureInfo: featureInfoType, payload1: T, payload2?: T) => {
  const { name, userType } = featureInfo;
  const toggle = typeof payload2 === 'undefined';
  const { features } = store.getState().featureToggle;
  let response = null;
  if (features === null) {
    response = payload1;
  } else {
    const isFeatureAvailable = features[name as keyof typeof features];
    if (toggle) {
      response = isToggle(isFeatureAvailable, payload1, userType);
    } else {
      response = isSwitch(isFeatureAvailable, payload1, payload2, userType);
    }
  }
  return response;
};

/**
 * Toggle the feature based on configuration from backend.
 *
 * * If visibility is `true`, then `payload1` will be returned.
 * * If visibility is `false`, then `null` will be returned.
 * * If feature name is not found in the list, then `null` will be returned.
 *
 * **Usage:**
 * * If payload(s) are components, just pass them to this util.
 * * If payload(s) are some logic, wrap those in functions and pass that to this util.
 *
 * **Testing:**
 * * No extra configuration is required for testing. By default `payload1` will be available.
 *
 * @param name Name of the feature as per config
 * @param payload1 Payload which will be shown if feature is visibile
 * @returns `payload1` if feature is visibile, else `null`
 */
export function featureToggleConfig<T>(featureInfo: featureInfoType, payload1: T) {
  return commonUtil(featureInfo, payload1);
}

/**
 * Switch between two feature(s) based on configuration from backend.
 *
 * * If visibility is `true`, then `payload1` will be returned.
 * * If visibility is `false`then `payload2` will be returned.
 * * If feature name is not found in the list, then `payload2` will be returned.
 *
 *
 * **Usage:**
 * * If payload(s) are components, just pass them to this util.
 * * If payload(s) are some logic, wrap those in functions and pass that to this util.
 *
 * **Testing:**
 *
 * CASE 1. If not rendering `<App/>`
 *   * In tests, `payload1` will be returned by default. For testing `payload2` do `store.dispatch(changeFeatureToggleState({ featuretogglelist: {}, disclaimer: { version: 1, description: '' } }));` action before the `setup()`.
 *   * Resetting: In order to prevent the store changes spilling to other tests:
 *     * Do `store.dispatch(changeFeatureToggleState(null))` after the test.
 *   * If using with `renderWithProviders`, add it in `preloadedState` as well along with above step.
 *
 * CASE 2. If rendering `<App/>`
 *   * Mock the `feature-toggle` API call in the setup() using `jest.spyOn(API, 'get').mockResolvedValueOnce({featuretogglelist: null})`
 * @param name Name of the feature as per config
 * @param payload1 Payload which will be shown if feature is visibile
 * @param payload2 Optional payload which will be shown if feature is not visibile or the `name` is not in the list.
 * @returns `payload1` if feature is visibile, else `payload2`
 */
export function featureSwitchConfig<T>(featureInfo: featureInfoType, payload1: T, payload2?: T) {
  const response = commonUtil(featureInfo, payload1, payload2);
  if (response) {
    return response;
  }

  // This line will never run. It is added so that `commonUtil`
  // can be used for both `featureToggleConfig` and `featureSwitchConfig` functions.
  return payload1;
}
