import {
  COST_AIRCRAFT_YEAR, IS_CALCULABLE, NUMBER_OF_TASK_REPORT, SUFFICIENT_TASK_REPORT,
} from '../../../models/programAnalysis/constants';
import { maintenanceTask, taskListSort } from '../../../models/programAnalysis/programAnalysisTypes';

export const sortbyCostAircraftYear = (data: maintenanceTask[]) => {
  data.sort((item, item2) => {
    if (!item[COST_AIRCRAFT_YEAR] || !item2[COST_AIRCRAFT_YEAR]) {
      if (item[COST_AIRCRAFT_YEAR] && !item2[COST_AIRCRAFT_YEAR]) return 1;
      if (!item[COST_AIRCRAFT_YEAR] && item2[COST_AIRCRAFT_YEAR]) return -1;
      return 0;
    }
    if (item[COST_AIRCRAFT_YEAR] > item2[COST_AIRCRAFT_YEAR]) return 1;
    if (item[COST_AIRCRAFT_YEAR] < item2[COST_AIRCRAFT_YEAR]) return -1;
    return 0;
  });
};

export const customFuncs = {
  cost_aircraft_year: (data: maintenanceTask[], val: number[]) => {
    sortbyCostAircraftYear(data);
    return data.slice((val[0] * data.length) / 100, (val[1] * data.length) / 100);
  },
  number_of_task_report: (data: maintenanceTask[], val: boolean) => {
    if (val) {
      return data.filter((item: maintenanceTask) => item[NUMBER_OF_TASK_REPORT] && item[NUMBER_OF_TASK_REPORT] > 0 && item[IS_CALCULABLE] === '');
    }
    return data;
  },
  op_sufficient_tr: (data: maintenanceTask[], val: boolean) => {
    if (val) {
      return data.filter((item: maintenanceTask) => (item[SUFFICIENT_TASK_REPORT] === val && item[IS_CALCULABLE] === ''));
    }
    return data;
  },
};

/**
 * Generic function that compares two arrays.
 * Again, this isn't a function that should be used everywhere.
 * @param {any} arrA The first object to be compared
 * @param {any} arrB The second object to be compared
 * @returns {boolean} A boolean indicating if the two objects are equal
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const simpleCompareArr = (arrA: any, arrB: any) => JSON.stringify(arrA) === JSON.stringify(arrB);

export const sortData = (sortType: taskListSort[], a: maintenanceTask, b: maintenanceTask, idx: number): number => {
  if (a[sortType[idx].id] > b[sortType[idx].id] || a[sortType[idx].id] < b[sortType[idx].id]) {
    if (sortType[idx].desc) {
      return a[sortType[idx].id] > b[sortType[idx].id] ? -1 : 1;
    }
    return a[sortType[idx].id] < b[sortType[idx].id] ? -1 : 1;
  }
  if (sortType[idx + 1]) {
    return sortData(sortType, a, b, idx + 1);
  }
  return 0;
};

export const applySort = (toSort: maintenanceTask[], sortBy: taskListSort[]) => {
  const sortedData = [...toSort];
  if (sortBy.length > 0) {
    sortedData.sort((a, b) => sortData(sortBy, a, b, 0));
  } else {
    sortedData.sort((a: maintenanceTask, b: maintenanceTask) => Number(a.task_number) - Number(b.task_number));
  }
  return sortedData;
};
