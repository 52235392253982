import { createSlice } from '@reduxjs/toolkit';
import {
  changeFeatureToggleStateReducer,
  fetchFeatureToggleListFullfilled,
  fetchFeatureToggleListPending,
  fetchFeatureToggleListRejected,
} from './FeatureToggleReducer';
import { createFeatureToggle } from './FeatureToggleAsyncThunk';

export const initialState: featureToggleState = {
  features: null,
  disclaimer: {
    version: -1,
    description: '',
  },
  isError: false,
};

const featureToggleSlice = createSlice({
  name: 'featureToggle',
  initialState,
  reducers: {
    changeFeatureToggleState: changeFeatureToggleStateReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(createFeatureToggle().pending, fetchFeatureToggleListPending);
    builder.addCase(createFeatureToggle().fulfilled, fetchFeatureToggleListFullfilled);
    builder.addCase(createFeatureToggle().rejected, fetchFeatureToggleListRejected);
  },
});

export const { changeFeatureToggleState } = featureToggleSlice.actions;
export default featureToggleSlice;
