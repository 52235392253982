import React from 'react';
import {
  Typography,
} from '@airbus/components-react';
import './MpdContent.scss';
import { AssignmentOutline } from '@airbus/icons/react';

const MpdContent = function MpdContent() {
  return (
    <div className="mpd-content-cls">
      <div>
        <div className="icon"><AssignmentOutline /></div>
        <h6 className="mpd-content-header">Select your Effective Maintenance Task</h6>
        <Typography variant="small">To continue, please select one of the effective maintenance task.</Typography>
      </div>
    </div>
  );
};

export default MpdContent;
