import React from 'react';
import './DataTableCell.scss';
import { Link } from '@airbus/components-react';

interface DataTableCellProps {
    cellValue: string;
    maxLength: number;
    maxLines: number;
}

/**
 * A generic component to represent a cell of data in the DataTable component.
 * Data greater than a predefined length (or number of lines) can be hidden/expanded using a show more/show less button.
 * @param {string} cellValue Value to be displayed in cell
 * @param {number} maxLines The maximum number of lines that can be displayed without truncating/hiding data
 * @param {number} maxLength The maximum length (number of charecters) of data that can be displayed without truncating/hiding data
 */
const DataTableCell: React.FC<DataTableCellProps> = function DataTableCell(props: DataTableCellProps) {
  const { cellValue, maxLength, maxLines } = props;
  const [showTrimData, setShowTrimData] = React.useState(true);

  const isCellEmpty = () => (
    cellValue == null
  );

  const isLengthTrimRequired = () => (
    cellValue.length > maxLength
  );

  const isLineTrimRequired = () => (
    ((cellValue.match(/\n/g) || '').length + 1) > maxLines
  );

  const isTrimRequired = () => (
    isLineTrimRequired() || isLengthTrimRequired()
  );

  const getTrimValue = () => {
    if (isLineTrimRequired()) {
      return `${cellValue.split('\n').slice(0, maxLines).join('\n')}...`;
    }
    if (isLengthTrimRequired()) {
      return `${cellValue.substring(0, maxLength)}...`;
    }
    return cellValue;
  };

  const getCellValue = () => (
    !isCellEmpty() && showTrimData ? getTrimValue() : cellValue
  );

  const renderShowMoreLessButton = () => {
    return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        className="data-table-cell-link"
        variant="underlined"
        aria-label="ShowMoreLess"
        onClick={(e) => {
          e.preventDefault();
          setShowTrimData((prevState) => !prevState);
        }}
      >
        {showTrimData ? 'more' : 'less'}
      </Link>
    );
  };

  return (
    <div className="data-table-cell-cls">
      <p title={cellValue}>
        {cellValue != null && getCellValue()}
      </p>
      {cellValue != null && isTrimRequired() && renderShowMoreLessButton()}
    </div>
  );
};

export default DataTableCell;
