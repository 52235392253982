import { PARepositoryAction, PARepositoryState, PARepositoryActionFilter } from './PARepositoryTypes';
import { TITLE } from './constants';

export const updatePARepositoryDataReducer = (state: PARepositoryState, action: PARepositoryAction) => (
  {
    ...state,
    repositoryData: action.payload.data ? action.payload.data : [],
    loading: false,
    responseMessage: action.payload.message as string,
    rebulidLoading: 'completed',
  }
);

export const updatePARepositoryDataReducerPending = (state: PARepositoryState) => ({
  ...state,
  loading: true,
});

export const updatePARepositoryDataReducerRejected = (state: PARepositoryState) => ({
  ...state,
  loading: true,
  responseMessage: 'rejected',
  rebulidLoading: 'completed',
});

export const deleteBuildDataReducer = (state: PARepositoryState, action: PARepositoryAction) => {
  const id = action.meta?.arg?.id;

  const newArray = state.repositoryData.filter((elem) => elem.id !== id);

  return {
    ...state,
    repositoryData: newArray,
    loading: false,
  };
};

export const deleteBuildDataReducerPending = (state: PARepositoryState) => ({
  ...state,
  loading: true,
});

export const deleteBuildDataReducerRejected = (state: PARepositoryState) => ({
  ...state,
  loading: false,
  responseMessage: 'rejected',
});

export const retryBuildDataReducer = (state: PARepositoryState) => ({
  ...state,
  rebulidLoading: 'done',
});

export const updatePARepositoryFiltersReducer = (state: PARepositoryState, action: PARepositoryActionFilter) => ({
  ...state,
  filters: action.payload.filters,
});

export const updateTitleReducer = (state: PARepositoryState, action: PARepositoryAction) => {
  const id = action.meta?.arg?.id;
  const newTitle = action.meta?.arg?.cardTitle;
  const elemById = state.repositoryData.find((elem) => elem.id === id);

  if (elemById) {
    const indexOfElem = state.repositoryData.indexOf(elemById);
    const newArray = [...state.repositoryData];
    newArray[indexOfElem][TITLE] = newTitle;
  }
};

export const clearPARepoStateReducer = (state: PARepositoryState) => ({
  ...state,
  repositoryData: [],
  responseMessage: '',
  rebulidLoading: '',
});
