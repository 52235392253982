import React, { Dispatch, SetStateAction } from 'react';
import { tableColsType } from '../Shared/GenericTable/GenericTable';
import DataTableHeader from '../Shared/DataTable/DataTableHeader';
import DataTableCell from '../Shared/DataTable/DataTableCell';
import { BarGraphCardProps } from '../UnscheduledEvents/GraphCard';

// Table util
export const generateTableColumns: (onClickHandler: Dispatch<SetStateAction<string>>, tableColumns: tableColsType[]) => mpdTableColsType[] = (onClickHandler, tableColumns) => tableColumns.map((header) => ({
  Header: <DataTableHeader title={header.Header} id={header.accessor} hideMode={false} onClickHandler={onClickHandler} />,
  title: header.Header,
  accessor: header.accessor,
  id: header.accessor,
  width: header.width,
  disableSortBy: false,
  disableResizing: false,
  Cell: ({ row }: mpdTask) => {
    const value = row.values[header.accessor];
    return <DataTableCell maxLength={50} maxLines={1} cellValue={value ? `${value}` : ''} />;
  },
}));

// Graph util
const basicGraphConfig = {
  options: {
    hideLegends: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Format data to be passed to the fleetwide graph
*
* @param fleetwide The fleetwide data
* @param operator The operator data
* @param years The dates correponding to the data
* @param title The title of the graph
* @returns An object with the data to pass to the graph
*/
export const createFleetwideGraph = (fleetwide: number[], operator: number[], years: (number | string)[]): BarGraphCardProps['graph'] => {
  const maxValue = Math.max(...fleetwide.concat(operator));
  return {
    data: {
      labels: years,
      datasets: [
        {
          label: 'Avg number of OI reported per A/C for fleet wide',
          data: fleetwide,
          backgroundColor: 'rgb(53, 167, 233)',
          barPercentage: 0.67,
          categoryPercentage: 0.45,
        },
        {
          label: 'Avg number of OI reported per A/C operator',
          data: operator,
          backgroundColor: 'rgb(37, 95, 204)',
          barPercentage: 0.67,
          categoryPercentage: 0.45,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      scales: {
        y: {
          title: {
            display: true,
            text: 'Avg number of OI reported',
            font: {
              weight: 'bold',
            },
          },
          max: Math.abs(maxValue + maxValue / 10),
        },
        x: {
          title: {
            display: true,
            text: 'Years',
            font: {
              weight: 'bold',
            },
          },
        },
      },
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          position: 'bottom' as const,
          align: 'start',
        },
      },
    },
    height: 300,
  };
};

// generic graph for finding rate and rsi graph
export const precomputeGenericGraph = (data: any, title: string, subData: string): any => {
  const oiMax = Math.max(...(data.oi_reported));
  const taskMax = Math.max(...(data[subData])) + 5; // +5 is to avoid line graph moving to top edge of the graph
  return {
    ...basicGraphConfig,
    options: {
      ...basicGraphConfig.options,
      scales: {
        x: {
          title: {
            display: true,
            text: 'A/C Age (Years)',
            font: {
              weight: 'bold',
            },
          },
        },
        y: {
          title: {
            display: true,
            text: 'Number of OI reported',
            font: {
              weight: 'bold',
            },
          },
          max: Math.abs(oiMax),
        },
        fRateAxis: {
          title: {
            display: true,
            text: title,
            font: {
              weight: 'bold',
            },
          },
          grid: {
            display: false,
          },
          position: 'right',
          max: Math.round(taskMax),
        },
      },
    },
    chartTitle: 'OI Reported vs Finding Rate per A/C age',
    data: {
      labels: data.interval_range,
      datasets: [
        {
          order: 2,
          label: 'Number of OI reported',
          type: 'bar',
          data: data.oi_reported,
          backgroundColor: 'rgba(37, 95, 204, 1)',
        },
        {
          order: 1,
          label: title,
          type: 'line',
          data: data[subData],
          borderColor: 'rgba(46, 159, 224, 1)',
          backgroundColor: 'rgba(46, 159, 224, 1)',
          yAxisID: 'fRateAxis',
        },
      ],
    },
  };
};
