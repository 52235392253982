/* eslint-disable @typescript-eslint/no-explicit-any */

import { SearchableColumns } from '../../../models/programAnalysis/programAnalysisTypes';
import { MPD_TASK_REFERENCE } from '../../../models/programAnalysis/constants';

/**
 * General comment -
 * Most of the functions here use the .filter() method of the array type.
 * Essentially, the .filter() method loops on the original array and returns a new array
 * (ie. it allocates a new array in memory, it does not filter "in-place")
 * with the items depending on the condition you give it.
 * If the item passes the condition, it will be part of the new array.
 *
 * More info on the filter method here :
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter
 */

/**
 * Generic function that filters an array of objects depending on a string field and a value passed via parameters
 * If the key name is NOT MPD_TASK_REFERENCE (see constants), we do an exact match
 * @param {T[]} tableData The data to filter
 * @param {string} val The value the field given via "key" should contain
 * @param {K} key The name of the field to be filtered
 * @returns {T[]} The data filtered
 */
export const genericFilterIncludesString = <T, K extends keyof T>(tableData: T[], val: string, key: K): T[] => tableData.filter((item) => (key === MPD_TASK_REFERENCE ? item[key] && (item[key] as unknown as string).includes(val) : item[key] && item[key] === val));

/**
 * Same as above but filters between 2 numbers
 * @template T Generic type that accepts any DataTable data
 * @template K Type that will take keys of T as values
 * @param {T[]} tableData The data to filter
 * @param {number[]} val The value the field given via "key" should contain
 * @param {K} key The name of the field to be filtered
 * @returns {T[]} The data filtered
 */
export const genericFilterBetweenNumbers = <T, K extends keyof T>(tableData: T[], val: number[], key: K): T[] => tableData.filter((item) => {
  /** If the value is "null"
   *  1. Include key if range is 0 and 100 (reset)
   *  2. Exclude key if range is > 0 or < 100
   */
  if (item[key] === null) {
    if (val[0] === 0 && val[1] === 100) {
      return true;
    }
    return false;
  }
  const it = item[key] || 0;
  return (it as unknown as number) >= val[0] && (it as unknown as number) <= val[1];
});

/**
 * Same as above but filters between 2 dates
 * @template T Generic type that accepts any DataTable data
 * @template K Type that will take keys of T as values
 * @param {T[]} data The data to be filtered
 * @param {Date[]} val The values the field given via "key" should be between
 * @param {K} key The key of T to be filtered
 * @returns {T[]} The data, filtered
 */
export const genericFilterBetweenDates = <T, K extends keyof T>(data: T[], val: Date[], key: K): T[] => data.filter((item) => (item[key] as unknown as Date) > val[0] && (item[key] as unknown as Date) < val[1]);

/**
 * Same as above but filters on a boolean
 * @template T Generic type that accepts any DataTable data
 * @template K Type that will take keys of T as values
 * @param {T[]} data The data to be filtered
 * @param {Date[]} val The values the field given via "key" should be between
 * @param {K} key The key of T to be filtered
 * @returns {T[]} The data, filtered
 */
export const genericFilterIncludesBoolean = <T, K extends keyof T>(data: T[], val: boolean, key: K): T[] => data.filter((item) => (item[key] as unknown as boolean) === val);

/**
 * Generic function that compares two objects.
 * We transform these objects into an array of entries, then sort these arrays and transform them to strings
 * This isn't a foolproof solution but since this function is only a helper and isn't meant to be used anywhere
 * else than our use-case, this should be sufficient
 * @param {any} objA The first object to be compared
 * @param {any} objB The second object to be compared
 * @returns {boolean} A boolean indicating if the two objects are equal
 */
export const simpleCompareObj = (objA: any, objB: any) => {
  if (Object.entries(objA).sort().toString() === Object.entries(objB).sort().toString()) {
    return true;
  }
  return false;
};

/**
 * A function to retrieve unique values from a particular field.
 * This is used to display unique choices to the user when he types something to search.
 * @template T Any data given to the DataTable component
 * @template K Type that'll hold T's keys
 * @param {T[]} data Data given to the DataTable component
 * @param {K} key The key to search unique values for
 * @returns A new array of unique values
 */
export const uniqueValues = <T extends Record<string, unknown>, K extends keyof T>(data: T[], key: K): { label: string; value: string }[] => {
  const newArr: { label: string; value: string }[] = [];
  data.forEach((item) => {
    if (item[key] !== undefined && !JSON.stringify(newArr).includes(`"label":"${item[key]}"`)) newArr.push({ label: item[key] as string, value: item[key] as string });
  });
  return newArr;
};

/**
 * A function to check if the searchable column is flagged as "unique".
 * We consider a column "unique" if the values are all unique.
 * We do this in order to avoid significantly slowing down the app by rendering potentially
 * tens of thousands of values in a dropdown.
 * @param {SearchableColumns[]} columns The columns which can be searchable and will show in the dropdown
 * @param {string} key The key to scan for uniqueness
 * @returns {boolean} A boolean indicating if the column only has unique items
 */
export const isColumnUnique = (columns: SearchableColumns[], key: string) => {
  let isUnique = false;
  columns.forEach((item) => {
    if (item.value === key && item.unique) isUnique = true;
  });
  return isUnique;
};

/**
 * Generic function that filters an array of objects of generic type depending on a string field and a value passed bia parameters
 * @template T Generic type that accepts any DataTable data
 * @template U Generic type that accepts any filter object. It extends Record<string, unknown> to allow us to use brackets [] syntax on the object
 * @template K Generic type that will take keys of T as values
 * @param {T[]} initialData The data to be filtered
 * @param {U} filtersCopy The filters to apply to the data sent in first param
 * @returns {T[]} The new data, filtered
 */
export const applyFilters = <T, F extends Record<string, unknown>, K extends keyof T, C extends Record<K, unknown>>(initialData: T[], columnsFiltered: F | F[], customFuncs?: C): T[] => {
  let newArr: T[] = [...initialData];
  if (columnsFiltered && Object.keys(columnsFiltered).length) {
    Object.entries(columnsFiltered).forEach(([key, val]) => {
      /* istanbul ignore else */
      if (customFuncs && customFuncs[key as K]) {
        newArr = (customFuncs[key as K] as (data: T[], value: typeof val, keyBis: K) => T[])(newArr, val, key as K);
      } else if (typeof val === 'string' && val !== '') {
        newArr = genericFilterIncludesString(newArr, val, key as K);
      } else if (Array.isArray(val) && val.length === 2 && typeof val[0] === 'number') {
        newArr = genericFilterBetweenNumbers(newArr, val, key as K);
      } else if (Array.isArray(val) && val.length === 2 && val[0] instanceof Date) {
        newArr = genericFilterBetweenDates(newArr, val, key as K);
      } else if (typeof val === 'boolean' && val) {
        newArr = genericFilterIncludesBoolean(newArr, val, key as K);
      }
    });
  }

  return newArr;
};
