import React, { Dispatch, SetStateAction } from 'react';
import DataTableHeader from '../Shared/DataTable/DataTableHeader';
import DataTableCell from '../Shared/DataTable/DataTableCell';
import { RawTaskReportCols } from '../../__mocks__/UnscheduledEvents/TaskReportsTable';

export const TableColumnState = {
  Shown: 'shown',
  Hidden: 'hidden',
};
export const generateTableColumns: (onClickHandler: Dispatch<SetStateAction<string>>) => mpdTableColsType[] = (onClickHandler) => RawTaskReportCols.map((header) => ({
  Header: <DataTableHeader title={header.Header} id={header.id} hideMode={false} onClickHandler={onClickHandler} />,
  title: header.Header,
  accessor: header.accessor,
  id: header.id,
  disableSortBy: false,
  width: header.width,
  disableResizing: true,
  Cell: ({ row }: mpdTask) => <DataTableCell maxLength={100} maxLines={6} cellValue={row.values[header.accessor]} />,
}));

/**
 * A function to get the index of tabs based on the availability of the data.
 * Displays the tab which has data.
 * eg: If 'calendar' has data and 'flight cycles, flight hours do not have data
 * Then 'calendar' tab should be selected by default on page load/refresh
 */
export const getTabIndexBasedOnDataAvailability = (multiChartData: chartProps[]) => {
  const foundIndex = multiChartData?.findIndex((type: chartProps) => type.chartDataset.length);
  // return default index as '0' if data is not present for all the tabs
  return foundIndex < 0 ? 0 : foundIndex;
};
