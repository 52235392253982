import React from 'react';
import './NoData.scss';
import apierror from '../../../assets/icons/api-error.svg';

type emptyDataPropsType = {
  message: string;
  type?: string;
  customClassName?: string;
};

function NoData(props: emptyDataPropsType) {
  const { message, type, customClassName } = props;

  const getErrorTag = () => (
    <div className="error-content">
      <img src={apierror} alt="" width="60" height="60" className="data-error" />
      <p>
        {message}
      </p>
    </div>

  );

  const getNoDataTag = () => (
    <div className="no-data  data-empty">
      <p>
        {message}
      </p>
    </div>
  );
  return (
    <div className={`no-data-component-cls ${customClassName}`}>
      {type === 'error' ? getErrorTag() : getNoDataTag()}
    </div>
  );
}

NoData.defaultProps = {
  type: 'no-data',
  customClassName: '',
};

export default NoData;
