import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DataTable,
  Divider,
  Chip,
} from '@airbus/components-react';
import './MpdRepositoryCompareFilterDialog.scss';
import TooltipComponent from '../Shared/MpdTooltip/mpdTooltip';
import { chipColumnWidth, comparePopupTooltipData } from '../Shared/MpdTooltip/Constants';

type mpdCompareFilterProps = {
  open: boolean;
  onClose: () => void;
  headers : mpdRepositoryCompareFilterColumnHeader[];
  data: mpdRepositoryCompareFilterResponseData[];
};

const MpdRepositoryCompareFilterDialog = (props: mpdCompareFilterProps) => {
  const {
    open, onClose, headers, data,
  } = props;
  const [tableHeaders, setTableHeaders] = useState<mpdRepositoryCompareFilterColumnHeader[]>([]);
  const [tableData, setTableData] = useState<mpdRepositoryCompareFilterColumnData[]>([]);

  useEffect(() => {
    const newTableHeaders: mpdRepositoryCompareFilterColumnHeader[] = [];
    const columnName = headers;
    columnName.forEach((column) => {
      const tmpObject = {
        ...column,
        Header: (
          <>
            <b className="compare-filter-dialog-headers">{column.Header}</b>
            {column.accessor === 'reference' && <Chip className="reference-file-chip" aria-label="ReferenceFileChip" label="Reference File" variant="success" />}
            {column.Header === 'Status' && <TooltipComponent title="Filter status" data={{ data: comparePopupTooltipData, columnWidth: chipColumnWidth }} placement="left" />}
          </>
        ),
        disableSortBy: true,
      };
      newTableHeaders.push(tmpObject);
    });
    setTableHeaders(newTableHeaders);

    const newTableData: mpdRepositoryCompareFilterColumnData[] = [];
    const columnValues: mpdRepositoryCompareFilterResponseData[] = data;
    columnValues.forEach((row: mpdRepositoryCompareFilterResponseData) => {
      let tmpObject: mpdRepositoryCompareFilterColumnData = {
        reference: <span style={{ whiteSpace: 'pre-wrap' }}>{row.reference}</span>,
        source: <span style={{ whiteSpace: 'pre-wrap' }}>{row.source}</span>,
        status: <Chip className="status-chip" aria-label="StatusChip" label={`${row.status}`} style={{ backgroundColor: `${row.chipColor}`, color: '#fff' }} />,
      };
      if (row.reference === 'Aircraft Series' || row.reference === 'MSN Registration Number' || row.reference === 'MSN Registeration Number') {
        tmpObject = {
          reference: <b>{row.reference}</b>,
          source: <b>{row.source}</b>,
          status: undefined,
        };
      } else if (row.reference === null) {
        tmpObject = {
          ...tmpObject,
          reference: '-',
          source: <span style={{ whiteSpace: 'pre-wrap' }}>{row.source}</span>,
        };
      } else if (row.source === null) {
        tmpObject = {
          ...tmpObject,
          reference: <span style={{ whiteSpace: 'pre-wrap' }}>{row.reference}</span>,
          source: '-',
        };
      }
      newTableData.push(tmpObject);
    });
    setTableData(newTableData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Dialog className="compare-filter-dialog-cls compare-filter-dialog-cls-custom" open={open} onClose={onClose}>
      <DialogHeader><b>Filter Comparison</b></DialogHeader>
      <Divider />
      <DialogContent>
        <DataTable
          className="compare-filter-table"
          height={450}
          columns={tableHeaders}
          data={tableData}
          disablePagination
          allowVirtualization
        />
      </DialogContent>
    </Dialog>
  );
};

export default MpdRepositoryCompareFilterDialog;
