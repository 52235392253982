import React from 'react';
import {
  DataTable, Button, Modal, Typography,
} from '@airbus/components-react';
import './SMPCTableModal.scss';

type SMPCTableModalProps = {
  modalOpen: boolean;
  columns: mpdTableColsType[];
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  data: Array<any>
};

const SMPCTableModal: React.FC<SMPCTableModalProps> = function SMPCTableModal(props: SMPCTableModalProps) {
  const {
    modalOpen,
    columns,
    data,
  } = props;
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={modalOpen}
      className="smpc-table-modal-cls"
    >
      <div
        className="modal-container"
      >
        <div>
          <Typography variant="large">Configuration</Typography>
          <Typography variant="small">Customization of the fleet</Typography>
          <DataTable
            className="margin-table"
            columns={columns}
            data={data}
          />
          <Button variant="primary" aria-label="Confirm">Confirm</Button>
        </div>
      </div>
    </Modal>
  );
};

export default SMPCTableModal;
