import React from 'react';
import './MpdEmptyDataComponent.scss';
import { AssignmentLateOutline, WarningOutline } from '@airbus/icons/react';
import { Typography } from '@airbus/components-react';

type mpdEmptyDataComponentProps = {
  iconName: string;
  title: string;
  subTitle: string;
};

const ICONS: { [key: string]: JSX.Element } = {
  AssignmentLateOutline: <AssignmentLateOutline />,
  WarningOutline: <WarningOutline />,
};

/**
 * This component is used to indicate the empty screen with a custom title, message and icon.
 *
 * To use this component, import this MpdEmptyDataComponent in your file.
*/
const MpdEmptyDataComponent = function MpdEmptyDataComponent(props: mpdEmptyDataComponentProps) {
  const { iconName, title, subTitle } = props;
  return (
    <div className="mpd-repository-emtl-content-cls">
      <div>
        <div className="mpd-repository-emtl-icon">{ICONS[iconName]}</div>
        <h6 className="mpd-repository-emtl-content-header">{title}</h6>
        <Typography className="mpd-repository-emtl-content-sub-header">{subTitle}</Typography>
      </div>
    </div>
  );
};

export default MpdEmptyDataComponent;
