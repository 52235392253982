enum Dashboard {
    OPERATOR_SELECTION = 'OPERATOR_SELECTION',
    PROGRAM_CUSTOMIZATION_CARD = 'PROGRAM_CUSTOMIZATION_CARD',
    PROGRAM_ANALYSIS_CARD = 'PROGRAM_ANALYSIS_CARD',
    DELIVERY_REPOSITORY_CARD = 'DELIVERY_REPOSITORY_CARD',
    SMP_PREMIUM_CARD = 'SMP_PREMIUM_CARD',
    PLAN_MAINTENANCE_SERVICE_CARD = 'PLAN_MAINTENANCE_SERVICE_CARD',
    PLANNING_ANALYSIS_CARD = 'PLANNING_ANALYSIS_CARD'
}

enum ProgramAnalysis {
    ENRICH = 'ENRICH',
    FILTER_PANEL = 'FILTER_PANEL',
    TASK_REPORT_OVERVIEW = 'TASK_REPORT_OVERVIEW',
    TASK_REPORT_ANALYSIS = 'TASK_REPORT_ANALYSIS',
    TASK_REPORT_UNSCHEDULE = 'TASK_REPORT_UNSCHEDULE',
    TASK_REPORT_ECONOMIC = 'TASK_REPORT_ECONOMIC',
    TASK_REPORT_PREDICTION = 'TASK_REPORT_PREDICTION',
    REPOSITORY = 'REPOSITORY'
}

enum ProgramCustomization {
    EMTL_DIRECT_DOWNLOAD = 'EMTL_DIRECT_DOWNLOAD',
    EMTL_EXPORT = 'EMTL_EXPORT',
    OTHER_SOURCES = 'OTHER_SOURCES',
    MRBR = 'MRBR',
    ALS = 'ALS',
    CMP = 'CMP',
    AMP_CREATOR = 'AMP_CREATOR'
}

enum ProgramCustomizationRepository {
    EMTL_COMPARE = 'EMTL_COMPARE',
    EMTL_DELTA_VIEW = 'EMTL_DELTA_VIEW',
    EMTL_DELTA_SOC = 'EMTL_DELTA_SOC',
    OTHER_SOURCES = 'OTHER_SOURCES',
    MRBR = 'MRBR',
    ALS = 'ALS',
    CMP = 'CMP',
    AMP_CREATOR = 'AMP_CREATOR'
}

export const features = {
  Dashboard,
  ProgramAnalysis,
  ProgramCustomization,
  ProgramCustomizationRepository,
};
