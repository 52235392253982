import { useState, useLayoutEffect, useContext } from 'react';
import { Auth, API } from 'aws-amplify';
import { Methods } from './apiTypes';
import { appContext } from '../context/userContext';
import { secureLS } from '../localStorageUtil/lsHelper';

/* A custom hook component to get, put, post and delete from
     API which can be used across the entire application.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFetch = (path: string, method: Methods): [any, string] => {
  const { icao } = useContext(appContext);
  const [data, setData] = useState();
  const [error, setError] = useState('');
  const apiName = 'API_SMAC';
  const fetchData = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession?.idToken?.jwtToken;
    // Update below once operator in fn paramas
    const operator = secureLS.get('operator');
    const request = {
      headers: {
        Authorization: token,
        Operator: operator || icao || 'None',
      },
    };
    await API[method](apiName, path, request)
      .then((response) => {
        setData(response);
      })
      .catch((e) => {
        setError(e.response);
      });
  };
  /* uselayoutEffect is used to make the api call synchronously after
  the dom mutation also it runs before browser paints the new changes */
  useLayoutEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
  return [data, error];
};
