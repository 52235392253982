enum Dashboard {
  SMAC_DB_ALL_RW_AL = 'SMAC_DB_ALL_RW_AL',
  SMAC_DB_SKYWISE_RW_AL = 'SMAC_DB_SKYWISE_RW_AL',
  SMAC_DB_PREMIUM_RW_AL = 'SMAC_DB_PREMIUM_RW_AL',
  SMAC_DB_MPE_RW_ABUS = 'SMAC_DB_MPE_RW_ABUS'
}

enum ProgramAnalysis {
  SMAC_PA_ALL_RW_AL = 'SMAC_PA_ALL_RW_AL',
  SMAC_PA_SKYWISE_RW_AL = 'SMAC_PA_SKYWISE_RW_AL',
  SMAC_PA_PREMIUM_RW_AL = 'SMAC_PA_PREMIUM_RW_AL',
  SMAC_PA_MPE_RW_ABUS = 'SMAC_PA_MPE_RW_ABUS'
}

enum ProgramCustomization {
  SMAC_PC_ALL_RW_AL = 'SMAC_PC_ALL_RW_AL',
  SMAC_PC_SKYWISE_RW_AL = 'SMAC_PC_SKYWISE_RW_AL',
  SMAC_PC_PREMIUM_RW_AL = 'SMAC_PC_PREMIUM_RW_AL',
  SMAC_PC_MPE_RW_ABUS = 'SMAC_PC_MPE_RW_ABUS'
}

enum ProgramCustomizationRepository {
  SMAC_PC_REPOSITORY_ALL_RW_AL = 'SMAC_PC_REPOSITORY_ALL_RW_AL',
  SMAC_PC_REPOSITORY_SKYWISE_RW_AL = 'SMAC_PC_REPOSITORY_SKYWISE_RW_AL',
  SMAC_PC_REPOSITORY_PREMIUM_RW_AL = 'SMAC_PC_REPOSITORY_PREMIUM_RW_AL',
  SMAC_PC_REPOSITORY_MPE_RW_ABUS = 'SMAC_PC_REPOSITORY_MPE_RW_ABUS'
}

export const sasPermissions = {
  Dashboard,
  ProgramAnalysis,
  ProgramCustomization,
  ProgramCustomizationRepository,
};
