import { Flex } from '@airbus/components-react';
import React from 'react';
import CardComponent from '../CardComponent/CardComponent';
import './ApplicationList.scss';
import cards from '../../../__mocks__/HomePage/cards';
import { LOCALES } from '../../../assets/locale';

const ApplicationList: React.FC = function ApplicationList() {
  return (
    <Flex direction="column">
      <div className="appl-ovrv-1">
        <Flex direction="row">
          <div className="appl-ovrv-2">
            <strong className="appl-ovrv-str-1">{LOCALES.OVERVIEW_TEXT}</strong>
          </div>
        </Flex>
      </div>
      <div className="appl-card-outer">
        <Flex direction="row" wrap>
          {cards.map((item) => (
            <Flex key={item.id} direction="column">
              <div className="appl-card">
                <CardComponent data={item} />
              </div>
            </Flex>
          ))}
        </Flex>
      </div>
    </Flex>
  );
};

export default ApplicationList;
