export const changeFeatureToggleStateReducer = (state: featureToggleState, action: { payload: featureList | null }) => ({
  ...state,
  features: action.payload && action.payload.featuretogglelist,
  isError: false,
});

// Async reducers
export const fetchFeatureToggleListPending = (state: featureToggleState) => ({
  ...state,
  features: {},
  isError: false,
});

export const fetchFeatureToggleListFullfilled = (state: featureToggleState, action: { payload: featureList }) => ({
  ...state,
  features: action.payload.featuretogglelist,
  disclaimer: {
    version: action.payload.disclaimer.version,
    description: action.payload.disclaimer.description,
  },
  isError: false,
});

export const fetchFeatureToggleListRejected = (state: featureToggleState) => ({
  ...state,
  features: {},
  disclaimer: {
    version: -1,
    description: '',
  },
  isError: true,
});
