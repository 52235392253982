// Slice
export const MPD_DRAWER_SLICE = 'mpdDrawer';

// Async thunk actions
export const FETCH_MPD_TASKS = 'fetchMPDTasks';
export const CREATE_MPD_FILTER = 'createMPDFilter';

// API Path
export const FILTER_API_PATH = 'api/csm/filters';

// Effective Maintenance Task options
export const RADIO_OPTIONS = [
  {
    label: 'EMTL', value: 'EMTL', description: 'EMTL is the effective maintenance task list based on your MSN configuration', disabled: false,
  },
  {
    label: 'Other Sources', value: 'Other Sources', description: null, disabled: true,
  },
  {
    label: 'MRBR', value: 'MRBR', description: null, disabled: true,
  },
  {
    label: 'ALS', value: 'ALS', description: null, disabled: true,
  },
  {
    label: 'CMP', value: 'CMP', description: null, disabled: true,
  },
];
