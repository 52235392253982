import React, { useState } from 'react';
import {
  Select, ComboBox, Col, Row, Button, Chip,
} from '@airbus/components-react';
import { createMpdRepositoryFilter } from '../../models/mpdRepositoryModel/mpdRepositoryAsyncThunk';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';
import { updateRepositoryFilter } from '../../models/mpdRepositoryModel/mpdRepositorySlice';
import { debounceUtil } from '../../utils/commonUtils/debounce/debounce';

type MpdRepositorySearchProps = {
  acProgram: string,
  moduleName: string
};

const MpdRepositorySearch = (props: MpdRepositorySearchProps) => {
  const {
    acProgram, moduleName,
  } = props;
  const dispatch = useAppDispatch();
  const {
    RepositoryFilterOptionsType: searchOptions, RepositoryFilterGroup: filterGroup,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  const [searchButton, setSearchButton] = useState(false);
  const [input, setInput] = useState('');
  // Adding some mili seconds of delay and waiting for user input
  const debounce = debounceUtil(600);

  return (
    <Row className="repository-search">
      {filterGroup.length ? (
        <Col aria-label="mpd-repository-filter-value" className="repository-search-legend">
          <b className="repository-search-filter">Filter</b>
          <Chip
            aria-label="mpd-repository-filter-value-chip"
            style={{ whiteSpace: 'normal' }}
            label={filterGroup[0].issueNumber}
            onDelete={() => {
              dispatch(updateRepositoryFilter([]));
            }}
          />
        </Col>
      ) : null}
      <Col className="repository-search-legend">Filter by</Col>
      <Col sm={3} md={3} lg={3} xl={3} xxl={3}>
        <Select
          style={{ height: '32px' }}
          aria-label="Select an option"
          placeholder="Select an option"
          value="Issue"
          options={[{
            label: 'Issue',
            value: 'issueNumber',
          }]}
        />
      </Col>
      <Col className="repository-search-dropdown-2" sm={2} md={2} lg={2} xl={2} xxl={2}>
        <ComboBox
          aria-label="repo-search-dropdown-2"
          placeholder="Start typing"
          options={searchOptions}
          value={input}
          className="repository-search-dropdown-2-inline"
          onChange={(val) => {
            /* istanbul ignore else */
            if (typeof val === 'string') {
              setInput(val);
              setSearchButton(val.length !== 0);
            }
          }}
          onInput={async (ev) => {
            const selectedInput = (ev?.target as HTMLInputElement).value;
            await debounce();
            dispatch(createMpdRepositoryFilter(acProgram, moduleName, 'issueNumber', selectedInput)());
          }}
        />
      </Col>
      <Col className="mpd-repository-search-button" sm={2} md={2} lg={2} xl={2} xxl={2}>
        <Button
          variant="primary"
          disabled={!searchButton}
          onClick={() => {
            dispatch(updateRepositoryFilter([{ issueNumber: input }]));
            setInput('');
            setSearchButton(false);
          }}
          style={{ height: '32px' }}
        >
          Search
        </Button>
      </Col>
    </Row>
  );
};

export default MpdRepositorySearch;
