import { createSlice } from '@reduxjs/toolkit';
import { APIPOLLING_SLICE } from './constants';
import { createAPIPollingFulfilled, createAPIPollingRejected, startPollingReducer } from './APIPollingReducer';
import { createAPIPolling } from './APIPollingAsyncThunk';
import { PollState, PollingIds } from './APIPollingTypes';

export const initialState: PollState = {
  pollRequest: {
    id: PollingIds.EmtlFileExport,
    payload: {},
  },
  pollResponse: '',
};

const apiPollingSlice = createSlice({
  name: APIPOLLING_SLICE,
  initialState,
  reducers: {
    // sync action creators
    startPolling: startPollingReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(createAPIPolling().fulfilled, createAPIPollingFulfilled);
    builder.addCase(createAPIPolling().rejected, createAPIPollingRejected);
  },
});

export const { startPolling } = apiPollingSlice.actions;
export default apiPollingSlice;
