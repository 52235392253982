import { PollAction, PollState } from './APIPollingTypes';

export const startPollingReducer = (state: PollState, action: PollAction) => ({
  ...state,
  pollRequest: action.payload,
});

export const createAPIPollingFulfilled = (state: PollState, action: PollState['pollResponse']) => ({
  ...state,
  pollResponse: action.payload,
});

export const createAPIPollingRejected = (state: PollState, action: PollState['pollResponse']) => ({
  ...state,
  pollResponse: {
    message: action.payload,
    error: true,
  },
});
