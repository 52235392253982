import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@airbus/components-react';
import './MpdRepositoryDownloadMenu.scss';
import {
  Download,
} from '@airbus/icons/react';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { createRepoExportTask } from '../../models/mpdRepositoryModel/mpdRepositoryAsyncThunk';
import { RootState } from '../../store/store';

type mpdRepoDownloadMenuProps = {
    fileTypes: string[];
    fileKey: string;
    fileName: string;
};

const MpdRepositoryDownloadMenu = (props: mpdRepoDownloadMenuProps) => {
  const dispatch = useAppDispatch();
  const {
    RepositoryButtonDisabled: fileBtnsDisabled,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  const { fileTypes, fileKey, fileName } = props;
  const handleOnDownload = (fileType: string) => {
    dispatch(createRepoExportTask(fileKey, fileType)());
  };
  return (
    <span className="mpd-repository-download-menu">
      <Menu>
        <MenuButton aria-label="downloadTask" variant="secondary" className="download-button" disabled={fileBtnsDisabled === fileName}>Download</MenuButton>
        <MenuList className="mpd-repository-download-menu-popover">
          {fileTypes.map((val) => {
            return (
              <MenuItem
                key={val}
                icon={<Download />}
                onClick={
                    () => {
                      handleOnDownload('xlsx');
                    }
                }
              >
                {` Download (${val})`}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </span>
  );
};

export default MpdRepositoryDownloadMenu;
