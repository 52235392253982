// Function to debounce
export const debounceUtil = (delay: number) => {
  let waitTimer: NodeJS.Timeout;
  return async function setTheTimer() {
    clearTimeout(waitTimer);
    await new Promise((resolve) => {
      waitTimer = setTimeout(resolve, delay);
    });
  };
};
