import { createSlice } from '@reduxjs/toolkit';
import { PABuildListState } from './PABuildListTypes';
import { PA_BUILD_LIST } from './constants';
import {
  clearPABuildListReducer, updatePABuildListReducerFulfilled, updatePABuildListReducerPending, updatePABuildListReducerRejected, updatePABuildListReducer,
} from './PABuildListReducer';
import { fetchPABuildListData } from './PABuildListAsyncThunk';

export const initialState: PABuildListState = {
  BuildIdList: [],
  BuildIdListCount: 0,
  error: '',
  loading: false,
};

const PABuildListSlice = createSlice({
  name: PA_BUILD_LIST,
  initialState,
  reducers: {
    clearPABuildList: clearPABuildListReducer,
    updatePABuildList: updatePABuildListReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPABuildListData().pending, updatePABuildListReducerPending);
    builder.addCase(fetchPABuildListData().fulfilled, updatePABuildListReducerFulfilled);
    builder.addCase(fetchPABuildListData().rejected, updatePABuildListReducerRejected);
  },
});

export const {
  clearPABuildList,
  updatePABuildList,
} = PABuildListSlice.actions;
export default PABuildListSlice;
