export const unscheduleDashBoardQLinks = {
  title: 'Link to SRS',
  quickLinks: [{
    desc: 'SRS',
    link: 'https://core.skywise.com/workspace/foundry-slate/documents/skywise-reliability-services/latest?v_tab=3',
    type: 'external',
  },
  ],
};

export const taskReportQLinks = {
  title: 'Quick links',
  quickLinks: [
    {
      desc: 'Operator Task Reports received per year',
      link: 'finding-rate-charts',
      type: 'internal',
    },
    {
      desc: 'Finding rate by geo region',
      link: 'finding-rate-charts',
      type: 'internal',
    },
    {
      desc: 'Operator number of findings vs finding type',
      link: 'operator-finding-type-charts',
      type: 'internal',
    },
    {
      desc: 'Fleet-wide number of findings vs finding type',
      link: 'fleetwide-finding-type-charts',
      type: 'internal',
    },
    {
      desc: 'Number of Tasks Reports/Finding Rate by MSN',
      link: 'task-report-by-msn',
      type: 'internal',
    },
    {
      desc: 'Task Reports number and finding rate per actual interval',
      link: 'task-report-by-actual-int',
      type: 'internal',
    },
    {
      desc: 'Findings types and finding rate per cumulative utilization',
      link: 'task-report-by-cumulative-int',
      type: 'internal',
    },
  ],
};
