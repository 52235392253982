import { createSlice } from '@reduxjs/toolkit';
import { PA_REPOSITORY_SLICE } from './constants';
import {
  deleteBuildData, fetchPARepositoryData, retryBuildData, updateTitle,
} from './PARepositoryAsyncThunk';
import { PARepositoryState } from './PARepositoryTypes';
import {
  deleteBuildDataReducer, deleteBuildDataReducerPending, deleteBuildDataReducerRejected, retryBuildDataReducer, updatePARepositoryDataReducer, updatePARepositoryDataReducerPending, updatePARepositoryDataReducerRejected, updateTitleReducer, updatePARepositoryFiltersReducer, clearPARepoStateReducer,
} from './PARepositoryReducers';

export const initialState: PARepositoryState = {
  repositoryData: [],
  filters: undefined,
  responseMessage: '',
  loading: true,
  rebulidLoading: '',
};

const PARepositorySlice = createSlice({
  name: PA_REPOSITORY_SLICE,
  initialState,
  reducers: {
    updatePARepositoryFilters: updatePARepositoryFiltersReducer,
    clearPARepoState: clearPARepoStateReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPARepositoryData().pending, updatePARepositoryDataReducerPending);
    builder.addCase(fetchPARepositoryData().fulfilled, updatePARepositoryDataReducer);
    builder.addCase(fetchPARepositoryData().rejected, updatePARepositoryDataReducerRejected);
    builder.addCase(deleteBuildData().pending, deleteBuildDataReducerPending);
    builder.addCase(deleteBuildData().fulfilled, deleteBuildDataReducer);
    builder.addCase(deleteBuildData().rejected, deleteBuildDataReducerRejected);
    builder.addCase(retryBuildData().fulfilled, retryBuildDataReducer);
    builder.addCase(updateTitle().fulfilled, updateTitleReducer);
  },
});
export const { updatePARepositoryFilters, clearPARepoState } = PARepositorySlice.actions;
export default PARepositorySlice;
