import './TableFilterChips.scss';
import React, { ReactElement } from 'react';
import { Reset } from '@airbus/icons/react';
import {
  Button, Inline, Typography, Row, Col, Chip,
} from '@airbus/components-react';
import { filtersValues } from '../../../../models/programAnalysis/programAnalysisTypes';

type tableFilterChipsProps = {
  deleteFromFilterGroup?(deletePair: searchPair[]): void;
  columnsOptions: Array<{ label: string, value: string, id: string }>;
  filterGroup?: filtersValues;
};

const TableFilterChips: React.FC<tableFilterChipsProps> = function TableFilterChips(props: tableFilterChipsProps) {
  const { deleteFromFilterGroup, columnsOptions, filterGroup } = props;

  const displayChips = () => {
    const chipsArr: ReactElement[] = [];
    filterGroup?.forEach((element: { columnName: string; columnInput: string; }) => {
      const colLabel = columnsOptions.find((cols) => cols.id === element.columnName);
      chipsArr.push(
        <Chip
          aria-label="filterChip"
          className="table-filter-chip"
          title={`${colLabel?.label}: ${element.columnInput}`}
          label={`${colLabel?.label}: ${element.columnInput}`}
          onDelete={() => {
            if (filterGroup && deleteFromFilterGroup) {
              deleteFromFilterGroup([{ columnName: element.columnName, columnInput: element.columnInput }]);
            }
          }}
        />,
      );
    });
    return chipsArr;
  };

  return (
    <Row alignItems="center" className="table-filter-chips-cls">
      <Col xxs={12} md={12}>
        <Inline spacing="2-x" wrap>
          <Typography variant="small" className="bold-title">Filter</Typography>
          <Inline spacing="1-x" wrap>
            {displayChips()}
          </Inline>
          <Button
            variant="ghost"
            size="small"
            aria-label="tableFilterChipsClearAll"
            onClick={() => {
              if (filterGroup && deleteFromFilterGroup) {
                deleteFromFilterGroup(filterGroup.slice());
              }
            }}
          >
            <div className="icon">
              <Reset />
            </div>
            Clear All
          </Button>
        </Inline>
      </Col>
    </Row>
  );
};

TableFilterChips.defaultProps = {
  deleteFromFilterGroup: undefined,
  filterGroup: undefined,
};

export default TableFilterChips;
