import { createSlice } from '@reduxjs/toolkit';
import { UNSCHEDULED_EVENT_SLICE } from './constants';
import { fetchOIInfos, fetchOITaskInfos, fetchTableInfos } from './unscheduledEventAsyncThunk';
import {
  updateOIDataReducer,
  updateOIDataReducerPending,
  updateOIDataReducerRejected,
  updateOIDataTaskReducer,
  clearOIDataReducer,
  updateOIDataTaskReducerPending,
  updateOIDataTaskReducerRejected,
  updateTableDataReducer,
  updateTableDataReducerPending,
  updateTableDataReducerRejected,
  updateTableDataSearchOptionsReducer,
  updateTableDataFiltersGroupReducer,
  updateTableDataSearchPairReducer,
  deleteTableDataFiltersGroupReducer,
  updateEnhanceStateReducer,
  emptyTableFiltersGroupReducer,
  clearOIDataTaskReducer,
} from './unscheduledEventReducers';

export const initialState: unscheduledEventState = {
  oiData: [],
  oiTaskData: [],
  tableData: [],
  responseMessage: '',
  tableFilters: {
    searchPairData: { columnName: '', columnInput: '' },
    searchOptions: [],
    filterGroup: [],
  },
  loading: true,
  enhanceState: false,
};

const unscheduledEventSlice = createSlice({
  name: UNSCHEDULED_EVENT_SLICE,
  initialState,
  reducers: {
    updateTDSearchPair: updateTableDataSearchPairReducer,
    updateTDSearchOptions: updateTableDataSearchOptionsReducer,
    updateTDFilterGroup: updateTableDataFiltersGroupReducer,
    deleteTDFilterGroup: deleteTableDataFiltersGroupReducer,
    clearOIData: clearOIDataReducer,
    updateTableData: updateTableDataReducer,
    updateEnhanceToggle: updateEnhanceStateReducer,
    emptyTableFiltersGroup: emptyTableFiltersGroupReducer,
    clearOIDataTask: clearOIDataTaskReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOIInfos().pending, updateOIDataReducerPending);
    builder.addCase(fetchOIInfos().fulfilled, updateOIDataReducer);
    builder.addCase(fetchOIInfos().rejected, updateOIDataReducerRejected);
    builder.addCase(fetchOITaskInfos().pending, updateOIDataTaskReducerPending);
    builder.addCase(fetchOITaskInfos().fulfilled, updateOIDataTaskReducer);
    builder.addCase(fetchOITaskInfos().rejected, updateOIDataTaskReducerRejected);
    builder.addCase(fetchTableInfos().pending, updateTableDataReducerPending);
    builder.addCase(fetchTableInfos().fulfilled, updateTableDataReducer);
    builder.addCase(fetchTableInfos().rejected, updateTableDataReducerRejected);
  },
});

export const {
  updateTDSearchPair, updateTDSearchOptions, updateTDFilterGroup, deleteTDFilterGroup, updateTableData, updateEnhanceToggle, emptyTableFiltersGroup, clearOIData, clearOIDataTask,
} = unscheduledEventSlice.actions;
export default unscheduledEventSlice;
