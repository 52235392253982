import { createSlice } from '@reduxjs/toolkit';
import {
  fetchEmtlTableFilterColumnValReducer,
  updateEmtlTableFilterReducer,
  updateEmtlTableFilterValueReducer,
  createMpdEmtlTableFilterPending,
  createMpdEmtlTableFilterFulfilled,
  createMpdEmtlTableFilterRejected,
  updateEmtlTableFilterGroupReducer,
  deleteOneEmtlTableFilterReducer,
  clearAllEmtlTableFilterReducer,
  resetMpdEmtlTableValueReducer,
  createMpdEmtlTableExportTaskFulfilled,
  createMpdEmtlTableExportTaskRejected,
  updateEmtlTablePageNumberReducer,
  setEmtlTableExportButtonStateReducer,
  createMpdTableFulfilled,
} from './mpdEmtlTableReducers';
import {
  createMpdEmtlTableFilter, createEmtlTableFilter, createMpdEmtlTableExportTask, createMpdTable, createTadaEmtlTableFilter,
} from './mpdEmtlTableAsyncThunk';

export const initialState: emtlTableState = {
  EmtlTableDataRowFilteredDataType: [],
  EmtlTableHeaders: { EMTL: [], appendix: [] },
  EmtlTableFilterColType: [],
  EmtlTableDataRowCountType: 0,
  EmtlTableDataPageSizeType: 50,
  EmtlTableDataPageNumberType: 1,
  EmtlTableColumnType: 'mpd_task_revision_code',
  EmtlTableColumnDataType: '',
  EmtlTableColumnDataOptionType: [],
  EmtlTableColSanitizedOptionType: [],
  EmtlTableFilterGroup: [],
  EmtlTableExportLoading: false,
  EmtlTableExportFile: '',
  EmtlTableExportId: '',
  EmtlTableExportButton: true,
  loading: false,
  error: '',
};

const emtlTableSlice = createSlice({
  name: 'emtlTable',
  initialState,
  reducers: {
    // sync action creators
    updateEmtlTableFilterValue: updateEmtlTableFilterValueReducer,
    updateEmtlTableFilter: updateEmtlTableFilterReducer,
    updateEmtlTableFilterGroup: updateEmtlTableFilterGroupReducer,
    resetMpdEmtlTableValue: resetMpdEmtlTableValueReducer,
    deleteOneEmtlTableFilter: deleteOneEmtlTableFilterReducer,
    clearAllEmtlTableFilter: clearAllEmtlTableFilterReducer,
    updateEmtlTablePageNumber: updateEmtlTablePageNumberReducer,
    updateEmtlExportButtonState: setEmtlTableExportButtonStateReducer,
  },
  // Async action creators
  extraReducers: (builder) => {
    builder.addCase(createEmtlTableFilter().fulfilled, fetchEmtlTableFilterColumnValReducer);
    builder.addCase(createTadaEmtlTableFilter().fulfilled, fetchEmtlTableFilterColumnValReducer);
    builder.addCase(createMpdEmtlTableFilter().pending, createMpdEmtlTableFilterPending);
    builder.addCase(createMpdEmtlTableFilter().fulfilled, createMpdEmtlTableFilterFulfilled);
    builder.addCase(createMpdEmtlTableFilter().rejected, createMpdEmtlTableFilterRejected);
    builder.addCase(createMpdTable().pending, createMpdEmtlTableFilterPending);
    builder.addCase(createMpdTable().fulfilled, createMpdTableFulfilled);
    builder.addCase(createMpdTable().rejected, createMpdEmtlTableFilterRejected);
    builder.addCase(createMpdEmtlTableExportTask().fulfilled, createMpdEmtlTableExportTaskFulfilled);
    builder.addCase(createMpdEmtlTableExportTask().rejected, createMpdEmtlTableExportTaskRejected);
  },
});

export const {
  updateEmtlTableFilterValue,
  updateEmtlTableFilter,
  updateEmtlTableFilterGroup,
  resetMpdEmtlTableValue,
  deleteOneEmtlTableFilter,
  clearAllEmtlTableFilter,
  updateEmtlTablePageNumber,
  updateEmtlExportButtonState,
} = emtlTableSlice.actions;
export default emtlTableSlice;
