import React, { Dispatch, SetStateAction } from 'react';
import { Chip } from '@airbus/components-react';
import DataTableHeader from '../../../Shared/DataTable/DataTableHeader';
import DataTableCell from '../../../Shared/DataTable/DataTableCell';
import { tableColsType } from '../../../Shared/GenericTable/GenericTable';
import TooltipComponent from '../../../Shared/MpdTooltip/mpdTooltip';
import { socTableTooltipData, chipColumnWidth } from '../../../Shared/MpdTooltip/Constants';
import { tableCellMaxLength, tableCellMaxLines } from '../../../Shared/GenericTable/__mocks__/GenericTable.mock';

const TableColumnState = {
  Shown: 'shown',
  Hidden: 'hidden',
};

const chipColor: { [k: string]: string } = {
  D: '#f23346',
  N: '#255fcc',
  R: '#919cb0',
};

const generateTableColumns: (onClickHandler: Dispatch<SetStateAction<string>>, tableColumns: Array<tableColsType>) => mpdTableColsType[] = (onClickHandler, tableColumns) => (
  tableColumns.map((column) => ({
    Header: <DataTableHeader tooltip={column.accessor === 'mvt' ? <TooltipComponent title="MVT Status" data={{ data: socTableTooltipData, columnWidth: chipColumnWidth }} placement="left" /> : undefined} title={column.Header} id={column.accessor} hideMode={false} onClickHandler={onClickHandler} />,
    title: column.Header,
    accessor: column.accessor,
    id: column.accessor,
    disableSortBy: true,
    width: column.width,
    disableResizing: true,
    Cell: ({ row }: mpdTask) => {
      const value = row.values[column.accessor];
      return column.accessor === 'mvt'
        ? <Chip className="mvt-chip" aria-label="mvt-chip" label={value} style={{ backgroundColor: chipColor[value], color: '#fff' }} />
        : <DataTableCell maxLength={tableCellMaxLength} maxLines={tableCellMaxLines} cellValue={value} />;
    },
  }))
);

export {
  TableColumnState, generateTableColumns,
};
