import { createSlice } from '@reduxjs/toolkit';
import {
  createToastReducer, clearToastReducer, clearAllToastReducer, setToastStatusReducer,
} from './toastReducer';

export const initialState: toastState = {
  toastList: [],
};

const toastSlice = createSlice({
  name: 'toastSlice',
  initialState,
  reducers: {
    // sync action creators
    createToast: createToastReducer,
    clearToast: clearToastReducer,
    clearAllToast: clearAllToastReducer,
    setToastStatus: setToastStatusReducer,
  },
});

export const {
  createToast, clearToast, clearAllToast, setToastStatus,
} = toastSlice.actions;
export default toastSlice;
