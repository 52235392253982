import React from 'react';
import { COLOR_CODES } from '../../utils/chartUtils/chartLib';
import { defaultChartOptions } from '../../utils/chartUtils/pieDefaultOptionUtil';
import { getChartComponent } from '../../utils/chartUtils/chart.component';
import { maintenanceTask } from '../../models/programAnalysis/programAnalysisTypes';

type MaintTaskChartProps = {
  data: maintenanceTask[];
  isZoomed?: boolean;
};

/**
 * Function extracting only what we want from the data, and returning a bunch of config
 * for the Pie Chart.
 * @param data The raw data to be treated
 * @returns Configuration to pass to the pie chart
 */
const getChartData = (data: maintenanceTask[]) => {
  const countCategories: { [key: string]: number } = {};

  /* we count how many tasks of each category we got */
  data.forEach((item: maintenanceTask) => {
    const split = item?.task_distribution?.split('##');
    split?.forEach((it: string) => {
      if (it !== '' && countCategories[it]) {
        countCategories[it] += 1;
      } else if (it !== '' && !countCategories[it]) {
        countCategories[it] = 1;
      }
    });
  });

  /* If we don't have certain categories, we chose to take only the categs
    that we have in data in order not to polute the pie legend
  */
  const labels = Object.keys(countCategories).map((item) => {
    return item.replace(/_/g, ' ');
  });

  const dataValues = Object.values(countCategories);
  const backgroundColor = Array.from(Array(data.length).keys()).map((id, idx) => COLOR_CODES[idx]);

  const datasets = [{ label: '', data: dataValues, backgroundColor }];
  return { datasets, labels };
};

const MaintTaskChart: React.FC<MaintTaskChartProps> = function MaintTaskChart(props: MaintTaskChartProps) {
  const { data, isZoomed } = props;
  defaultChartOptions.plugins.legend.position = 'right';
  const PieChart = getChartComponent('pie');

  const chartOpts = !isZoomed ? { ...defaultChartOptions, plugins: { ...defaultChartOptions.plugins } } : { ...defaultChartOptions, plugins: { ...defaultChartOptions.plugins, legend: { ...defaultChartOptions.plugins.legend, position: 'top' } } };
  return <PieChart data={getChartData(data)} options={chartOpts} />;
};

MaintTaskChart.defaultProps = {
  isZoomed: false,
};
export default MaintTaskChart;
