import './tableControls.scss';
import React, {
  SetStateAction, Dispatch,
} from 'react';
import {
  Button, Col, Inline, Row, Typography,
} from '@airbus/components-react';
import {
  VisibilityOff,
} from '@airbus/icons/react';
import TableSearch from '../../TableSearch/TableSearch';
import { SearchableColumns, filtersValues, taskListFilters } from '../../../../models/programAnalysis/programAnalysisTypes';

type tableControlsProps = {
  tableTitle?: string | React.JSX.Element;
  searchColumns: SearchableColumns[];
  showHiddenCols: React.Dispatch<SetStateAction<boolean>>;
  isDataDynamic?: boolean;
  hideFilterChips?: boolean;
  /** If true, the filter chips (which shows the applied filters) will be hidden. */
  filters: taskListFilters;
  setFilters: Dispatch<SetStateAction<taskListFilters>>;
  data: Record<string, unknown>[];
  updateColumnFilter?(columnName: string): void;
  updateColumnFilterValue?(value: string): void;
  fetchColumnFilterValues?(userInput: string): void;
  updateFilterGroup?(): void;
  deleteFromFilterGroup?(deletePair: searchPair[]): void;
  searchPair?: filtersValues;
  columnFilter?: filtersValues;
  filterGroup?: filtersValues;
  noSearchKeySizeLimitColumns?: Array<string>;
  hideCustomizeColumn?: boolean;
  hideFilterBy?: boolean;
  /** List of columns names, for which a keystroke limit to trigger onChange in search component is set to 0.
   *  i.e. The OnChange for the search value will be triggered on every keystroke for these columns.
  */
};

const TableControls: React.FC<tableControlsProps> = function TableControls(props: tableControlsProps) {
  const {
    tableTitle,
    filters,
    setFilters,
    data,
    searchColumns,
    isDataDynamic,
    hideFilterChips,
    showHiddenCols,
    updateColumnFilter,
    updateColumnFilterValue,
    fetchColumnFilterValues,
    updateFilterGroup,
    deleteFromFilterGroup,
    searchPair,
    columnFilter,
    filterGroup,
    noSearchKeySizeLimitColumns,
    hideCustomizeColumn,
    hideFilterBy,
  } = props;

  const renderTableTitle = () => (
    (typeof tableTitle === 'string')
      ? <Typography variant="large"><b>{tableTitle}</b></Typography>
      : tableTitle
  );

  return (
    <Row alignItems="center">
      <Col xxs={12} md={4}>
        {tableTitle && renderTableTitle()}
      </Col>
      <Col xxs={12} md={8}>
        <Inline spacing="1-x" align="end" className="generic-table-controls-cls content-right">
          {!hideFilterBy && (
            <span className="table-search-custom">
              <TableSearch
                filters={filters}
                setFilters={setFilters}
                data={data}
                columnsOptions={searchColumns}
                isDataDynamic={isDataDynamic}
                updateColumnFilter={updateColumnFilter}
                updateColumnFilterValue={updateColumnFilterValue}
                fetchColumnFilterValues={fetchColumnFilterValues}
                updateFilterGroup={updateFilterGroup}
                deleteFromFilterGroup={deleteFromFilterGroup}
                searchPair={searchPair}
                columnFilter={columnFilter}
                filterGroup={filterGroup}
                hideFilterChips={hideFilterChips}
                noSearchKeySizeLimitColumns={noSearchKeySizeLimitColumns}
              />
            </span>
          )}
          {!hideCustomizeColumn && (
            <span>
              <Inline spacing="1-x" wrap>
                <Button
                  variant="secondary"
                  aria-label="Customized Columns"
                  size="small"
                  onClick={() => {
                    showHiddenCols(true);
                  }}
                >
                  <div className="icon">
                    <VisibilityOff />
                  </div>
                  Customize Columns
                </Button>
              </Inline>
            </span>
          )}
        </Inline>
      </Col>
    </Row>
  );
};

TableControls.defaultProps = {
  isDataDynamic: false,
  hideFilterChips: false,
  updateColumnFilter: undefined,
  updateColumnFilterValue: undefined,
  fetchColumnFilterValues: undefined,
  updateFilterGroup: undefined,
  deleteFromFilterGroup: undefined,
  searchPair: undefined,
  columnFilter: undefined,
  filterGroup: undefined,
  tableTitle: undefined,
  noSearchKeySizeLimitColumns: [],
  hideCustomizeColumn: false,
  hideFilterBy: false,
};

export default TableControls;
