import React from 'react';
import { useAppSelector } from '../../../store/hooksTypes';
import { RootState } from '../../../store/store';

interface FeatureErrorBoundryProps {
  children: JSX.Element | null;
}

const FeatureErrorBoundry = function FeatureErrorBoundry(errorProps: FeatureErrorBoundryProps) {
  const { isError: err } = useAppSelector((state: RootState) => state.featureToggle);
  const { children } = errorProps;
  return (
    err ? <p style={{ color: 'red' }}>Some features are missing, please refresh the page</p> : children
  );
};

export default FeatureErrorBoundry;
