import { createSlice } from '@reduxjs/toolkit';
import { UNSCHEDULED_EVENT_PRECOMP_SLICE } from './constants';
import {
  updateEnhanceStateReducer,
  updateOiHeaderCardDataReducerFullfilled,
  updateOiHeaderCardDataReducerPending,
  updateOiHeaderCardDataRejected, updateTaskNumberReducer,
  unscheduledEventsTableReducerFulfilled,
  unscheduledEventsTableReducerPending,
  unscheduledEventsTableReducerRejected,
  unscheduledPrecompFindingRateReducerFulfilled,
  unscheduledPrecompFindingRateReducerPending,
  unscheduledPrecompFindingRateReducerRejected,
  unscheduledEventsTableFilterReducerFulfilled,
  fleetwideGraphReducerFulfilled,
  fleetwideGraphReducerRejected,
  fleetwideGraphReducerPending,
  unscheduledPrecompRSIGraphReducerFulfilled,
  unscheduledPrecompRSIGraphReducerPending,
  unscheduledPrecompRSIGraphReducerRejected,
  clearTableFilterReducer,
} from './unscheduledEventsPrecompReducers';
import {
  fetchFleetwideGraphData, fetchOiHeaderCardData, fetchTableData, fetchUnscheduleFindingRateData, fetchTableFilterData,
  fetchRSIGraphData,
} from './unscheduledEventsPrecompAsyncThunk';

export const initialState: unscheduledEventPrecompState = {
  oiHeaderCardData: {
    number_of_oi_in_cancellation: '-',
    number_of_oi_in_delay: '-',
    number_of_oi_in_diversion: '-',
    number_of_oi_in_flight_turn_back: '-',
    number_of_oi: '-',
    oi_average_delay_in_minutes: '-',
    rsi: '-',
  },
  oiHeaderCardDataLoading: false,
  oiHeaderCardDataError: false,
  enhancedAnalysisState: false,
  taskNumber: '',
  table: {
    data: [],
    headers: [],
    filter_data: [],
    count: 0,
    loading: false,
    error: false,
  },
  findingRateGraph: {
    findingRate: {
      task_finding_rate: [],
      oi_reported: [],
      interval_range: [],
    },
    loading: false,
    error: false,
  },
  tableFilter: {
    column_name: '',
    column_value: [],
  },
  fleetwideGraph: {
    operator: [],
    yearRange: [],
    fleetwide: [],
    loading: false,
    error: false,
  },
  rsiGraph: {
    rsiGraphData: {
      rsi_rate: [],
      oi_reported: [],
      interval_range: [],
      type: '',
    },
    loading: false,
    error: false,
  },
};

const unscheduledEventPrecompSlice = createSlice({
  name: UNSCHEDULED_EVENT_PRECOMP_SLICE,
  initialState,
  reducers: {
    updateEnhancedAnalysisToggle: updateEnhanceStateReducer,
    updateTaskNumber: updateTaskNumberReducer,
    clearTableFilter: clearTableFilterReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOiHeaderCardData().pending, updateOiHeaderCardDataReducerPending);
    builder.addCase(fetchOiHeaderCardData().fulfilled, updateOiHeaderCardDataReducerFullfilled);
    builder.addCase(fetchOiHeaderCardData().rejected, updateOiHeaderCardDataRejected);
    builder.addCase(fetchTableData().pending, unscheduledEventsTableReducerPending);
    builder.addCase(fetchTableData().fulfilled, unscheduledEventsTableReducerFulfilled);
    builder.addCase(fetchTableData().rejected, unscheduledEventsTableReducerRejected);
    builder.addCase(fetchUnscheduleFindingRateData().pending, unscheduledPrecompFindingRateReducerPending);
    builder.addCase(fetchUnscheduleFindingRateData().fulfilled, unscheduledPrecompFindingRateReducerFulfilled);
    builder.addCase(fetchUnscheduleFindingRateData().rejected, unscheduledPrecompFindingRateReducerRejected);
    builder.addCase(fetchTableFilterData().fulfilled, unscheduledEventsTableFilterReducerFulfilled);
    builder.addCase(fetchFleetwideGraphData().pending, fleetwideGraphReducerPending);
    builder.addCase(fetchFleetwideGraphData().fulfilled, fleetwideGraphReducerFulfilled);
    builder.addCase(fetchFleetwideGraphData().rejected, fleetwideGraphReducerRejected);
    builder.addCase(fetchRSIGraphData().pending, unscheduledPrecompRSIGraphReducerPending);
    builder.addCase(fetchRSIGraphData().fulfilled, unscheduledPrecompRSIGraphReducerFulfilled);
    builder.addCase(fetchRSIGraphData().rejected, unscheduledPrecompRSIGraphReducerRejected);
  },
});

export const {
  updateEnhancedAnalysisToggle,
  updateTaskNumber,
  clearTableFilter,
} = unscheduledEventPrecompSlice.actions;
export default unscheduledEventPrecompSlice;
