import React from 'react';
import './QuickLinks.scss';
import { OpenInNew } from '@airbus/icons/react';

type QuickLink = {
  desc: string,
  link: string,
  type: string,
}

type QuickLinksProps = {
  quickLinks: QuickLink[];
  title: string;
}

/**
 * QuickLink.type - internal means within the page you can create a quick link using data-link
 * QuickLink.type - external means you can openup a new window with external url
 * @param props
 * @returns
 */
export default function QuickLinks(props: QuickLinksProps): JSX.Element {
  const {
    quickLinks, title,
  } = props;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    const elId = event.currentTarget.attributes['data-link'].value;
    document.getElementById(elId)?.scrollIntoView();
  };

  const renderLinks = (qlinks: QuickLink[]) => qlinks.map((qlink) => (
    qlink.type === 'internal'
      ? (
        <div className="quick-links" key={qlink.desc} data-link={qlink.link} onClick={handleClick} onKeyDown={handleClick} role="presentation" data-testid={qlink.link}>
          <span key={qlink.link} className="quick-links-desc">
            <strong>
              {qlink.desc}
            </strong>
          </span>
        </div>
      )
      : (
        <div className="quick-links" key={qlink.desc} role="presentation" data-testid={qlink.link}>
          <a key={qlink.desc} className="quick-links-desc" href={qlink.link} target="_blank" rel="noreferrer">
            <span key={qlink.link} className="quick-links-desc">
              <OpenInNew className="quick-link-icon" />
              <strong>
                {qlink.desc}
              </strong>
            </span>
          </a>
        </div>
      )
  ));

  return quickLinks && (
    <div className="quick-link-cls">
      <div className="quick-container">
        <div className="quick-links-desc">
          <span className="quick-links-title">
            <strong>{title}</strong>
          </span>
        </div>
        <div>
          {renderLinks(quickLinks)}
        </div>
      </div>
    </div>
  );
}
