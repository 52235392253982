export const setUserPermissionsReducer = (
  state: userPermissionState,
  action: userPermissionAction,
) => {
  const featurePermissions: feature[] = [];

  // If payload doesn't contain the permissions or the featurePermissions, then, don't change the state.
  if (!action.payload.permissions || !action.payload.featurePermissions) {
    return state;
  }

  // Extract the entries from the action payload.
  const entries = Object.entries(action.payload.featurePermissions);

  // Loop over the entries to generate the features in required structure.
  entries.forEach(([sasPermissionCode, featureList]) => {
    const features = featureList.map((feature) => ({ featureCode: feature, sasPermissionCode }));
    featurePermissions.push(...features);
  });

  return {
    ...state,
    sasPermissions: action.payload.permissions,
    featurePermissions,
  };
};
