/* eslint-disable @typescript-eslint/no-explicit-any */
import { Methods } from '../../utils/customHooks/apiTypes';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import {
  FETCH_PA_BUILD_LIST_DATA, CREATE_PA_BUILD_LIST_DATA, PA_BUILD_LIST_SCOPES_API_PATH,
} from './constants';

export const fetchPABuildListData: any = (operator: string, acProgram: string) => {
  const customAction = {
    sliceName: FETCH_PA_BUILD_LIST_DATA,
    actionType: CREATE_PA_BUILD_LIST_DATA,
  };
  const apiRequestObj = {
    path: `${PA_BUILD_LIST_SCOPES_API_PATH}?ac_program=${acProgram}&operator=${operator}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
