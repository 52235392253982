/* eslint-disable @typescript-eslint/no-explicit-any */

/*
We only export these in order to test them and increase coverage. It is pretty useless to test
functions like these.
*/
export const returnLabel = (context: any) => ` ${context.parsed}`;
export const formatterDatalabel = () => '';

export const defaultChartOptions: any = {
  maintainAspectRatio: false,
  responsive: true,
  animation: {
    animateScale: true,
    animateRotate: true,
  },

  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      fullSize: true,
      labels: {
        fontColor: '#323130',
        fontSize: 8,
        boxWidth: 20,
      },
    },
    tooltip: {
      callbacks: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        label: returnLabel,
      },
    },
    datalabels: {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      formatter: formatterDatalabel,
      labels: {
        title: {
          font: {
            size: 9,
            weight: 'bold',
          },
        },
      },
      color: 'white',
    },
  },
};
