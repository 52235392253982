import React from 'react';
import './PlannedMaintenanceServices.scss';

function PlannedMaintenanceServicesComponent() {
  return (
    <img
      className="planned-service-image-class"
      src="./S.MP_planningMaintenanceServices.jpg"
      alt="test"
    />
  );
}

export default PlannedMaintenanceServicesComponent;
