export const updateEnhanceStateReducer = (state: unscheduledEventPrecompState) => ({
  ...state,
  enhancedAnalysisState: !state.enhancedAnalysisState,
});

export const updateOiHeaderCardDataReducerFullfilled = (state: unscheduledEventPrecompState, action: unscheduledEventPrecompAction) => ({
  ...state,
  oiHeaderCardData: action.payload.data,
  oiHeaderCardDataLoading: false,
  oiHeaderCardDataError: false,
});

export const updateOiHeaderCardDataReducerPending = (state: unscheduledEventPrecompState) => ({
  ...state,
  oiHeaderCardDataLoading: true,
  oiHeaderCardDataError: false,
});

export const updateOiHeaderCardDataRejected = (state: unscheduledEventPrecompState) => ({
  ...state,
  oiHeaderCardDataLoading: false,
  oiHeaderCardDataError: true,
});

export const updateTaskNumberReducer = (state: unscheduledEventPrecompState, action: unscheduledEventPrecompTaskNumberAction) => ({
  ...state,
  taskNumber: action.payload,
});

export const unscheduledEventsTableReducerPending = (state: unscheduledEventPrecompState) => ({
  ...state,
  table: {
    ...state.table,
    loading: true,
    error: false,
  },
});

export const unscheduledEventsTableReducerFulfilled = (state: unscheduledEventPrecompState, action: unscheduledEventsPrecompTableAction) => ({
  ...state,
  table: {
    headers: action.payload.data.headers,
    filter_data: action.payload.data.filter_data,
    count: action.payload.data.count,
    data: action.payload.data.table,
    loading: false,
    error: false,
  },
});

export const unscheduledEventsTableFilterReducerFulfilled = (state: unscheduledEventPrecompState, action: unscheduledEventPrecompTableFilterAction) => ({
  ...state,
  tableFilter: {
    column_name: action.payload.data.column_name,
    column_value: action.payload.data.column_value,
  },
});

export const clearTableFilterReducer = (state: unscheduledEventPrecompState) => ({
  ...state,
  tableFilter: {
    column_name: '',
    column_value: [],
  },
});

export const unscheduledEventsTableReducerRejected = (state: unscheduledEventPrecompState) => ({
  ...state,
  table: {
    ...state.table,
    loading: false,
    error: true,
  },
});

// Finding rate graph reducers
export const unscheduledPrecompFindingRateReducerFulfilled = (state: unscheduledEventPrecompState, action: unscheduledPrecompFingRateAction) => ({
  ...state,
  findingRateGraph: {
    findingRate: {
      task_finding_rate: action.payload.data.task_finding_rate,
      oi_reported: action.payload.data.oi_reported,
      interval_range: action.payload.data.interval_range,
    },
    loading: false,
    error: false,
  },
});

export const unscheduledPrecompFindingRateReducerPending = (state: unscheduledEventPrecompState) => ({
  ...state,
  findingRateGraph: {
    ...state.findingRateGraph,
    loading: true,
  },

});

export const unscheduledPrecompFindingRateReducerRejected = (state: unscheduledEventPrecompState) => ({
  ...state,
  findingRateGraph: {
    ...state.findingRateGraph,
    loading: false,
    error: true,
  },
});

export const fleetwideGraphReducerPending = (state: unscheduledEventPrecompState) => ({
  ...state,
  fleetwideGraph: {
    ...state.fleetwideGraph,
    loading: true,
    error: false,
  },
});

export const fleetwideGraphReducerFulfilled = (state: unscheduledEventPrecompState, action: fleetwideGraphDataAction) => {
  const yearList = action.payload.data.occurrence_year;
  const yearRange: fleetwideGraph['yearRange'] = [
    action.payload.data.start_date,
    ...yearList.slice(1, yearList.length - 1),
    action.payload.data.end_date,
  ];

  return ({
    ...state,
    fleetwideGraph: {
      fleetwide: action.payload.data.average_of_oi_by_year_for_fleet_wide,
      operator: action.payload.data.average_of_oi_by_year_operator,
      yearRange,
      loading: false,
      error: false,
    },
  });
};

export const fleetwideGraphReducerRejected = (state: unscheduledEventPrecompState) => ({
  ...state,
  fleetwideGraph: {
    ...state.fleetwideGraph,
    loading: false,
    error: true,
  },
});

export const unscheduledPrecompRSIGraphReducerPending = (state: unscheduledEventPrecompState) => ({
  ...state,
  rsiGraph: {
    ...state.rsiGraph,
    loading: true,
  },
});

// RSI graph reducers
export const unscheduledPrecompRSIGraphReducerFulfilled = (state: unscheduledEventPrecompState, action: unscheduledEventPrecompRSIGraphAction) => ({
  ...state,
  rsiGraph: {
    rsiGraphData: action.payload.data,
    loading: false,
    error: false,
  },
});

export const unscheduledPrecompRSIGraphReducerRejected = (state: unscheduledEventPrecompState) => ({
  ...state,
  rsiGraph: {
    ...state.rsiGraph,
    loading: false,
    error: true,
  },
});
