import React from 'react';
import { Tooltip } from '@airbus/components-react';
import HeaderComponent from '../../Shared/DataTable/DataTableHeader';
import RadioButton from '../../Shared/DataTable/RadioButton';
import {
  FLEET_AVG_YIELD,
  lsdrTasks,
  MPD_TASK_CODE,
  MPD_TASK_DESCRIPTION,
  MPD_TASK_REFERENCE,
  NUMBER_OF_TASK_REPORT, OP_AVG_YIELD, OPERATOR_FINDING_RATE, SOURCE_DOCUMENT_TYPE, WORLD_WIDE_FINDING_RATE, ENRICH_TASK_OVERVIEW,
  OPERATOR_ENHANCED_FINDING_RATE,
  NO_OF_PREIDENIFIED_OI,
} from '../../../models/programAnalysis/constants';
import { maintenanceTask, taskListSort } from '../../../models/programAnalysis/programAnalysisTypes';
import { applySort } from './utils';
import { featureSwitchConfig } from '../../../utils/FeatureToggleUtil/FeatureToggleUtil';
import { checkFeaturePermission } from '../../../utils/userPermissionUtils/userPermissionUtils';
import './TaskList.scss';
import DataTableCell from '../../Shared/DataTable/DataTableCell';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueFormatted = (row: any, field: string, format: boolean, concat: string) => {
  let result = `${row?.original[field]?.toString().concat(concat)}`;
  if (row?.original[field] === 'N/A' || (row?.original[field] === 'nan')) {
    result = 'N/A';
  } else if (format) {
    result = `${row?.original[field]?.toFixed(1)}${concat}`;
  }
  return result;
};

// helper function to handle N/A conditions for given filed and display tooltip
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const columnTooltip = (row: any, field: string, format : boolean, concat : string) => {
  let textToDisplay = '';
  if (lsdrTasks.includes(row.original[MPD_TASK_CODE])) {
    textToDisplay = 'LSDR Task';
  } else if (!row.original[NUMBER_OF_TASK_REPORT]) {
    textToDisplay = 'No reporting data';
  }
  return textToDisplay === '' ? getValueFormatted(row, field, format, concat) : (
    <Tooltip
      placement="left"
      label={textToDisplay}
      className="custom-tooltip"
      arrowClassName="custom-tooltip-arrow"
      targetClassName="custom-tooltip-target"
    >
      <span>
        N/A
      </span>
    </Tooltip>
  );
};

// Define the accessor logic based on permission
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAccessor = (row: any, id: string, concat: string) => {
  if (row?.[id] === 'N/A') {
    return 'N/A';
  }
  return row?.[id]?.toString().concat(concat);
};

// Creating a custom logic to hide the column level based on the user permission
export const getColumn = (
  title: string,
  id: string,
  accessor: string,
  minWidth: number,
  maxWidth: number,
  width: number,
  align: string,
  concat: string,
): ColumnDefinition => {
  const hasPermission = checkFeaturePermission([ENRICH_TASK_OVERVIEW]);
  if (!hasPermission) {
    return {
      Header: <HeaderComponent title={title} premium />,
      id,
      accessor,
      minWidth,
      maxWidth,
      width,
      align,
      Cell: <div className="table-value-blur">NA</div>,
    };
  }

  return {
    Header: <HeaderComponent title={title} premium />,
    id,
    accessor: getAccessor,
    minWidth,
    maxWidth,
    width,
    align,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Cell: ({ row }: any) => (columnTooltip(row, id, false, concat)),
  };
};

export const sortTableData = (data: maintenanceTask[], dataSortBy: taskListSort[]) => {
  let sortedData = [];
  if (dataSortBy?.length > 1) {
    sortedData = applySort(data, dataSortBy.filter((item) => item.id !== MPD_TASK_REFERENCE) as taskListSort[]);
  } else {
    sortedData = applySort(data, dataSortBy as taskListSort[]);
  }
  return sortedData;
};

/**
 * This table is used to define the columns the DataTable in MaintenanceTaskTable will have.
 */

export const getTableColumns = (selectedMPD: string, userType: string) => {
  // Default Table Columns - "TASK NUMBER", "TASK DESCRIPTION","TASK CODE", "SOURCE", "WWFR", "OPSFR", "OPERATOR ENHANCED FINDING RATE"
  const defaultTableColumns = [
    {
      Header: '',
      id: 'selected',
      accessor: 'selected',
      width: 5,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => <RadioButton isSelected={row.original.mpd_task_reference === selectedMPD} />,
      disableSortBy: true,
    },
    {
      Header: <HeaderComponent title="Task Number" />,
      id: MPD_TASK_REFERENCE,
      accessor: MPD_TASK_REFERENCE,
      width: 120,
      align: 'left',
      disableSortBy: true,
    },
    {
      Header: <HeaderComponent title="Task Description" />,
      accessor: MPD_TASK_DESCRIPTION,
      width: 400,
      align: 'left',
      disableSortBy: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => <DataTableCell maxLength={500} maxLines={2} cellValue={row.original[MPD_TASK_DESCRIPTION]} />,
    },
    {
      Header: <HeaderComponent title="Task Code" />,
      accessor: MPD_TASK_CODE,
      id: MPD_TASK_CODE,
      width: 80,
      align: 'left',
      disableSortBy: true,
    },
    {
      Header: <HeaderComponent title="Source" />,
      accessor: SOURCE_DOCUMENT_TYPE,
      id: SOURCE_DOCUMENT_TYPE,
      width: 150,
      align: 'left',
      disableSortBy: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => <DataTableCell maxLength={50} maxLines={2} cellValue={row.original[SOURCE_DOCUMENT_TYPE]} />,
    },
    {
      Header: <HeaderComponent title="World Wide Sample Finding Rate" />,
      id: WORLD_WIDE_FINDING_RATE,
      accessor: (row: maintenanceTask) => (row[WORLD_WIDE_FINDING_RATE] ? row[WORLD_WIDE_FINDING_RATE].toString().concat('%') : 'N/A'),
      width: 100,
      align: 'left',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => columnTooltip(row, WORLD_WIDE_FINDING_RATE, true, '%'),
    },
    {
      Header: <HeaderComponent title="Operator Sample Finding Rate" />,
      id: OPERATOR_FINDING_RATE,
      accessor: (row: maintenanceTask) => (row[OPERATOR_FINDING_RATE] ? row[OPERATOR_FINDING_RATE].toString().concat('%') : 'N/A'),
      width: 100,
      align: 'left',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => columnTooltip(row, OPERATOR_FINDING_RATE, true, '%'),
    },
  ];

  // New Table Columns - "TASK NUMBER", "TASK DESCRIPTION","TASK CODE", "SOURCE", "WWFR", "OPSFR", "OPERATOR YIELD", "WORLD WIDE FLEET YIELD", "OPERATOR ENHANCED FINDING RATE", "NO.OF PRE-IDENTIFIED OI"
  const newTableColumns = [
    ...defaultTableColumns,
    {
      Header: <HeaderComponent title="Operator Yield" />,
      id: OP_AVG_YIELD,
      // eslint-disable-next-line no-nested-ternary
      accessor: (row: maintenanceTask) => (row[OP_AVG_YIELD] ? row[OP_AVG_YIELD].toString().concat('%') : 'N/A'),
      width: 100,
      align: 'left',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => columnTooltip(row, OP_AVG_YIELD, true, '%'),
    },
    {
      Header: <HeaderComponent title="Worldwide Fleet Yield" />,
      id: FLEET_AVG_YIELD,
      accessor: (row: maintenanceTask) => (row[FLEET_AVG_YIELD] ? row[FLEET_AVG_YIELD].toString().concat('%') : 'N/A'),
      width: 100,
      align: 'left',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: any) => columnTooltip(row, FLEET_AVG_YIELD, true, '%'),
    },
    getColumn('Operator Enhanced Finding Rate', OPERATOR_ENHANCED_FINDING_RATE, OPERATOR_ENHANCED_FINDING_RATE, 120, 120, 120, 'left', '%'),
    getColumn('Number of Pre-identified OI', NO_OF_PREIDENIFIED_OI, NO_OF_PREIDENIFIED_OI, 120, 120, 120, 'left', ''),
  ];
  /* feature toggling the table columns based on maintanence-task-list-new-columns featureToggle
   * for internal - newTableColumns
   * for external - defaultTableColumns */
  return featureSwitchConfig(
    { name: 'maintanence-task-list-new-columns', userType },
    newTableColumns,
    defaultTableColumns,
  );
};
