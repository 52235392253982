export const RawTaskReportCols = [
  {
    Header: 'A/C Type',
    accessor: 'ac_series',
    width: 120,
    id: 'ac_series',
    searchable: true,
    disableSortBy: true,
    align: 'center',
  },
  {
    Header: 'MSN',
    accessor: 'id_aircraft',
    width: 110,
    id: 'id_aircraft',
    searchable: true,
    disableSortBy: true,
    align: 'center',
  },
  {
    Header: 'Finding Type',
    accessor: 'finding_type_description',
    width: 140,
    id: 'finding_type_description',
    searchable: true,
    disableSortBy: true,
    align: 'left',
  },
  {
    Header: 'Finding Description',
    accessor: 'finding_description',
    width: 300,
    id: 'finding_description',
    searchable: true,
    disableSortBy: true,
    align: 'left',
  },
  {
    Header: 'Corrective Action',
    accessor: 'corrective_action',
    width: 280,
    id: 'corrective_action',
    searchable: true,
    disableSortBy: true,
    align: 'left',
  },
  {
    Header: 'Actual Interval (MO)',
    accessor: 'actual_interval_months',
    width: 100,
    id: 'actual_interval_months',
    searchable: true,
    disableSortBy: true,
    align: 'center',
  },
  {
    Header: 'Actual Interval (FH)',
    accessor: 'actual_interval_flight_hours',
    width: 100,
    id: 'actual_interval_flight_hours',
    searchable: true,
    disableSortBy: true,
    align: 'center',
  },
  {
    Header: 'Actual Interval (FC)',
    accessor: 'actual_interval_flight_cycles',
    width: 100,
    id: 'actual_interval_flight_cycles',
    searchable: true,
    disableSortBy: true,
    align: 'center',
  },
];

export const EnrichTaskReportCols = [
  { Header: 'A/C Type', accessor: 'A/C Type', width: 60 },
  { Header: 'MSN', accessor: 'MSN', width: 60 },
  {
    Header: 'Finding Type',
    accessor: 'Finding Type',
    width: 120,
  },
  {
    Header: 'Corrective Action',
    accessor: 'Corrective Action',
    width: 180,
  },
  {
    Header: 'Actual Interval (MO)',
    accessor: 'Actual Interval (MO)',
    width: 80,
  },
  {
    Header: 'Actual Interval (FH)',
    accessor: 'Actual Interval (FH)',
    width: 80,
  },
  {
    Header: 'Actual Interval (FC)',
    accessor: 'Actual Interval (FC)',
    width: 80,
  },
];

const getRandomNumberFrom = (n: number) => Math.floor(Math.random() * n) + 1;

export const RawTaskReportMockData = (dataCount: number) => {
  const DefaultAcType = 'A320';
  const FindingDesc = [
    'RH ENG - INBD T/R HOLD OPEN ROD SUPPORT BRACKET, CRACKED (P/N NEEDED: ER8703P01)',
    'LH ENG - INBD T/R HOLD OPEN ROD SUPPORT BRACKET, DAMAGED (P/N NEEDED: ER8702P01)',
    'RH ENG - PYLON ACCESS PANELS MULTIPLE FASTENERS - DAMAGED.',
    'LH ENG - FAN COWL LATCHES - PAINT IN POOR CONDITION.',
    'RH ENG - RH FAN COWL DOOR LATCHES - PAINTING IS IN POOR CONDITION.',
  ];
  const CorrAction = [
    'ACTION OPEN/DEFERRED',
    'PAINT RESTORED IAW SRM 51-75-12.REV. NO.17 DATE: 01.NOV.2018; A756',
    'PAINT RESTORED IAW SRM 51-75-12; A174',
    'PAINT RESTORED WITH REF TO SRM 51-75-12.; A158',
    'FASTENERS REPLACED WITH REF TO SRM 51-42-00 AND 20-21-00; /E165',
  ];
  return Array(dataCount)
    .fill(DefaultAcType)
    .map((item, index) => ({
      'A/C Type': item,
      MSN: `N000${70 + index}`,
      'Finding Type':
        FindingDesc[getRandomNumberFrom(0)],
      'Corrective Action':
        CorrAction[getRandomNumberFrom(0)],
      'Actual Interval (MO)': getRandomNumberFrom(0).toString(),
      'Actual Interval (FH)': getRandomNumberFrom(0).toString(),
      'Actual Interval (FC)': getRandomNumberFrom(0).toString(),
    }));
};

export const unscheduledEventsTableCols = [
  { Header: 'A/C Type', accessor: 'A/C Type', width: 60 },
  { Header: 'MSN', accessor: 'MSN', width: 60 },
  { Header: 'Date', accessor: 'Date', width: 60 },
  {
    Header: 'Station from',
    accessor: 'Station from',
    width: 60,
  },
  {
    Header: 'Station to',
    accessor: 'Station to',
    width: 60,
  },
  {
    Header: 'Duration',
    accessor: 'Duration',
    width: 60,
  },
  {
    Header: 'ATA Perf',
    accessor: 'ATA Perf',
    width: 60,
  },
  {
    Header: 'Description',
    accessor: 'Description',
    width: 120,
  },
];

export const unscheduledEventsTable = (dataCount: number) => {
  const DefaultAcType = 'A320';
  const Description = [
    'Performed the maintenance action',
    'Remedial measures and verified',
    'Inspection deficiencies are identified',
  ];
  const Duration = [
    '35 mins',
    '45 mins',
    '55 mins',
    '25 mins',
    '120 mins',
  ];
  return Array(dataCount)
    .fill(DefaultAcType)
    .map((item, index) => ({
      'A/C Type': item,
      MSN: `N000${70 + index}`,
      Date: '23/04/2022',
      'Station from': 'Cell text',
      'Station to': 'Cell text',
      Duration: Duration[getRandomNumberFrom(Description.length - 1)],
      'ATA Perf': '32-41',
      Description: Description[getRandomNumberFrom(Description.length - 1)],
    }));
};

export const noFilterSize = ['Actual Interval (MO)', 'Actual Interval (FH)', 'Actual Interval (FC)'];
