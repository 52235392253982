import React, { Dispatch, SetStateAction } from 'react';
import { Placement } from '@airbus/components-react';
import DataTableHeader from '../Shared/DataTable/DataTableHeader';
import DataTableCell from '../Shared/DataTable/DataTableCell';
import mpdConfigColumns from '../../__mocks__/MpdCustomization/mpdConfigColumns.json';
import MpdTableAppendixCell from './components/MpdTableAppendixCell/MpdTableAppendixCell';
import {
  applicabilityText, applicabilityTextTooltip, filterColumn, filterData, hasApplicabilityWarning, missingSBReport,
  missingSBReportTooltip,
} from './constants';
import MpdWarningIconCell from './components/MpdWarningIconCell/MpdWarningIconCell';
import TooltipComponent from '../Shared/MpdTooltip/mpdTooltip';

/* eslint-disable @typescript-eslint/no-shadow */
export const TableColumnState = {
  Shown: 'shown',
  Hidden: 'hidden',
};

// function to check ce note value for given columns
export const ceNoteValueCheck = (accessor:string, value:string, filterColumn: string[], filterData: string) => {
  return filterColumn.includes(accessor) && value !== null && value.includes(filterData);
};

// function to add warning icon in data table cell.
export const warningIconCellResponse = (warningIconStatus:boolean, value: string, tooltipMessage: string, placement: Placement) => {
  return (warningIconStatus ? <MpdWarningIconCell cellValue={value} tooltipMessage={tooltipMessage} placement={placement} /> : <DataTableCell maxLength={100} maxLines={6} cellValue={value} />);
};

// function to show appendix data
export const appendixColumnResponse = (accessor:string, value:string, filterColumn: string[], filterData: string, mpdTaskNumberValue: string) => {
  return ceNoteValueCheck(accessor, value, filterColumn, filterData) ? <MpdTableAppendixCell mpdTaskNumber={mpdTaskNumberValue} cellValue={value} /> : <DataTableCell maxLength={100} maxLines={6} cellValue={value} />;
};

export const rowCell = (accessor:string, value: string, mpdTaskNumberValue: string, hasApplicabilityValue: boolean) => {
  let response;
  switch (accessor) {
    case filterColumn[0]:
      response = appendixColumnResponse(accessor, value, filterColumn, filterData, mpdTaskNumberValue);
      break;

    case filterColumn[1]:
      response = appendixColumnResponse(accessor, value, filterColumn, filterData, mpdTaskNumberValue);
      break;

    case missingSBReport:
      response = warningIconCellResponse((value !== ''), value, missingSBReportTooltip, 'top');
      break;

    case applicabilityText:
      response = warningIconCellResponse(hasApplicabilityValue, value, applicabilityTextTooltip, 'left');
      break;

    default:
      response = <DataTableCell maxLength={100} maxLines={6} cellValue={value} />;
  }
  return response;
};

const headerTooltipHandler = (column: string) => {
  if (column === 'opportunity') {
    return (<TooltipComponent placement="left" data="Airbus maintenance engineering can support on task escalation." />);
  }
  return undefined;
};

const generateTableColumns: (onClickHandler: Dispatch<SetStateAction<string>>, mpdColumn: emtlColType[]) => mpdTableColsType[] = (onClickHandler, mpdColumn) => (
  mpdColumn.map((header) => ({
    Header: <DataTableHeader tooltip={headerTooltipHandler(header.accessor)} title={header.label} id={header.id} hideMode={false} onClickHandler={onClickHandler} />,
    title: header.label,
    accessor: header.id,
    id: header.id,
    disableSortBy: false,
    width: header.width,
    disableResizing: true,
    Cell: ({ row }: mpdTask) => rowCell(header.accessor, row.values[header.accessor], row.values.mpd_task_reference, row.original[hasApplicabilityWarning]),
  }))
);

const generateConfigTableColumns: () => mpdTableColsType[] = () => (
  mpdConfigColumns.map((header) => ({
    Header: <DataTableHeader title={header.title} />,
    title: header.title,
    accessor: header.accessor,
    id: header.id,
  }))
);

const generateHidableColumns: (columns: mpdTableColsType[]) => { shown: boolean, column: mpdTableColsType }[] = (columns) => (
  columns.map((column) => ({
    shown: true,
    column,
  }))
);

/**
 * A function to return either hidden or shown columns depending on value of columnState
 *
 * @param  columns  Array of columns with shown property
 * @param  columnState State of column Hidden/Shown
 */
const getTableColumns: (columns: hidableMpdTask[], columnState: string) => mpdTableColsType[] = (columns, columnState) => (
  columns.reduce((acc: mpdTableColsType[], column: hidableMpdTask) => {
    if (columnState === TableColumnState.Shown) {
      return column.shown ? [...acc, column.column] : acc;
    }
    return !column.shown ? [...acc, column.column] : acc;
  }, [])
);

const formateTableColumn: (columnList: headerType[]) => emtlColType[] = (columnList) => {
  const finalList: emtlColType[] = [];
  columnList?.forEach((val) => {
    finalList.push({
      label: val.Header,
      value: val.Header,
      id: val.accessor,
      width: val.width,
      accessor: val.accessor,
    });
  });
  return finalList;
};

export {
  generateTableColumns, generateConfigTableColumns, generateHidableColumns, getTableColumns, formateTableColumn,
};
