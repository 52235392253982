import './GenericTable.scss';
import '../../UnscheduledEvents/UnscheduledEvents.scss';
import React, {
  useState, useEffect, Dispatch, SetStateAction,
} from 'react';
import {
  Container, DataTable, Pagination, Row, Col,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@airbus/components-react';
import TableControls from './tableControls/tableControls';
import SMPCTableHiddenCols from '../../MpdTaskTable/components/SMPCTableHiddenCols/SMPCTableHiddenCols';
import TableFilterChips from './TableFilterChips/TableFilterChips';
import { applyFilters } from '../TableSearch/utils';
import { filtersValues } from '../../../models/programAnalysis/programAnalysisTypes';
import apierror from '../../../assets/icons/api-error.svg';

export type tableColsType = { Header: string; accessor: string; width: number; id?: string };
type GenericTableType = {
  tableTitle?: string | React.JSX.Element;
  tableCols: Array<tableColsType>;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  tableData: Array<any>;
  totalDataCount?: number;
  onPaginationChangeCallback?(params: unknown): void;
  Loading?: boolean;
  httpStatus?: number | string;
  isDataDynamic?: boolean;
  updateColumnFilter?(columnName: string): void;
  updateColumnFilterValue?(value: string): void;
  fetchColumnFilterValues?(userInput: string): void;
  updateFilterGroup?(): void;
  deleteFromFilterGroup?(deletePair: searchPair[]): void;
  searchPair?: filtersValues;
  columnFilter?: filtersValues;
  filterGroup?: filtersValues;
  generateTableColumns(onClickHandler: Dispatch<SetStateAction<string>>, tableColumns: Array<tableColsType>): mpdTableColsType[];
  tableColumnState: filtersValues;
  getTableColumns(columns: hidableMpdTask[], columnState: string): mpdTableColsType[];
  /** If true, the filter chips will be displayed above the table instead being attached to the table search component. */
  filterChipPositionDetached?: boolean;
  /** List of columns names, for which a keystroke limit to trigger onChange in search component is set to 0.
   *  i.e. The OnChange for the search value will be triggered on every keystroke for these columns.
   */
  noSearchKeySizeLimitColumns?: Array<string>;
  /** List of column names which should not appear in the Search component. The user will not be able to filter by these columns. */
  noSearchColumns?: Array<string>;
  generateHidableColumns(columns: mpdTableColsType[]): { shown: boolean; column: mpdTableColsType }[];
  cssClass?: string;
  /** If hideCustomizeColumn and hideFilterBy is true. then it will hide filter search option and customize column option */
  hideCustomizeColumn?: boolean;
  hideFilterBy?: boolean;
};
type reRender = {
  pageIndex?: number;
  rowCount?: number;
};

export default function GenericTable(props: GenericTableType) {
  const {
    tableTitle,
    tableCols,
    tableData,
    totalDataCount,
    onPaginationChangeCallback,
    Loading,
    isDataDynamic,
    updateColumnFilter,
    updateColumnFilterValue,
    fetchColumnFilterValues,
    updateFilterGroup,
    deleteFromFilterGroup,
    searchPair,
    columnFilter,
    filterGroup,
    generateTableColumns,
    tableColumnState,
    getTableColumns,
    generateHidableColumns,
    filterChipPositionDetached,
    noSearchKeySizeLimitColumns,
    noSearchColumns,
    cssClass,
    hideCustomizeColumn,
    hideFilterBy,
    httpStatus,
  } = props;
  const [rowsDisplayCount, setRowsDisplayCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [filters, setFilters] = useState<any>({});
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const [tableDataRowFiltered, setTableDataRowFiltered] = useState<Array<any>>(tableData);
  const [hiddenColsShown, setHiddenColsShown] = useState(false);
  const [hiddenColumn, setHiddenColumn] = useState<string>('');
  const [hidableTableColumns, setHidableTableColumns] = useState<Array<hidableMpdTask>>(generateHidableColumns(generateTableColumns(setHiddenColumn, tableCols)));

  const reRenderTable = (params: reRender) => {
    if (onPaginationChangeCallback && tableData && tableData.length > 0) {
      onPaginationChangeCallback({
        pageIndex: (params.pageIndex || currentPage) - 1,
        pageSize: params.rowCount || rowsDisplayCount,
      });
    }
  };

  useEffect(() => {
    setHidableTableColumns((prevState) => {
      const prevHiddenColumns: { [key: string]: boolean } = {};
      prevState.forEach((hideCol) => {
        prevHiddenColumns[hideCol.column.title] = !hideCol.shown;
      });
      return generateHidableColumns(generateTableColumns(setHiddenColumn, tableCols)).map((hideCol) => ({
        ...hideCol,
        shown: !(prevHiddenColumns[hideCol.column.title] === true),
      }));
    });
  }, [tableCols, generateHidableColumns, generateTableColumns]);

  useEffect(() => {
    setHidableTableColumns((prevState) => prevState.map((hideCol) => ({
      ...hideCol,
      shown: hideCol.shown && hideCol.column.id !== hiddenColumn,
    })));
  }, [hiddenColumn]);

  useEffect(() => {
    setCurrentPage(1);
    reRenderTable({ pageIndex: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGroup]);

  useEffect(() => {
    const newArr = applyFilters(tableData, filters);
    setTableDataRowFiltered(newArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, tableData]);

  useEffect(() => {
    setHidableTableColumns((prevState) => prevState.map((hideCol) => {
      const header = React.cloneElement(hideCol.column.Header, { hideMode: hiddenColsShown });
      return {
        column: { ...hideCol.column, Header: header, disableSortBy: hiddenColsShown },
        shown: hideCol.shown,
      };
    }));
  }, [hiddenColsShown]);

  const prepareColOption = () => tableCols
    .filter((item) => !noSearchColumns?.includes(item.Header))
    .map((item) => ({
      label: item.Header,
      value: item.Header,
      id: item.accessor,
    }));

  const clearHiddenCol = (id: string) => {
    setHidableTableColumns(
      [...hidableTableColumns].map((hideCol) => ({
        ...hideCol,
        shown: hideCol.column.id === id ? true : hideCol.shown,
      })),
    );
    setHiddenColumn('');
  };

  const clearAllHiddenCols = () => {
    setHidableTableColumns(
      [...hidableTableColumns].map((hideCol) => ({
        column: hideCol.column,
        shown: true,
      })),
    );
    setHiddenColumn('');
  };

  const paginationComponent = () => (
    <Pagination
      aria-label="genericTable-pagination"
      legend={`Showing ${(currentPage - 1) * rowsDisplayCount + 1}-${Math.min(currentPage * rowsDisplayCount, totalDataCount || 0)} of ${totalDataCount} results`}
      page={currentPage}
      count={totalDataCount || 0}
      rowsPerPage={rowsDisplayCount}
      rowsPerPageOptions={[10, 25, 50]}
      onPageChange={(event, num) => {
        if (totalDataCount) {
          setCurrentPage(num);
          reRenderTable({ pageIndex: num });
        }
      }}
      onRowsPerPageChange={(e, val) => {
        if (totalDataCount) {
          setRowsDisplayCount(Number(val));
          setCurrentPage(1);
          reRenderTable({ rowCount: Number(val), pageIndex: 1 });
        }
      }}
      showGoto
    />
  );

  // After updating the design system to 2.4 remove the below code and use TableEmpty
  const getEmptyTable = (isError: boolean) => (
    <Table className="margin-table">
      <TableHead>
        <TableRow>
          {getTableColumns(hidableTableColumns, tableColumnState.Shown).map(
            (colType: mpdTableColsType) => (
              <TableCell head key={colType.accessor} width={115} className="table-header-empty">{colType.title}</TableCell>
            ),
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <tr className="table-empty-row">
          <td colSpan={8}>
            {isError
              ? (
                <div className="table-empty table-error">
                  <img src={apierror} alt="api-error" width="50" height="60" className="table-error" />
                  <p className="api-fetching-error">
                    Error occured. Please try after sometime.
                  </p>
                </div>
              ) : (
                <div className="table-empty table-no-data">
                  <p>No Data</p>
                </div>
              )}
          </td>
        </tr>
      </TableBody>
    </Table>
  );

  const renderDynamicTable = () => (
    <div>
      {(Loading || tableData.length > 0)
        ? (<DataTable className="margin-table" height={450} columns={getTableColumns(hidableTableColumns, tableColumnState.Shown)} data={tableData} defaultRowsPerPage={50} loading={Loading} />)
        : getEmptyTable(httpStatus !== 200)}
      {paginationComponent()}
    </div>
  );

  const renderStaticTable = () => (
    <div>
      <DataTable height={500} columns={getTableColumns(hidableTableColumns, tableColumnState.Shown)} data={tableDataRowFiltered} defaultRowsPerPage={10} pageCount={tableDataRowFiltered.length / rowsDisplayCount} rowCount={tableDataRowFiltered.length} />
      <br />
    </div>
  );

  const renderTableControls = () => (
    <TableControls
      tableTitle={tableTitle}
      filters={filters}
      setFilters={setFilters}
      data={tableDataRowFiltered}
      searchColumns={prepareColOption()}
      isDataDynamic={isDataDynamic}
      showHiddenCols={setHiddenColsShown}
      updateColumnFilter={updateColumnFilter}
      updateColumnFilterValue={updateColumnFilterValue}
      fetchColumnFilterValues={fetchColumnFilterValues}
      updateFilterGroup={updateFilterGroup}
      deleteFromFilterGroup={deleteFromFilterGroup}
      searchPair={searchPair}
      columnFilter={columnFilter}
      filterGroup={filterGroup}
      hideFilterChips={filterChipPositionDetached}
      noSearchKeySizeLimitColumns={noSearchKeySizeLimitColumns}
      hideCustomizeColumn={hideCustomizeColumn}
      hideFilterBy={hideFilterBy}
    />
  );

  const renderHiddenColsSection = () => <SMPCTableHiddenCols showHiddenCols={setHiddenColsShown} hiddenCols={getTableColumns(hidableTableColumns, tableColumnState.Hidden)} clearAll={clearAllHiddenCols} clearHiddenCol={clearHiddenCol} hiddenColsShown={hiddenColsShown} />;

  const renderFilterChipsSection = () => <TableFilterChips columnsOptions={prepareColOption()} deleteFromFilterGroup={deleteFromFilterGroup} filterGroup={filterGroup} />;

  return (
    <Container className={`${cssClass} custom-container`}>
      <>
        <Row alignItems="end" className="table-control-cls">
          <Col xxs={12}>{!hiddenColsShown && renderTableControls()}</Col>
        </Row>
        <Row alignItems="center" className="col-section-cls">
          <Col xxs={12}>{(hiddenColsShown || getTableColumns(hidableTableColumns, tableColumnState.Hidden).length > 0) && renderHiddenColsSection()}</Col>
        </Row>
        {filterChipPositionDetached && filterGroup?.length > 0 && renderFilterChipsSection()}
      </>
      {typeof onPaginationChangeCallback === 'undefined' ? renderStaticTable() : renderDynamicTable()}
    </Container>
  );
}

GenericTable.defaultProps = {
  onPaginationChangeCallback: undefined,
  Loading: false,
  totalDataCount: 0,
  isDataDynamic: false,
  updateColumnFilter: undefined,
  updateColumnFilterValue: undefined,
  fetchColumnFilterValues: undefined,
  updateFilterGroup: undefined,
  deleteFromFilterGroup: undefined,
  searchPair: undefined,
  columnFilter: undefined,
  filterGroup: undefined,
  tableTitle: undefined,
  filterChipPositionDetached: false,
  noSearchKeySizeLimitColumns: [],
  noSearchColumns: [],
  cssClass: 'generic-table-cls',
  hideCustomizeColumn: false,
  hideFilterBy: false,
  httpStatus: 200,
};
