import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Banner, Link } from '@airbus/components-react';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { RootState } from '../../../store/store';
import './BannerComponent.scss';
import { customBannerIconMap } from '../../../models/bannerModel/constants';
import { clearBannerLocation, removeBanner } from '../../../models/bannerModel/bannerSlice';
import { setFilterPanelOpen } from '../../../models/mpdRepositoryModel/mpdRepositorySlice';
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable  no-unused-expressions */
/**
 * This is a generic banner component that acts as a location for banners to be shown.
 *
 * **USAGE:** To add a banner to a location, information about that banner needs to be added in `bannerModel\constants.ts` file.
 *
 * **EXAMPLE:** If `<BannerComponent location="XYZ"/>` is placed in some component, then it acts as a location where we can add banners.
 * Now, to add a banner to this location, we will add information about that banner in the `bannerModel\constants.ts` file,
 * and also make sure its `bannerLocation` is `'XYZ'`. Let's call this banner information as `BANNER_INFO`.
 *
 * Now when we trigger this banner by using `dispatch(addBanner(BANNER_INFO))`, then it will be shown in that `XYZ` location.
 *
 * It also supports custom icon and link which can be configured in the `bannerModel\constants.ts` file.
*/
const BannerComponent = (props: { location: string }) => {
  const { location } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { banners } = useAppSelector((state: RootState) => state.banner);
  const bannerList = banners.filter(({ bannerLocation }) => bannerLocation === location);

  // Remove banners when component unmounts
  useEffect(() => {
    return () => {
      dispatch(clearBannerLocation(location));
    };
  }, [dispatch, location]);

  return (
    <div className="banner-list">
      {bannerList.map((banner) => {
        const Icon = (banner.customBannerIcon ? customBannerIconMap[banner.customBannerIcon] : null);
        return (
          <Banner className="banner-cls banner-customisation" data-testid="banner-test" variant={banner.bannerVariantType} onClose={() => dispatch(removeBanner(banner))}>
            {Icon && <Icon className="custom-banner-icon" role="img" />}
            <span className="banner-message">
              {banner.bannerMessage}
            </span>
            <span>
              {
                banner.link
                && (
                <Link
                  className="banner-link"
                  onClick={() => {
                    banner.link && navigate(`${banner.link.address}`);
                    dispatch(setFilterPanelOpen(false));
                    dispatch(clearBannerLocation(location));
                  }}
                  size="small"
                  variant="underlined"
                >
                  {banner.link.text}
                </Link>
                )
              }
            </span>
          </Banner>
        );
      })}
    </div>
  );
};

export default BannerComponent;
