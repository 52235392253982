import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { usePostHog } from 'posthog-js/react';
import { useDispatch } from 'react-redux';
import AppHeader from '../../components/Shared/AppHeader/AppHeader';
import { LOCALES } from '../../assets/locale';
import SignIn from '../../components/SignIn/SignIn';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import SignOut from '../../components/SignOut/SignOut';
import { routes, RoutesConfig } from './RouteMapping';
import { appContext, userContext } from '../../utils/context/userContext';
import { Methods } from '../../utils/customHooks/apiTypes';
import { useFetch } from '../../utils/customHooks/useFetch';
import BannerComponent from '../../components/Shared/BannerComponent/BannerComponent';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';
import { bannerLocationMap } from '../../models/bannerModel/constants';
import { createFeatureToggle } from '../../models/FeatureToggleModel/FeatureToggleAsyncThunk';
import ToastComponent from '../../components/Shared/Toast/ToastComponent';
import MpdHomepageDisclaimerDialog from '../../components/mpdHomepageDisclaimerDialog/mpdHomepageDisclaimerDialog';
import { useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';
import { setUserPermissions } from '../../models/userPermissionsModel/userPermissionSlice';

const App = () => {
  const API_METHOD_GET: Methods = 'get';
  const PATH = 'api/user';
  const [response] = useFetch(PATH, API_METHOD_GET);
  const [userType, setUserType] = useState(userContext.userDetails);
  const [mailid, setMailid] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isLoading, setisLoading] = useState(true);
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const [disclaimerData, setDisclaimerData] = useState('');
  const dispatch = useDispatch();
  const {
    version: disclaimerVersion,
  } = useAppSelector((state: RootState) => state.featureToggle.disclaimer);
  const posthog = usePostHog();

  const handleDisclaimerSubmit = () => {
    secureLS.set('disclaimerVersion', disclaimerVersion);
    setDisclaimerOpen(false);
  };

  const routerMap = (routeList: RoutesConfig[]): JSX.Element[] => {
    return routeList.map((routeItem) => {
      if (routeItem.childRoute) {
        return (
          <Route path={routeItem.path} element={routeItem.element} key={routeItem.path}>
            {routerMap(routeItem.childRoute)}
          </Route>
        );
      }
      return routeItem.index ? (<Route element={routeItem.element} key={routeItem.path} index />) : (<Route path={routeItem.path} element={routeItem.element} key={routeItem.path} />);
    });
  };

  const setUser = () => {
    setUserType({
      userType: response?.authorizations[0].company.icao === 'AIB' ? 'internal' : 'external',
      icao: response?.authorizations[0].company.icao === 'AIB' ? '' : response?.authorizations[0].company.icao,
      userAccess: response?.authorizations[0].permissions[0].code ?? '',
      email: mailid,
      firstname: firstName,
      lastname: lastName,
      loader: !(response),
    });

    dispatch(setUserPermissions({
      permissions: response?.authorizations[0].permissions,
      featurePermissions: response?.authorizations[0].permissions_with_features,
    }));
  };

  // call the posthog capture method to log the weather user is internal/external and icoa
  useEffect(() => {
    if (userType) {
      posthog.register({
        icao: userType.icao !== '' ? userType.icao : 'AIB',
        role: userType.userType,
      });
    }
  }, [userType, posthog]);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((amplifyUser) => {
      setMailid(amplifyUser.attributes.email);
      setFirstName(amplifyUser.attributes.given_name);
      setLastName(amplifyUser.attributes.preferred_username || amplifyUser.attributes.family_name);
      const currentUser = secureLS.get('userId');
      if (currentUser !== amplifyUser.username) {
        secureLS.remove('operator');
        secureLS.remove('ac_program');
        secureLS.remove('architecture');
        secureLS.set('userId', amplifyUser.username);
      }
    }).catch(() => { });
    setUser();

    // Show Loader until Feature Toggle API returns
    if (response) {
      // Get operator from response
      const operator = response?.authorizations[0].company.icao === 'AIB' ? '' : response?.authorizations[0].company.icao;
      dispatch(createFeatureToggle(operator)())
        .unwrap().then((data: featureList) => {
          const disclaimerVersionLS = secureLS.get('disclaimerVersion');
          if (disclaimerVersionLS === '' || disclaimerVersionLS < data.disclaimer.version) {
            setDisclaimerData(data.disclaimer.description);
            setDisclaimerOpen(true);
          }
        }).finally(() => {
          setisLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, dispatch]);

  return (
    <div>
      <appContext.Provider value={userType}>
        <AppHeader appName={LOCALES.APP_NAME} />
        <BannerComponent location={bannerLocationMap.GLOBAL} />
        <ToastComponent />
        <MpdHomepageDisclaimerDialog open={disclaimerOpen} onClose={handleDisclaimerSubmit} data={disclaimerData} />
        <Routes>
          <Route element={<PrivateRoute isLoading={isLoading} />}>{routerMap(routes)}</Route>
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/signout" element={<SignOut />} />
        </Routes>
      </appContext.Provider>
    </div>
  );
};

export default App;
