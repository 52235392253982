import { checkFeaturePermission } from '../userPermissionUtils/userPermissionUtils';

/**
 * Returns true if the user have access to the page/component
 * @param requiredPermissions string[] - List of required permissions.
 * @returns boolean - True if allowed and false otherwise.
 */
const useUserPermission = (requiredPermissions: string[]) => {
  return checkFeaturePermission(requiredPermissions);
};

export { useUserPermission };
