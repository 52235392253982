/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { Inline, Placement, Tooltip } from '@airbus/components-react';
import { Warning } from '@airbus/icons/react';
import '../../../Shared/DataTable/DataTableCell.scss';
import './MpdWarningIconCell.scss';
import DataTableCell from '../../../Shared/DataTable/DataTableCell';
import { featureSwitchConfig } from '../../../../utils/FeatureToggleUtil/FeatureToggleUtil';
import { appContext } from '../../../../utils/context/userContext';

interface MpdWarningIconCellProps {
  cellValue: string;
  tooltipMessage: string;
  placement: Placement;
}

/**
 * Component to represent a Warning icon cell in the DataTable component.
 * @param {string} cellValue Value to be displayed in cell
 * @param {string} tooltipMessage Message on tooltip hover
 * @param {Placement} placement Position of on tooltip
*/

const MpdWarningIconCell: React.FC<MpdWarningIconCellProps> = function MpdWarningIconCell(props: MpdWarningIconCellProps) {
  const { cellValue, tooltipMessage, placement } = props;
  const { userType } = useContext(appContext);
  const response = featureSwitchConfig(
    { name: 'MpdWarningIcon', userType },
    <Inline className="warning-icon-data-table-cell-cls warning-icon-mpd-table-cell-cls">
      <Tooltip large placement={placement} className="warning-icon-tooltip" label={tooltipMessage}>
        <Warning
          className="data-table-cell-warning-icon"
          aria-label="data-table-warning-icon"
        />
      </Tooltip>
      <DataTableCell maxLength={100} maxLines={6} cellValue={cellValue} />
    </Inline>,
    <DataTableCell maxLength={100} maxLines={6} cellValue={cellValue} />,
  );
  return response;
};

export default MpdWarningIconCell;
