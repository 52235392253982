import React from 'react';
import { Button, Col, Row } from '@airbus/components-react';
import { Check } from '@airbus/icons/react';
import './MpdRepositoryCard.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState, store } from '../../store/store';
import {
  addSelectedFile, resetPreviousRevisionBanner, setPrevRevExportInProgress, updateEmtlPageNumber, updateSelectedFile,
} from '../../models/mpdRepositoryModel/mpdRepositorySlice';
import MpdRepositoryCardDelete from '../MpdRepository/ProgCustomization/MpdRepositoryCardDelete/MpdRepositoryCardDelete';
import { getFilteredItems } from '../MpdTaskTable/mpdTableUtils';
import MpdRepositoryDownloadMenu from '../MpdRepositoryDownloadMenu/MpdRepositoryDownloadMenu';
import {
  createPreviousRevisionExportTask, createRepositoryTaskList,
} from '../../models/mpdRepositoryModel/mpdRepositoryAsyncThunk';
import { startPolling } from '../../utils/APIPollingUtil/APIPolling';
import { addBanner, clearBannerLocation } from '../../models/bannerModel/bannerSlice';
import {
  bannerLocationMap, EXPORT_FAILED, EXPORT_IN_PROGRESS, EXPORT_SUCCESS,
} from '../../models/bannerModel/constants';
import { PollRequest, pollResponse } from '../../utils/APIPollingUtil/APIPollingTypes';
import { EMTL_TABLE_EXPORT_STATUS_POLLING_API_PATH } from '../../utils/APIPollingUtil/constants';
import { updateEmtlExportButtonState } from '../../models/mpdEmtlTableModel/mpdEmtlTableSlice';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';
import { findRevision } from '../../models/mpdRepositoryModel/util';
import { formatRepositoryDate } from '../MpdRepositoryAnalysisCard/MpdRepositoryUtil';

type mpdRepoListProps = {
  fileName: string;
  createAt: string;
  fileKey: string;
};

const MpdRepositoryCard = (props: mpdRepoListProps) => {
  const {
    fileName, createAt, fileKey,
  } = props;
  const dispatch = useAppDispatch();
  const {
    RepositorySelectedFilesList: selectedFiles,
    RepositorySelectedFileKeys: selectedKeys,
    RepositoryButtonDisabled: btnsDisabled,
    RepositoryTaskListRowsPerPage: repoEmtlRowsPerPage,
    RepositoryFilterGroup: filterGroup,
    RepositoryPreviousRevision,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  const { isLatest, isRevisionExist, prevRevExportInProgress } = RepositoryPreviousRevision;
  const acProgram = secureLS.get('ac_program');
  const excelType = ['.xlsx'];

  const enableRepository = () => {
    dispatch(updateEmtlExportButtonState(false));
    dispatch(setPrevRevExportInProgress(false));
  };

  const clearBanners = () => {
    dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
    dispatch(clearBannerLocation(bannerLocationMap.REPOSITORY_MODULE));
  };

  const prevRevExportCleanup = () => {
    clearBanners();
    enableRepository();
  };

  const prevRevExportPollingPayload = (fileId: string) => {
    const response: PollRequest = {
      data: {
        url: `${EMTL_TABLE_EXPORT_STATUS_POLLING_API_PATH}?export-task-id=${fileId}`,
        interval: 5500,
        retriesAllowed: 5,
        successStatus: ['DONE'],
        failureStatus: ['FAIL', 'DELETE'],
      },
      onSuccess: (resp: pollResponse) => {
        prevRevExportCleanup();
        dispatch(resetPreviousRevisionBanner());
        dispatch(addBanner(EXPORT_SUCCESS));
        dispatch(addSelectedFile({ fileName: [resp.data.fileName], fileKey: [resp.data.taskId] }));

        const currentTab = store.getState().repositoryTaskList.RepositoryEMTLTabType;
        /* istanbul ignore next */
        if (window.location.pathname === '/repository/emtl' && currentTab === 'EMTL') {
          dispatch(updateEmtlPageNumber(1));
          dispatch(createRepositoryTaskList(repoEmtlRowsPerPage, 1, acProgram, filterGroup, currentTab)());
        }
      },
      onFailure: (resp: pollResponse | null, error?: unknown) => {
        prevRevExportCleanup();
        if (resp) {
          const bannerMessage = EXPORT_FAILED.bannerMessage.replace('`<FILENAME>`', `"${resp.data.fileName}"`);
          dispatch(addBanner({ ...EXPORT_FAILED, bannerMessage }));
        } else {
          dispatch(resetPreviousRevisionBanner());
          dispatch(addBanner({
            ...EXPORT_FAILED,
            bannerMessage: `${JSON.stringify(error)}: please check the repository in sometime.`,
          }));
        }
      },
      onTimeout: () => {
        prevRevExportCleanup();
        dispatch(addBanner({
          ...EXPORT_FAILED,
          bannerMessage: 'Export is taking longer than expected, please check the repository in sometime.',
        }));
      },
    };
    return response;
  };

  const prevRevExportHandler = async () => {
    try {
      clearBanners();
      dispatch(addBanner(EXPORT_IN_PROGRESS));
      dispatch(setPrevRevExportInProgress(true));
      const response = await dispatch(createPreviousRevisionExportTask('EMTL')()).unwrap();
      dispatch(startPolling(prevRevExportPollingPayload(response.data.taskId)));
    } catch (error) {
      clearBanners();
      enableRepository();
      dispatch(addBanner({
        ...EXPORT_FAILED,
        bannerMessage: EXPORT_FAILED.bannerMessage.replace('`<FILENAME>` ', ''),
      }));
    }
  };

  const compareBtnHandler = () => {
    if (selectedFiles.length < 2 && !(selectedFiles.includes(fileName))) {
      dispatch(addSelectedFile({ fileName: [fileName], fileKey: [fileKey] }));
    } else {
      const removeSelectedFiles = getFilteredItems(selectedFiles, fileName);
      const removeSelectedKeys = getFilteredItems(selectedKeys, fileKey);
      dispatch(updateSelectedFile({ fileName: removeSelectedFiles, fileKey: removeSelectedKeys }));
    }
  };

  const compareBtnDisabled = prevRevExportInProgress || (selectedFiles.length >= 2 && !(selectedFiles.includes(fileName)))
    || btnsDisabled === fileName || (selectedFiles.length > 0 && Math.abs(findRevision(selectedFiles[0]) - findRevision(fileName)) > 1);

  const showPrevRevBanner = !prevRevExportInProgress && selectedFiles.includes(fileName)
    && selectedFiles.length === 1 && isLatest && !isRevisionExist;

  return (
    <div className={showPrevRevBanner ? 'mpd-repository-card mpd-repository-card-pre-rev' : 'mpd-repository-card'} aria-label="mpdRepositoryCard">
      <Row>
        <Col className="card-detail-cls">
          <p><b>{fileName}</b></p>
          <p className="timestamp-cls">
            {`Exported on ${formatRepositoryDate(createAt)}`}
          </p>
        </Col>
        <Col className="card-action-cls">
          <Button
            aria-label="mpdSelectionTask"
            variant={selectedFiles.includes(fileName) ? 'success' : 'primary'}
            className="action-btn-cls"
            onClick={compareBtnHandler}
            disabled={compareBtnDisabled}
          >
            {selectedFiles.includes(fileName) ? 'Selected' : 'Compare'}
          </Button>
          <MpdRepositoryDownloadMenu
            fileTypes={excelType}
            fileKey={fileKey}
            fileName={fileName}
          />
          <MpdRepositoryCardDelete fileName={fileName} fileKey={fileKey} comparedFileName={fileName} />
        </Col>
      </Row>
      {showPrevRevBanner && (
        <div>
          <Row className="previous-revision-bar">
            <div className="previous-revision-text">Do you want to export EMTL based on previous revision?</div>
            <Button
              aria-label="previous-revision-button"
              variant="ghost"
              className="previous-revision-button"
              onClick={prevRevExportHandler}
            >
              <Check />
              Yes, continue
            </Button>
          </Row>
        </div>
      )}
    </div>
  );
};

export default MpdRepositoryCard;
