/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ComboBox } from '@airbus/components-react';
import { secureLS } from '../../../utils/localStorageUtil/lsHelper';

interface dataProps {
  data: any;
  onSelectingDrp: (value: string) => void;
  value: string;
  placeholder: string;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  forceUpdate: (obj: object) => void;
  // eslint-disable-next-line react/require-default-props
  lsName?: any;
}

const Dropdown: React.FC<dataProps> = function Dropdown(props: dataProps) {
  const {
    data, onSelectingDrp, value, placeholder, disabled, forceUpdate, lsName,
  } = props;
  // eslint-disable-next-line, @typescript-eslint/no-shadow
  const dropdownChange = (event: any) => {
    onSelectingDrp(event);
    // eslint-disable-next-line no-unused-expressions
    event !== '' && secureLS.set(lsName, event);
    forceUpdate({});
  };

  return (
    <ComboBox
      onChange={dropdownChange}
      placeholder={placeholder}
      options={data}
      value={value}
      aria-label={placeholder}
      disabled={disabled}
    />
  );
};

export default Dropdown;
