// Slice
export const BELL_NOTIFICATION = 'BellNotification';

// Async thunk actions
export const CREATE_NOTIFICATION_DATA = 'createNotificationData';
export const UPDATE_NOTIFICATIONS_AS_READ = 'updateNotificationsAsRead';

// Path of API to get Notification Data
export const GET_NOTIFICATION_API_PATH = 'api/notifications';
// Path of API to update NotificationIds
export const PUT_NOTIFICATION_API_PATH = 'api/user_notifications';
