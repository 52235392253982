/* eslint-disable no-await-in-loop */

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Methods } from '../customHooks/apiTypes';
import createCustomAsyncThunk from '../thunkUtils/createCustomAsyncThunk';
import { RootState } from '../../store/store';
import { PollRequest, pollResponse } from './APIPollingTypes';

/**
 * This thunk is used to poll API globally. To use, dispatch the `startPolling` thunk along with the parameter of type `PollRequest`.
 *
 * The `response` must contain `data` object with a `status` field.
 *
 * **Limitation:** Since it is an async thunk, hooks can't be used directly.
*/
export const startPolling = (request: PollRequest): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
  const {
    data,
    onSuccess,
    onFailure,
    onTimeout,
  } = request;

  let retriesLeft = data.retriesAllowed ?? 5;

  const customAction = {
    sliceName: 'apiPolling',
    actionType: 'APIPolling',
  };

  const apiRequestObj = {
    path: data.url,
    method: 'get' as Methods,
  };

  let isSuccess = false;
  let isFailed = false;
  while (retriesLeft) {
    retriesLeft -= 1;

    await new Promise((resolve) => {
      setTimeout(resolve, data.interval);
    });

    try {
      const response: pollResponse = await dispatch(createCustomAsyncThunk(customAction, apiRequestObj)('')).unwrap();
      isSuccess = data.successStatus.some((status) => status === response.data.status);
      isFailed = data.failureStatus.some((status) => status === response.data.status);
      if (isSuccess) {
        onSuccess(response);
        break;
      } else if (isFailed) {
        onFailure(response);
        break;
      } else if (!retriesLeft && onTimeout) {
        onTimeout(response);
      }
    } catch (error) {
      onFailure(null, error);
      break;
    }
  }
};
