import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  clearBuild, clearFilters, clearPrevBuildId, clearTasks,
} from '../../models/programAnalysis/programAnalysisSlice';
import { useAppDispatch } from '../../store/hooksTypes';

const ProgramAnalysis = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearTasks());
      dispatch(clearFilters());
      dispatch(clearBuild());
      dispatch(clearPrevBuildId());
    };
  }, [dispatch]);

  return <Outlet />;
};

export default ProgramAnalysis;
