export const addBannerReducer = (state: BannerList, action: BannerAction) => ({
  ...state,
  banners: [...state.banners, action.payload],
});

export const removeBannerReducer = (state: BannerList, action: BannerAction) => ({
  ...state,
  banners: state.banners.filter(
    (banner) => banner.bannerMessage !== action.payload.bannerMessage,
  ),
});

export const clearBannerLocationReducer = (state: BannerList, action: BannerLocation) => ({
  ...state,
  banners: state.banners.filter(
    (banner) => banner.bannerLocation !== action.payload,
  ),
});
