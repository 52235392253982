export const createToastReducer = (state: toastState, action: toastAction) => ({
  ...state,
  status: true,
  toastList: [{ status: true, toast: action.payload }, ...state.toastList],
});

export const clearToastReducer = (state: toastState, action: { payload: string }) => ({
  ...state,
  toastList: state.toastList.filter((item) => item.toast.id !== action.payload),
});

export const clearAllToastReducer = (state: toastState) => ({
  ...state,
  status: false,
  toastList: [],
});

export const setToastStatusReducer = (state: toastState, action: toastStatusSetAction) => ({
  ...state,
  status: true,
  toastList: state.toastList.map((item) => (item.toast.id === action.payload.id ? {
    ...item,
    status: action.payload.status,
  } : item)),
});
