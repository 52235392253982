/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PRECOMPUTED_API_PATH, UE_PRECOMP_OI_DATA_FILTER, UNSCHEDULED_EVENT_PRECOMP_SLICE, UNSCHEDULED_FINDING_RATE_SLICE,
} from './constants';
import { Methods } from '../../utils/customHooks/apiTypes';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';

export const fetchOiHeaderCardData: any = (filterId: string, primaryScope: string, secondaryScope: string, paramsName: string, paramsValue: number | string | undefined, isTada: boolean) => {
  const customAction = {
    sliceName: UNSCHEDULED_EVENT_PRECOMP_SLICE,
    actionType: UE_PRECOMP_OI_DATA_FILTER,
  };
  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=${primaryScope}&secondary_scope=${secondaryScope}&${paramsName}=${paramsValue}&isTada=${isTada}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchFleetwideGraphData: any = (filterId: string, paramsName: string, paramsValue: number | string | undefined, isTada: boolean) => {
  const customAction = {
    sliceName: UNSCHEDULED_EVENT_PRECOMP_SLICE,
    actionType: 'fetchFleetwideGraphData',
  };
  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=unscheduled_events&secondary_scope=oi_graph&${paramsName}=${paramsValue}&isTada=${isTada}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTableData: any = (filterId: string, paramsName: string, paramsValue: number | string | undefined, isTada: boolean, pageNumber = 1, pageSize = 10, filterGroups: { columnName: string, columnInput: string }[] = []) => {
  const customAction = {
    sliceName: UNSCHEDULED_EVENT_PRECOMP_SLICE,
    actionType: 'fetchTableData',
  };

  // NM-TO-BE-CHANGED: Remove below comment after table filter API is implemented
  /* istanbul ignore next */
  const filters = filterGroups.map((filterGroup) => `&${filterGroup.columnName}=${filterGroup.columnInput}`).join('');
  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=unscheduled_events&secondary_scope=table&${paramsName}=${paramsValue}&page_number=${pageNumber}&page_size=${pageSize}${filters}&isTada=${isTada}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchUnscheduleFindingRateData: any = (filterId: string, ata2d: number, taskNumber: string, isTada: boolean) => {
  const customAction = {
    sliceName: UNSCHEDULED_FINDING_RATE_SLICE,
    actionType: 'fetchFindingRateGraphData',
  };

  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=unscheduled_events&secondary_scope=oi_finding&ata_2d=${ata2d}&task_number=${taskNumber}&isTada=${isTada}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchTableFilterData: any = (filterId: string, paramsName: string, paramsValue: number | string | undefined, columnName: string, columnValue: string, isTada: boolean, filterGroups: { columnName: string, columnInput: string }[] = []) => {
  const customAction = {
    sliceName: UNSCHEDULED_EVENT_PRECOMP_SLICE,
    actionType: 'fetchTableFilterData',
  };

  /* istanbul ignore next */
  const filters = filterGroups.map((filterGroup) => `&${filterGroup.columnName}=${filterGroup.columnInput}`).join('');
  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=unscheduled_events&secondary_scope=table_filter&${paramsName}=${paramsValue}&column_name=${columnName}&column_value=${columnValue}${filters}&isTada=${isTada}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};

export const fetchRSIGraphData: any = (filterId: string, paramsName: string, paramsValue: number | string | undefined, isTada: boolean) => {
  const customAction = {
    sliceName: UNSCHEDULED_EVENT_PRECOMP_SLICE,
    actionType: 'fetchRSIGraphData',
  };

  /* istanbul ignore next */
  const apiRequestObj = {
    path: `${PRECOMPUTED_API_PATH}/${filterId}/data?primary_scope=unscheduled_events&secondary_scope=rsi_graph&${paramsName}=${paramsValue}&isTada=${isTada}`,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
