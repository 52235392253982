/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FETCH_API_PATH_MPD_GEN_INFO,
  CREATE_MPD_GEN_INFO,
  FETCH_MPD_GEN_INFO,
} from './constants';
import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { Methods } from '../../utils/customHooks/apiTypes';

export const createMpdGeneralInfo: any = (moduleName:string) => {
  const customAction = {
    sliceName: FETCH_MPD_GEN_INFO,
    actionType: CREATE_MPD_GEN_INFO,
  };
  const apiRequestObj = {
    path: `${FETCH_API_PATH_MPD_GEN_INFO}?module_name=${moduleName}`,
    method: 'get' as Methods,
  };
  return createCustomAsyncThunk(customAction, apiRequestObj);
};
