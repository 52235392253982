/* eslint-disable @typescript-eslint/no-explicit-any */

export enum PollingIds {
  EmtlFileExport,
  EmtlDeltaExport
}

export type PollRequest = {
  id: PollingIds,
  payload: {
    [key: string]: string,
  },
}

export type PollItem = {
  request: {
    url: string,
    interval: number,
    responseColumnName: string,
    successStatus: string[],
    failureStatus: string[],
  },
  onSuccess(): void,
  onFailure(retriesLeft: number): void,
}

export type PollState = {
  pollRequest: PollRequest,
  pollResponse: any
}
export type PollAction = {
  payload: PollRequest;
}
