import { createSlice } from '@reduxjs/toolkit';
import { setUserPermissionsReducer } from './userPermissionReducer';

export const initialState: userPermissionState = {
  sasPermissions: [],
  featurePermissions: [],
};

const userPermissionSlice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {
    setUserPermissions: setUserPermissionsReducer,
  },
});

export const { setUserPermissions } = userPermissionSlice.actions;

export default userPermissionSlice;
