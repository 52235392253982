import './Crown.scss';
import React from 'react';

function Crown() {
  return (
    <div className="crown-svg-cls">
      <svg
        className="crown-svg"
        width="2em"
        height="2em"
        viewBox="-3 -3 24 24"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: '2rem !important' }}
      >
        <path
          d="M4 0 L7 20 L25 20 Z M18 0 L7 20 L27 20 Z M31 0 L7 20 L27 20 Z"
          fill="#f2994a"
        />

      </svg>
    </div>
  );
}

export default Crown;
