import { Methods } from '../../utils/customHooks/apiTypes';

// API
export const TASK_REPORT_API_PATH = 'api/bcm/program-analysis/task-report-analysis';
export const TASK_REPORT_API_POST_METHOD = 'post' as Methods;
export const TASK_REPORT_PRECOMPUTE_API_PATH = 'api/precompute/scopes';
export const TASK_REPORT_API_GET_METHOD = 'get' as Methods;
// operatorcard path
export const TASK_REPORT_OPERATORCARD_API_PATH = 'data/operator-key-params?task_number';
export const TASK_REPORT_WORLDWIDEFLEETCARD_API_PATH = 'data/fleet-key-params?task_number';
// Slice
export const TASKS_SLICE = 'task';

// Async thunk actions
export const FETCH_TASK_INFO = 'fetchTaskInfo';
export const FETCH_TASK_INFO_TABLE_NAME = 'smac_mpd_task_metadata';
export const FETCH_TASK_INFO_COLUMNS = [
  'mpd_task_reference',
  'source_document_reference',
  'source_document_type',
  'mpd_task_description',
  'mpd_task_code',
  'mpd_100_threshold',
  'mpd_100_interval',
  'applicability_text',
  'is_calculable',
];

// precomp
export const FETCH_TASK_INFO_FLEET_LEVEL_GEO_REGION = 'fetchTaskInfoFleetLevelGeoRegion';
export const FETCH_TASK_INFO_FLEET_LEVEL_GEO_REGION_TADA = 'fetchTaskInfoFleetLevelGeoRegionforTada';
export const FETCH_TASK_INFO_FLEET_LEVEL_FINDING_CHART = 'fetchTaskInfoFleetLevelFindingChart';
export const FETCH_TASK_INFO_FLEET_LEVEL_FINDING_CHART_TADA = 'fetchTaskInfoFleetLevelFindingChartforTada';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_REPORT_RECEIVED_YEARLY = 'fetchTaskInfoOperatorLevelReportReceivedYearly';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_CHART = 'fetchTaskInfoOperatorLevelFindingChart';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_CHART_TADA = 'fetchTaskInfoOperatorLevelFindingChartforTada';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_RATE_BY_MSN = 'fetchTaskInfoOperatorLevelFindingRateByMsn';
export const FETCH_TASK_INFO_OPERATOR_LEVEL_FINDING_RATE_BY_MSN_TADA = 'fetchTaskInfoOperatorLevelFindingRateByMsnforTada';
export const FETCH_TASK_REPORT_OPERATOR_CARD = 'fetchTaskReportOperatorCard';
export const FETCH_TASK_REPORT_OPERATOR_CARD_TADA = 'fetchTaskReportOperatorCardforTada';

export const FETCH_TASK_REPORT_WORLD_WIDE_FLEET_CARD = 'fetchTaskReportWorldWideFleetCard';
export const FETCH_TASK_REPORT_WORLD_WIDE_FLEET_CARD_TADA = 'fetchTaskReportWorldWideFleetCardforTada';

export const FETCH_RAW_TASK_REPORTS = 'fetchRawTaskReports';
export const FETCH_RAW_TASK_REPORTS_COLUMN_SEARCH = 'fetchRawTaskReportsColumnSearch';
export const FETCH_PRECOMPUTE_RAW_TASK_REPORTS = 'fetchPrecomputeRawTaskReports';
export const FETCH_PRECOMPUTE_RAW_TASK_REPORTS_COLUMN_SEARCH = 'fetchPrecomputeRawTaskReportsColumnSearch';
export const FETCH_RAW_TASK_REPORTS_TABLE_NAME = 'smac_post_task_assessment_raw_task_report';
export const FETCH_RAW_TASK_REPORTS_COLUMNS = [
  'actual_interval_months',
  'actual_interval_flight_cycles',
  'actual_interval_flight_hours',
  'finding_type_description',
  'id_aircraft',
  'ac_series',
  'corrective_action',
];

export const FETCH_PRECOMP_TASK_REPORT_BY_ACTUAL_INTERVAL = 'fetchPrecompTaskReportByActualInterval';
export const FETCH_PRECOMP_TASK_REPORT_BY_ACTUAL_INTERVAL_TADA = 'fetchPrecompTaskReportByActualIntervalforTada';
// FR-Finding Rate , FC -Finding Chart
export const DEFAULT_OPERATOR_FR_BY_MSN_STATE = {
  loading: false,
  error: '',
  httpStatus: '',
  status: 'idle',
  id_aircraft: [],
  all_finding_per_id_aircraft_count: [],
  nil_finding_per_id_aircraft_count: [],
  finding_rate_per_id_aircraft_count: [],
};
export const DEFAULT_FR_GEO_REGION_STATE = {
  loading: false,
  error: '',
  httpStatus: '',
  status: 'idle',
  all_climate_regions: [],
  climate_fleet_finding_rate: [],
};
export const DEFAULT_OPERATOR_LEVEL_FC_STATE = {
  loading: false,
  error: '',
  httpStatus: '',
  status: 'idle',
  operator_finding_list: [],
  operator_finding_avg_count: [],
};

export const DEFAULT_FLEET_LEVEL_FC_STATE = {
  loading: false,
  error: '',
  httpStatus: '',
  status: 'idle',
  fleet_finding_list: [],
  fleet_finding_avg_count: [],
};
export const DEFAULT_FLEET_CARD_STATE = {
  loading: false,
  error: '',
  httpStatus: '',
  status: 'idle',
  world_wide_finding_rate: '',
  world_wide_enhanced_finding_rate: '',
};

export const DEFAULT_ACTUAL_INTERVAL_STATE = {
  loading: false,
  error: '',
  httpStatus: '',
  status: 'idle',
};

export const DEFAULT_CUMMULATIVE_INTERVAL_STATE = {
  loading: false,
  error: '',
  httpStatus: '',
  status: 'idle',
};
export const DEFAULT_PERFORMANCE_STATE = {
  filterId: '',
  taskNumber: '',
  table: {
    limit: 0,
    offset: 0,
    filterGroup: [],
  },
};
export const DEFAULT_OPERATOR_CARD_STATE = {
  loading: false,
  error: '',
  httpStatus: '',
  status: 'idle',
  total_tr: '',
  total_ac_reported: '',
  sufficient_reported_data_for_optimization: '',
  sufficent_reported_bar: '',
  sufficient_task_report: '',
  avg_yield: '',
  operator_finding_rate: '',
  ffr: '',
  me: '',
};
