import React from 'react';

type dummyPropsType = {
  dummyText: string;
};
export default function DummyComponent(props: dummyPropsType) {
  const { dummyText } = props;
  return (
    <div>
      <h2>{dummyText}</h2>
    </div>
  );
}
