import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Col,
  Inline,
  Select,
  Stack,
  Tab,
  Tabs,
  ComboBox,
} from '@airbus/components-react';

import Chart from '../Shared/Charts/Chart';
import SpinnerComponent from '../Spinner/SpinnerComponent';
import './TAChartComponent.scss';

export type ChartTabs = {
  tabName: string;
  class: string;
};

export type ChartDropDown = {
  label: string;
  value: string;
};

export type MultiChartDropDownData2 = {
  key: string;
  type: string;
  data: ChartDropDown[];
  titleText?: string;
  onChangeCallbackFunc?: (type: string, e: Array<ChartDropDown>) => void;
};

type ChartComponentDropDown = {
  tabs?: ChartTabs[] | null;
  selectedTabIndex?: number;
  chartData: chartProps;
  chartDropDown1?: ChartDropDown[] | null;
  loading?: boolean;
  multiChartData?: chartProps[] | null;
  multiChartDropDownData2?: MultiChartDropDownData2[] | null;
  status?: string;
};

export default function ChartComponent(props: ChartComponentDropDown): React.JSX.Element {
  const [selectedTab, setSelectedTab] = useState(0);

  const {
    tabs, selectedTabIndex, chartData, chartDropDown1, loading, multiChartData, multiChartDropDownData2, status,
  } = props;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const updateTabSelected = (value: any) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    updateTabSelected(selectedTabIndex);
  }, [selectedTabIndex]);

  return (
    <Stack className="chart-screen-cls">
      <Card className="chart-screen">
        { loading ? (
          <div className="loader-center">
            <SpinnerComponent size="small" label="" />
          </div>
        ) : (
          <>
            <Inline>
              <Col className="tabs-styling chart-component-selection-bar">
                {tabs && (
                <Tabs
                  className="chart-tab"
                  aria-label="Tabs purpose label"
                  value={selectedTab}
                  onChange={(event, value) => updateTabSelected(value)}
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.class}
                      className="chart-tabs"
                      aria-label={tab.tabName}
                    >
                      {tab.tabName}
                    </Tab>
                  ))}
                </Tabs>
                )}
              </Col>
              <Col>
                {chartDropDown1 && (
                <div className="finding-type">
                  <Select
                    aria-label="Select an option"
                    defaultValue="option1"
                    options={chartDropDown1}
                  />
                </div>
                )}
                { multiChartDropDownData2 && (
                  <div className="range-selection">
                      {
                        multiChartDropDownData2.map((dropdown, index) => {
                          return (
                            <div key={`dropdown--${dropdown.key}`} style={{ display: `${index === selectedTab ? 'flex' : 'none'}` }}>
                              { dropdown.titleText && (
                              <p className="finding-type-label-text">
                                { dropdown.titleText }
                                {' '}
                                :
                              </p>
                              ) }
                              <ComboBox
                                multiple
                                className="w-288 finding-type-dropdown"
                                limitChips={1}
                                aria-label="Filter chart based on finding type selections"
                                disabled={!dropdown.data?.length}
                                defaultValue={dropdown.data}
                                options={dropdown.data}
                                onChange={(e) => dropdown?.onChangeCallbackFunc?.(dropdown.type, e as ChartDropDown[])}
                              />
                            </div>
                          );
                        })
                      }
                  </div>
                )}
              </Col>
            </Inline>
            <CardContent>
              <Col>
                { multiChartData?.length ? <Chart chartData={multiChartData[selectedTab]} apiStatus={status} /> : <Chart chartData={chartData} apiStatus={status} /> }
              </Col>
            </CardContent>
          </>
        )}
      </Card>
    </Stack>
  );
}

ChartComponent.defaultProps = {
  tabs: null,
  chartDropDown1: null,
  loading: false,
  multiChartData: null,
  multiChartDropDownData2: null,
  status: null,
  selectedTabIndex: 0,
};
