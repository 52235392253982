import React, { SetStateAction } from 'react';
import {
  Button, Typography, Inline, Chip, Row, Col,
} from '@airbus/components-react';
import './SMPCTableHiddenCols.scss';
import { Reset, Done } from '@airbus/icons/react';

type SMPCTableHiddenColsProps = {
  showHiddenCols: React.Dispatch<SetStateAction<boolean>>;
  hiddenCols: mpdTableColsType[];
  clearAll: () => void;
  clearHiddenCol: (id: string) => void;
  hiddenColsShown: boolean;
};

const SMPCTableHiddenCols: React.FC<SMPCTableHiddenColsProps> = function SMPCTableHiddenCols(props: SMPCTableHiddenColsProps) {
  const {
    showHiddenCols, hiddenCols, clearAll, clearHiddenCol, hiddenColsShown,
  } = props;

  const renderClearAllButton = () => (
    <Button
      variant="ghost"
      size="small"
      aria-label="ClearAll"
      onClick={() => {
        clearAll();
      }}
    >
      <div className="icon">
        <Reset />
      </div>
      Clear All
    </Button>
  );

  const renderDoneButton = () => (
    <Button
      variant="secondary"
      aria-label="Done"
      size="small"
      className="content-right"
      onClick={() => {
        showHiddenCols(false);
      }}
    >
      <div className="icon">
        <Done />
      </div>
      Done
    </Button>
  );

  return (
    <Row alignItems="center" className="smpc-table-hidden-cols-cls">
      <Col xxs={12} md={11}>
        <Inline spacing="2-x" wrap>
          <Typography variant="small" className="bold-title">
            Hidden Columns
          </Typography>
          <Inline spacing="1-x" wrap>
            {hiddenCols.map((column) => (
              <Chip key={column.id} label={column.title} aria-label="Hidden Column" onDelete={() => clearHiddenCol(column.id)} />
            ))}
            {hiddenCols.length !== 0 && renderClearAllButton()}
          </Inline>
        </Inline>
      </Col>
      <Col xxs={12} md={1}>
        {hiddenColsShown && renderDoneButton()}
      </Col>
    </Row>
  );
};

export default SMPCTableHiddenCols;
