import createCustomAsyncThunk from '../../utils/thunkUtils/createCustomAsyncThunk';
import { Methods } from '../../utils/customHooks/apiTypes';
import apiPollingSlice from './APIPollingSlice';
/* eslint-disable @typescript-eslint/no-explicit-any */

export const createAPIPolling: any = (path: string) => {
  const customAction = {
    sliceName: apiPollingSlice.name,
    actionType: 'APIPolling',
  };

  const apiRequestObj = {
    path,
    method: 'get' as Methods,
  };

  return createCustomAsyncThunk(customAction, apiRequestObj);
};
