import { createSlice } from '@reduxjs/toolkit';
import { BANNER_SLICE } from './constants';
import { addBannerReducer, clearBannerLocationReducer, removeBannerReducer } from './bannerReducer';

export const initialState: BannerList = { banners: [] };

const bannerSlice = createSlice({
  name: BANNER_SLICE,
  initialState,
  reducers: {
    // sync action creators
    addBanner: addBannerReducer,
    removeBanner: removeBannerReducer,
    clearBannerLocation: clearBannerLocationReducer,
  },
});

export const { addBanner, removeBanner, clearBannerLocation } = bannerSlice.actions;
export default bannerSlice;
