import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@airbus/components-react';
import './DialogComponent.scss';

/**
 * This Dialog component is used to generate the pop up with a custom message, title, primary button name
 * and secondary button name.
 *
 * The custom information can be provided in 'DialogComponent\Contants file.
 *
 * To use this component, import this DialogComponent in your file and add after the button to generate the pop up.
*/

type DialogProps = {
  message: JSX.Element;
  open: boolean;
  primmaryButtonName ?: string;
  secondaryButtonName ?: string;
  confirmButtonName ?: string;
  dialogTitle: string;
  onClose: () => void;
  onSubmit: () => void;
};

const DialogComponent = (props: DialogProps) => {
  const {
    message, open, primmaryButtonName, secondaryButtonName, confirmButtonName, dialogTitle, onClose, onSubmit,
  } = props;

  return (
    <Dialog aria-label="dialogConfirmationComponent" open={open} onClose={onClose}>
      <DialogHeader className="dialog-header">{dialogTitle}</DialogHeader>
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        {primmaryButtonName && (
        <Button variant="error" onClick={onSubmit}>
          {primmaryButtonName}
        </Button>
        ) }
        {secondaryButtonName && (
        <Button aria-label="dialogSecondoryBtn" variant="secondary" className="dialog-component-cancel-button" onClick={onClose}>
          {secondaryButtonName}
        </Button>
        ) }
        {confirmButtonName && (
        <Button variant="primary" name="accessButton" onClick={onSubmit}>
          {confirmButtonName}
        </Button>
        ) }
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;

DialogComponent.defaultProps = {
  primmaryButtonName: undefined,
  secondaryButtonName: undefined,
  confirmButtonName: undefined,
};
