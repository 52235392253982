import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Spinner, Typography, Button, ProgressBar,
} from '@airbus/components-react';

import {
  BuildOutline, Block, FolderOpen, Clear, AcTwinEngine,
  AirplanemodeInactive,
} from '@airbus/icons/react';
import { WARNING_TOAST_COMPONENT_MESSAGE, WARNING_TOAST_COMPONENT_TITTLE } from '../Toast/ToastComponentConstants';

import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { RootState } from '../../../store/store';
import { createToast } from '../../../models/ToastModel/toastSlice';
import { secureLS } from '../../../utils/localStorageUtil/lsHelper';
import { POSSIBLE_BUILD_STATUS } from '../../../models/programAnalysis/constants';
import { NEW_ARCH } from '../../TaskReportAnalysis/constants';

import './EmptyContent.scss';

export type ECProps = {
  title?: string | undefined;
  subtitle?: string | undefined;
  status?: string;
  progress?: number[];
};

const statusesIcons = {
  [POSSIBLE_BUILD_STATUS.IN_PROGRESS]: <AcTwinEngine />,
  [POSSIBLE_BUILD_STATUS.INIT]: <AcTwinEngine />,
  [POSSIBLE_BUILD_STATUS.FAILED]: <BuildOutline />,
  [POSSIBLE_BUILD_STATUS.READY]: <Clear />,
  [POSSIBLE_BUILD_STATUS.UNAUTHORIZED]: <Block />,
  IMPACTED: <AirplanemodeInactive />,
  EMPTY: <FolderOpen />,
  LOADING: <Spinner />,
};

const EmptyContent: React.FC<ECProps> = function EmptyContent(props: ECProps) {
  const {
    title, subtitle, status, progress,
  } = props;
  const warningToastId = 'warningToastId';
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const architecture = secureLS.get('architecture');
  const {
    build: buildInfo, tasks: taskObj,
  } = useAppSelector((state: RootState) => state.programAnalysis);
  const {
    BuildIdList: buildListData, BuildIdListCount: buildListDataCount,
  } = useAppSelector((state: RootState) => state.PABuildList);
  // eslint-disable-next-line consistent-return
  const dataDisplay = () => {
    return (
      <div className="icon">
        {statusesIcons[status as string]}
      </div>
    );
  };

  useEffect(() => {
    if (buildInfo.id !== '' && buildInfo.id !== undefined && !buildListData.includes(buildInfo.id)) {
      if (buildListDataCount === 9 && !taskObj.fetchInfo.loading && !buildInfo.fetchInfo.loading) {
        dispatch(createToast({
          id: `${warningToastId}`,
          cssClass: 'repo-tost-cls',
          variant: 'warning',
          title: `${WARNING_TOAST_COMPONENT_TITTLE}`,
          content: [`${WARNING_TOAST_COMPONENT_MESSAGE}`],
        }));
      }
    }
  }, [buildInfo.id, taskObj.fetchInfo.loading, buildInfo.fetchInfo.loading, buildListDataCount, buildListData, dispatch]);

  const buttonDisplay = () => {
    if (['EMPTY', 'IMPACTED', POSSIBLE_BUILD_STATUS.FAILED].includes(status as string)) {
      return (
        <div className="button-div">
          <Button
            variant="primary"
            onClick={() => {
              navigate('/repository', { state: '/program-analysis' });
            }}
            aria-label="check-build-status"
          >
            Access saved results
          </Button>
        </div>
      );
    }
    return null;
  };

  const displaySpinnerForPreComp = () => {
    if ([POSSIBLE_BUILD_STATUS.INIT, POSSIBLE_BUILD_STATUS.IN_PROGRESS].includes(status as string)
    && architecture === NEW_ARCH) {
      return (
        <Spinner />
      );
    }
    return null;
  };

  return (
    <div className="col-main-content">
      {dataDisplay()}
      <h5>{title}</h5>
      {[POSSIBLE_BUILD_STATUS.IN_PROGRESS].includes(status as string) ? (
        <div style={{ minWidth: '300px', marginBottom: '10px' }}>
          <ProgressBar value={Math.round(((progress as number[])[0] / (progress as number[])[1]) * 100)} showPercentage />
        </div>
      ) : null}
      {displaySpinnerForPreComp()}
      <Typography variant="small">{subtitle}</Typography>
      {buttonDisplay()}
    </div>
  );
};

EmptyContent.defaultProps = {
  title: undefined,
  subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
  status: "You don't have any task",
  progress: [0, 1],
};

export default EmptyContent;
