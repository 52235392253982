import React, { useEffect } from 'react';
import {
  Col, Pagination, Row, Tab, Tabs,
} from '@airbus/components-react';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { RootState } from '../../../store/store';
import { secureLS } from '../../../utils/localStorageUtil/lsHelper';
import { addBanner, clearBannerLocation } from '../../../models/bannerModel/bannerSlice';
import { bannerLocationMap, REPO_EXPORT_DOWNLOAD_FAILED } from '../../../models/bannerModel/constants';
import {
  clearAllEmtlExportTaskBanner,
  updateEmtlExportTask,
  updateEmtlPageNumber,
  updateEmtlRowsPerPage,
  updateRepositoryTab,
} from '../../../models/mpdRepositoryModel/mpdRepositorySlice';
import { downloadFile } from '../../../container/MpdRepository/mpdRepositoryUtils';
import MpdRepositorySearch from '../../MpdRepositorySearch/MpdRepositorySearch';
import { clearAllToast } from '../../../models/ToastModel/toastSlice';
import MpdRepositoryEmtlList from './MpdRepositoryEmtlList/MpdRepositoryEmtlList';

const EmtlCardsContainer = () => {
  const dispatch = useAppDispatch();
  const {
    RepositoryEMTLTabType: tab,
    RepositoryTaskListRowCountType: totalRowCount,
    RepositoryTaskListCurPageNumber: page,
    RepositoryTaskListRowsPerPage: rowsPerPage,
    RepositoryExportFileDownloadURL: downloadUrl,
    RespositoryExportFileDownloadError: downloadError,
    RepositorySelectedFilesList: selectedFiles,
    RepositoryFilterGroup,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  const acProgram = secureLS.get('ac_program');

  useEffect(() => {
    if (downloadError) {
      dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
      dispatch(clearBannerLocation(bannerLocationMap.REPOSITORY_MODULE));
      dispatch(addBanner(REPO_EXPORT_DOWNLOAD_FAILED));
      dispatch(clearAllEmtlExportTaskBanner());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadError]);

  useEffect(() => {
    if (downloadUrl) {
      downloadFile(downloadUrl);
      dispatch(updateEmtlExportTask(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadUrl]);

  const repositoryTabClickHandler = (tabName: string) => {
    dispatch(updateRepositoryTab(tabName));
    dispatch(clearAllToast());
  };

  const noDataForFilter = RepositoryFilterGroup.length > 0 && totalRowCount === 0;
  const showSearch = totalRowCount > 0 || noDataForFilter;
  const showPagination = totalRowCount > 3;

  const emtlList = (
    <Col className="repository-content-cls">
      <Row className="repository-header-cls">
        <Col sm={4} md={3}>
          <Tabs
            aria-label="Tabs purpose label"
            value={tab === 'EMTL' ? 0 : 1}
            style={{ height: '32px' }}
          >
            <Tab
              onClick={() => repositoryTabClickHandler('EMTL')}
            >
              EMTL
            </Tab>
            <Tab onClick={() => repositoryTabClickHandler('EMTL_DELTA')}>
              EMTL Delta
            </Tab>
          </Tabs>
        </Col>
        {showSearch && (
          <Col sm={8} md={9} xl={8} className="repository-search-container">
            <MpdRepositorySearch acProgram={acProgram} moduleName={tab} />
          </Col>
        )}
      </Row>
      <MpdRepositoryEmtlList />
      {showPagination && (
        <Pagination
          aria-label="mpd-repository-pagination"
          className={selectedFiles.length ? 'repository-pagination-selection-cls' : 'repository-pagination-cls'}
          legend={`Showing ${totalRowCount === 0 ? 0 : ((page - 1) * rowsPerPage) + 1}-${Math.min(page * rowsPerPage, totalRowCount)} of ${totalRowCount} results`}
          page={page}
          count={totalRowCount || 1}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={(_, num) => {
            dispatch(updateEmtlPageNumber(num));
          }}
          onRowsPerPageChange={(e, val) => {
            dispatch(updateEmtlRowsPerPage(val));
            dispatch(updateEmtlPageNumber(1));
          }}
          showGoto
        />
      )}
    </Col>
  );

  return emtlList;
};

export default EmtlCardsContainer;
