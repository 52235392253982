import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

const SignIn = () => {
  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    if (process.env.REACT_APP_STAGE === 'pro') {
      Auth.federatedSignIn({ customProvider: 'airbus-onelogin' }).catch((e) => {});
    } else {
      Auth.federatedSignIn().catch((e) => {});
    }
  });
  return null;
};

export default SignIn;
