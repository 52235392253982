import React, { useEffect, useState } from 'react';
import {
  Col, Container, IconButton, Row, Inline, Typography,
} from '@airbus/components-react';
import { ArrowBack } from '@airbus/icons/react';
import { useNavigate, useParams } from 'react-router-dom';
import './EmtlDeltaViewSOC.scss';
import GenericTable from '../../Shared/GenericTable/GenericTable';
import { TableColumnState, generateTableColumns } from './EmtlDeltaViewSOCTable/EmtlDeltaViewSOCTableUtils';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { generateHidableColumns, getTableColumns } from '../../MpdTaskTable/mpdTableStructureUtils';
import { createDeltaSocTableView, createDeltaSocTableViewFilter } from '../../../models/mpdRepositoryModel/SOC/deltaViewSocAsyncThunk';
import { RootState } from '../../../store/store';
import { DELTA_DATA_LOAD_ERROR, bannerLocationMap } from '../../../models/bannerModel/constants';
import { addBanner, clearBannerLocation } from '../../../models/bannerModel/bannerSlice';
import { clearAllDeltaSocTableViewData, clearDeltaSocTableViewFilter } from '../../../models/mpdRepositoryModel/SOC/deltaViewSocSlice';
import MpdEmptyDataComponent from '../../Shared/MpdEmptyDataComponent/MpdEmptyDataComponent';
import { CONSTANTS } from '../ProgCustomization/MpdRepositoryEmtlList/constants';

const EmtlDeltaViewSOC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    socTableData, socDeltaViewDataLoading: loading, socDeltaViewFilterData: viewFilterData, error,
  } = useAppSelector((state: RootState) => state.deltaViewSoc);

  const [pageNo, setpageNo] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchPairs, setSearchPairs] = useState({ columnName: '', columnInput: '' });
  const [columnFilter, setColumnFilter] = useState<Array<{ [key: string]: string }>>([]);
  const [filterGroups, setFilterGroups] = useState<Array<{ columnName: string, columnInput: string }>>([]);

  useEffect(() => {
    dispatch(clearAllDeltaSocTableViewData());
    dispatch(createDeltaSocTableView(
      id,
      (pageNo + 1),
      limit,
      filterGroups.map((filter) => ({ filter_column: filter.columnName, filter_value: filter.columnInput })),
    )());
    return () => {
      dispatch(clearDeltaSocTableViewFilter());
    };
  }, [filterGroups, id, limit, pageNo, dispatch]);

  useEffect(() => {
    const filterOptions: Array<{ [key: string]: string }> = [];
    viewFilterData.column_value.forEach((option) => (
      filterOptions.push({ [viewFilterData.column_name]: option })
    ));
    setColumnFilter(filterOptions);
  }, [viewFilterData]);

  useEffect(() => {
    if (error) {
      dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
      dispatch(clearBannerLocation(bannerLocationMap.REPOSITORY_MODULE));
      dispatch(addBanner(DELTA_DATA_LOAD_ERROR));
    }
  }, [error, dispatch]);

  const onPaginationChange: (data: {
    pageIndex: number,
    pageSize: number
  }) => void = ({ pageIndex, pageSize }) => {
    setpageNo(pageIndex);
    setLimit(pageSize);
  };

  const onUpdateFilterGroup: () => void = () => {
    setFilterGroups((prevState) => {
      const newFilterGroup = prevState.filter((filterGroup) => filterGroup.columnName !== searchPairs.columnName);
      return [...newFilterGroup, { columnName: searchPairs.columnName, columnInput: searchPairs.columnInput }];
    });
  };

  const onUpdateColumnFilter: (columnName: string) => void = (columnName) => {
    /* istanbul ignore else */
    if (columnName !== searchPairs.columnName) {
      setSearchPairs((prevState) => ({ ...prevState, columnName }));
    }
  };

  const onDeleteFromFilterGroup: (filterGroup: searchPair[]) => void = (filterGroup) => {
    const deleteColumnNameList = filterGroup.map((column) => column.columnName);
    setFilterGroups((prev) => (
      prev.filter((filter) => !deleteColumnNameList.includes(filter.columnName))
    ));
  };

  const onUpdateColumnFilterValue: (columnInput: string) => void = (columnInput) => {
    setSearchPairs((prevState) => ({ ...prevState, columnInput }));
  };

  const onFetchColumnFilterValues: (input: string) => void = (input) => {
    setSearchPairs((prevState) => ({ ...prevState, columnInput: input }));
    dispatch(createDeltaSocTableViewFilter(
      id,
      { filter_column: searchPairs.columnName, filter_value: input },
    )());
  };

  let socTableComponent = (
    <GenericTable
      cssClass="emtl-delta-view-soc-table"
      tableCols={socTableData.headers}
      tableData={socTableData.records}
      totalDataCount={socTableData.totalHits}
      onPaginationChangeCallback={onPaginationChange}
      Loading={loading}
      generateHidableColumns={generateHidableColumns}
      generateTableColumns={generateTableColumns}
      tableColumnState={TableColumnState}
      getTableColumns={getTableColumns}
      searchPair={searchPairs}
      filterGroup={filterGroups}
      columnFilter={columnFilter}
      updateColumnFilter={onUpdateColumnFilter}
      updateColumnFilterValue={onUpdateColumnFilterValue}
      updateFilterGroup={onUpdateFilterGroup}
      deleteFromFilterGroup={onDeleteFromFilterGroup}
      fetchColumnFilterValues={onFetchColumnFilterValues}
      noSearchKeySizeLimitColumns={socTableData.filterData}
      filterChipPositionDetached
      hideCustomizeColumn
      isDataDynamic
    />
  );

  const socData = () => {
    if (!loading && !socTableData.records.length && !filterGroups.length) {
      socTableComponent = (
        <div style={{ margin: '33vh 0 0 0' }}>
          <MpdEmptyDataComponent iconName={CONSTANTS.emptyContentIconName} title={CONSTANTS.noDeltaSocTitle} subTitle={CONSTANTS.noDeltaSocSubtitle} />
        </div>
      );
    }
    return socTableComponent;
  };
  return (
    <Col className="repository-content-cls">
      <Container className="emtl-delta-view-soc-container-cls custom-container">
        <Row>
          <Col className="delta-soc-page-title" sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Inline spacing="2-x">
              <IconButton
                size="small"
                onClick={() => navigate(`../emtl/view/${id}`)}
                className="emtlDeltaViewSOCBackBtn"
                aria-label="delta-soc-back-btn"
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="large">
                <b>Summary of Changes</b>
              </Typography>
            </Inline>
          </Col>
          <Col>
            {socData()}
          </Col>
        </Row>
      </Container>
    </Col>
  );
};

export default EmtlDeltaViewSOC;
