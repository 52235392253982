export const genericTableMockData = [
  {
    'A/C Type': 'A320',
    MSN: 'N00070',
    'Finding Type': 'Abnormal Wear',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '4',
    'Actual Interval (FH)': '1',
    'Actual Interval (FC)': '3',
  },
  {
    'A/C Type': 'A320',
    MSN: 'N00071',
    'Finding Type': 'Corrosion',
    'Corrective Action': 'Corrective measures taken',
    'Actual Interval (MO)': '6',
    'Actual Interval (FH)': '2',
    'Actual Interval (FC)': '9',
  },
  {
    'A/C Type': 'A320',
    MSN: 'N00072',
    'Finding Type': 'Corrosion',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '1',
    'Actual Interval (FH)': '3',
    'Actual Interval (FC)': '7',
  },
  {
    'A/C Type': 'A320',
    MSN: 'N00073',
    'Finding Type': 'Crack',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '5',
    'Actual Interval (FH)': '6',
    'Actual Interval (FC)': '2',
  },
  {
    'A/C Type': 'A330',
    MSN: 'N00074',
    'Finding Type': 'Crack',
    'Corrective Action': 'Corrective measures taken',
    'Actual Interval (MO)': '4',
    'Actual Interval (FH)': '2',
    'Actual Interval (FC)': '7',
  },
  {
    'A/C Type': 'A340',
    MSN: 'N00075',
    'Finding Type': 'Abnormal Wear',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '8',
    'Actual Interval (FH)': '1',
    'Actual Interval (FC)': '4',
  },
  {
    'A/C Type': 'A350',
    MSN: 'N00076',
    'Finding Type': 'Abnormal Wear',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '1',
    'Actual Interval (FH)': '5',
    'Actual Interval (FC)': '5',
  },
  {
    'A/C Type': 'A380',
    MSN: 'N00077',
    'Finding Type': 'Corrosion',
    'Corrective Action': 'Remedial measures and verified',
    'Actual Interval (MO)': '3',
    'Actual Interval (FH)': '10',
    'Actual Interval (FC)': '7',
  },
  {
    'A/C Type': 'A350',
    MSN: 'N00078',
    'Finding Type': 'Crack',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '8',
    'Actual Interval (FH)': '6',
    'Actual Interval (FC)': '4',
  },
  {
    'A/C Type': 'A330',
    MSN: 'N00079',
    'Finding Type': 'Crack',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '10',
    'Actual Interval (FH)': '8',
    'Actual Interval (FC)': '2',
  },
  {
    'A/C Type': 'A320',
    MSN: 'N00070',
    'Finding Type': 'Abnormal Wear',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '4',
    'Actual Interval (FH)': '1',
    'Actual Interval (FC)': '3',
  },
  {
    'A/C Type': 'A320',
    MSN: 'N00071',
    'Finding Type': 'Corrosion',
    'Corrective Action': 'Corrective measures taken',
    'Actual Interval (MO)': '6',
    'Actual Interval (FH)': '2',
    'Actual Interval (FC)': '9',
  },
  {
    'A/C Type': 'A320',
    MSN: 'N00072',
    'Finding Type': 'Corrosion',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '1',
    'Actual Interval (FH)': '3',
    'Actual Interval (FC)': '7',
  },
  {
    'A/C Type': 'A320',
    MSN: 'N00073',
    'Finding Type': 'Crack',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '5',
    'Actual Interval (FH)': '6',
    'Actual Interval (FC)': '2',
  },
  {
    'A/C Type': 'A330',
    MSN: 'N00074',
    'Finding Type': 'Crack',
    'Corrective Action': 'Corrective measures taken',
    'Actual Interval (MO)': '4',
    'Actual Interval (FH)': '2',
    'Actual Interval (FC)': '7',
  },
  {
    'A/C Type': 'A340',
    MSN: 'N00075',
    'Finding Type': 'Abnormal Wear',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '8',
    'Actual Interval (FH)': '1',
    'Actual Interval (FC)': '4',
  },
  {
    'A/C Type': 'A350',
    MSN: 'N00076',
    'Finding Type': 'Abnormal Wear',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '1',
    'Actual Interval (FH)': '5',
    'Actual Interval (FC)': '5',
  },
  {
    'A/C Type': 'A380',
    MSN: 'N00077',
    'Finding Type': 'Corrosion',
    'Corrective Action': 'Remedial measures and verified',
    'Actual Interval (MO)': '3',
    'Actual Interval (FH)': '10',
    'Actual Interval (FC)': '7',
  },
  {
    'A/C Type': 'A350',
    MSN: 'N00078',
    'Finding Type': 'Crack',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '8',
    'Actual Interval (FH)': '6',
    'Actual Interval (FC)': '4',
  },
  {
    'A/C Type': 'A330',
    MSN: 'N00079',
    'Finding Type': 'Crack',
    'Corrective Action': 'Performed the maintenance action',
    'Actual Interval (MO)': '10',
    'Actual Interval (FH)': '8',
    'Actual Interval (FC)': '2',
  },
];

export const tableCellMaxLength = 100;
export const tableCellMaxLines = 6;
