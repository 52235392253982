/* eslint-disable import/no-unresolved */

import React from 'react';
import {
  Button, ComboBox, Typography,
} from '@airbus/components-react';
import './SelectComboBox.scss';
import { ComboBoxProps } from '@airbus/components-react/dist/components/ComboBox';
import { DefaultOption } from '@airbus/components-react/dist/components/ComboBox/ComboBox';

type SelectComboBoxProps = {
  showTotalNumber?: boolean; // should we show the number of possible values besides "Select/Deselect All"?
  comboProps: Partial<ComboBoxProps>;
  dropdownHeader: string;
}

type Option = DefaultOption | string;

/* eslint-disable react/jsx-props-no-spreading */
const SelectComboBox: React.FC<SelectComboBoxProps> = (props: SelectComboBoxProps) => {
  const {
    showTotalNumber, comboProps, dropdownHeader,
  } = props;

  /*
    Determines if the text to display is "Select" or "Deselect"
   */
  const textSelect = () => {
    let text = 'Select All';
    if ((comboProps.options as unknown[]).length === (comboProps.value as unknown[]).length) {
      text = 'Deselect All';
    }
    text = showTotalNumber ? `${text} ${(comboProps.options as unknown[]).length}` : text;
    return <small>{text}</small>;
  };

  /*
    Updating values depending on the length of both arrays.
    If they're equal, then we know all values are selected and we should deselect all
    If not, then we select all values
   */
  const onSelectDeselectOptClick = () => {
    const values = (comboProps.options as unknown[]).length === (comboProps.value as unknown[]).length ? [] : (comboProps.options as unknown[]);
    comboProps.onChange?.(values as Option[]);
  };

  return (
    <div className="select-combo-box-filter-drawer-combo">
      <div className="select-combo-box-combobox-label">
        <Typography variant="small">
          {dropdownHeader}
        </Typography>
        { comboProps.options && comboProps.options.length !== 0
          ? (
            <Button variant="ghost" onClick={onSelectDeselectOptClick} size="xsmall" style={{ alignSelf: 'end' }}>
              {textSelect()}
            </Button>
          ) : null}
      </div>
      <ComboBox
        multiple
        {...comboProps}
      />
    </div>
  );
};

SelectComboBox.defaultProps = {
  showTotalNumber: false,
};

export default SelectComboBox;
