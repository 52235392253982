import React from 'react';
import './SkywiseCrown.scss';

function SkywiseCrown() {
  return (
    <div className="skycrown-svg-cls" aria-label="skywise-crown">
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
      >
        <path
          d="M8.94835 9.33919e-05C8.83272 -0.00161249 8.71643 0.0202812 8.5929 0.0469186C8.39523 0.0894289 8.1838 0.156031 7.97253 0.22559C7.76119 0.295011 7.55293 0.366366 7.37619 0.419796C7.19944 0.473253 7.04399 0.504999 6.99435 0.50509C6.94464 0.505063 6.79261 0.474713 6.6158 0.42162C6.43895 0.368437 6.22855 0.296489 6.01711 0.227396C5.80569 0.158339 5.59162 0.0895201 5.3939 0.04732C5.19616
             0.00508333 5.02032 -0.0263889 4.83198 0.035096C4.64362 0.0964896 4.51912 0.228536 4.38413 0.379211C4.24915 0.529894 4.11412 0.709632 3.9839 0.890228C3.85369 1.07081 3.72833 1.25209 3.61668 1.39936C3.50502 1.54661 3.39708 1.66089 3.35693 1.69021C3.31645 1.71885 3.17554 1.78859 3.00101 1.84909C2.82649 1.90957 2.6149 1.97245 2.40326 2.04095C2.19169 2.10955 1.98076 2.18203 1.79608
             2.2644C1.61136 2.34668 1.45157 2.42523 1.33551 2.58636C1.21919 2.74691 1.19405 2.92613 1.17335 3.12755C1.15265 3.32897 1.15008 3.55108 1.15072 3.77385C1.15134 3.99661 1.15509 4.21785 1.15119 4.40272C1.1473 4.58761 1.12832 4.74524 1.11301 4.79258C1.09767 4.83993 1.02198 4.97427 0.9169 5.12634C0.811806 5.27837 0.677784 5.45729 0.546841 5.63735C0.41589 5.8174 0.28643 5.99945 0.185266
             6.17476C0.0841011 6.35007 -0.000160459 6.51094 2.29449e-07 6.70934C0.000158468 6.90774 0.0847572 7.06642 0.186206 7.24156C0.287655 7.4167 0.416545 7.59772 0.54778 7.77755C0.679016 7.9574 0.812495 8.13435 0.917839 8.28621C1.02319 8.43809 1.09951 8.57454 1.11489 8.62186C1.13024 8.6693 1.15124 8.82592 1.15543 9.01078C1.15964 9.19566 1.15569 9.41734 1.15543 9.64011C1.15517 9.86288
             1.15657 10.0855 1.17759 10.2869C1.19862 10.4883 1.22272 10.6662 1.33928 10.8267C1.45585 10.9871 1.61925 11.0671 1.8041 11.1491C1.98896 11.231 2.19956 11.302 2.41127 11.3702C2.62298 11.4384 2.83563 11.5036 3.00997 11.5644C3.18435 11.6253 3.32567 11.6903 3.36589 11.7195C3.40613 11.7488 3.51327 11.8638 3.62516 12.0108C3.73705 12.1579 3.86471 12.3386 3.99522 12.519C4.12573 12.6994
             4.25883 12.8809 4.39404 13.0315C4.52923 13.182 4.65438 13.3102 4.84282 13.3713C5.03124 13.4325 5.20945 13.4012 5.4071 13.3586C5.60477 13.3161 5.81621 13.2499 6.02747 13.1804C6.23881 13.111 6.44707 13.0391 6.62382 12.9857C6.80057 12.9322 6.95601 12.9005 7.00566 12.9003C7.05537 12.9003 7.20787 12.9311 7.38467 12.9842C7.56153 13.0374 7.77146 13.1094 7.9829 13.1785C8.19432 13.2475
             8.40698 13.3131 8.60468 13.3552C8.80242 13.3975 8.97828 13.4289 9.16661 13.3675C9.35497 13.3061 9.48095 13.1774 9.61586 13.0266C9.75079 12.8758 9.88494 12.6934 10.0152 12.5128C10.1454 12.3322 10.2722 12.1533 10.3838 12.006C10.4955 11.8588 10.602 11.7421 10.6421 11.7128C10.6826 11.6842 10.8249 11.6173 10.9995 11.5568C11.174 11.4963 11.3838 11.4307 11.5953 11.3621C11.8069 11.2935
             12.0178 11.221 12.2025 11.1386C12.3872 11.0563 12.549 10.9803 12.665 10.819C12.7813 10.6585 12.806 10.4797 12.8267 10.2783C12.8474 10.0769 12.849 9.85193 12.8483 9.62916C12.8477 9.4064 12.844 9.18469 12.8479 8.99982C12.8518 8.81494 12.8717 8.66014 12.887 8.61279C12.9023 8.56545 12.977 8.42821 13.0822 8.27621C13.1873 8.12419 13.3222 7.94856 13.4532 7.7685C13.5841 7.58845 13.7122
             7.40309 13.8133 7.22778C13.9145 7.05247 14.0002 6.8949 14 6.69651C13.9998 6.49809 13.9157 6.33895 13.8143 6.16381C13.7128 5.98867 13.5825 5.80484 13.4513 5.62498C13.32 5.44513 13.1866 5.26866 13.0812 5.1168C12.9759 4.96492 12.8991 4.82801 12.8837 4.78069C12.8684 4.73343 12.8492 4.57947 12.845 4.39459C12.8408 4.20972 12.8443 3.98849 12.8446 3.76574C12.8448 3.54297 12.8425 3.31751
             12.8215 3.11614C12.8004 2.91475 12.7763 2.7363 12.6598 2.57588C12.5432 2.41548 12.3812 2.3387 12.1964 2.25674C12.0115 2.17473 11.7995 2.10105 11.5878 2.03282C11.3761 1.96458 11.1648 1.90227 10.9905 1.84144C10.8161 1.7805 10.6734 1.71226 10.6332 1.68304C10.593 1.65394 10.4853 1.53927 10.3734 1.39218C10.2615 1.24508 10.1343 1.0639 10.0038 0.883523C9.87333 0.703146 9.74117 0.524466
             9.60596 0.37392C9.47078 0.223382 9.34609 0.095176 9.15765 0.0340287C9.087 0.0111315 9.01773 0.0012793 8.94835 9.33919e-05ZM6.99906 4.13827C7.08388 4.13832 7.16244 4.18288 7.20601 4.25565L8.47458 6.36852L10.4969 5.1947C10.5843 5.14409 10.6939 5.15322 10.7718 5.21757C10.8496 5.28192 10.8791 5.38789 10.8458 5.48321L9.59183 9.10366C9.55808 9.20079 9.46649 9.26588 9.36366
             9.26582H4.63681C4.53398 9.26588 4.44239 9.20079 4.40865 9.10365L3.15233 5.4832C3.11883 5.38776 3.14839 5.28155 3.22638 5.21713C3.30438 5.15272 3.41426 5.14377 3.50165 5.19471L5.52401 6.36853L6.79258 4.25565C6.83607 4.18303 6.91441 4.13849 6.99906 4.13827Z"
          fill="#DA1884"
        />

      </svg>
    </div>
  );
}

export default SkywiseCrown;
