/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import {
  Drawer, Button, Stack, IconButton, Container, Row, Col,
} from '@airbus/components-react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@airbus/icons/react';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import FilterCombobox from './FilterCombos';
import { getPAFilters, postPAFilters } from '../../models/programAnalysis/programAnalysisAsyncThunks';
import {
  clearBuild,
  updatePAFilters,
  clearPrevBuildId,
  clearTasks,
} from '../../models/programAnalysis/programAnalysisSlice';
import { filtersValues } from '../../models/programAnalysis/programAnalysisTypes';
import { secureLS } from '../../utils/localStorageUtil/lsHelper';

import {
  MAINTENANCE_PROGRAM, OPERATOR_ICAO_CODE, TIME_FRAME_YEAR,
} from '../../models/programAnalysis/constants';
import { comboOptions, allFiltersKeys, FILTER_DRAWER_OPEN_TRUTHY_VALUES } from './constants';
import { architecture } from '../../models/programAnalysisRepository/PARepositoryAsyncThunk';
import { NEW_ARCH } from '../TaskReportAnalysis/constants';
import './FilterDrawer.scss';

type ExcludedValues = {
  [filterId: string]: string[];
};

const FilterDrawer = function FilterDrawer() {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(FILTER_DRAWER_OPEN_TRUTHY_VALUES.includes(pathname.split('/program-analysis')[1]));
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useAppDispatch();
  const filtersObj = useAppSelector((state) => state.programAnalysis.filters);
  const buildParams = useAppSelector((state) => state.programAnalysis.build);
  const [startId, setStartId] = useState('');
  const location = useLocation();
  const [excludedValues, setExcludedValues] = useState<ExcludedValues>({});
  const [isDeselectAll, setIsDeselectAll] = useState(false);

  /*
    Based on the URL path, we decide if we need to fetch the filters or not when mounting the component
    If it's either "/program-analysis" or "/program-analysis/" (notice the trailing slash), we fetch
    If there's an ID after the trailing slash, we don't
    {initial: true} parameter in the dispatch will tell the reducer we also *select* these values
   */
  useEffect(() => {
    const urlId = location.pathname.split('/program-analysis')[1];
    /* istanbul ignore else */
    if (FILTER_DRAWER_OPEN_TRUTHY_VALUES.includes(urlId)) {
      dispatch(getPAFilters({ [OPERATOR_ICAO_CODE]: secureLS.get('operator'), [MAINTENANCE_PROGRAM]: secureLS.get('ac_program'), select: allFiltersKeys })({ initial: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
    useEffect to decide when the submit button is disabled or enabled.
    If there is 1 or more ComboBox(es) empty or not filled in any way, it will be disabled (true)
    Else, it will be enabled (false)
   */
  useEffect(() => {
    // remove maintanence program and operator_icao_code from the selected filters
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { maintenance_program: key1, operator_icao_code: key2, ...validObj } = filtersObj.selected as filtersValues;
    const vals = Object.values(validObj);
    if (vals.length < comboOptions.length) {
      setIsDisabled(true);
      return;
    }
    const isEmpty = !vals.every((x) => {
      if (Array.isArray(x)) return x.length !== 0;
      return x !== '';
    });
    setIsDisabled(isEmpty);
  }, [filtersObj.selected]);

  const shallowCompare = (obj1: filtersValues, obj2: filtersValues) => {
    return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { maintenance_program: key1, operator_icao_code: key2, ...validObj } = filtersObj.selected as filtersValues;
    if (buildParams?.build_parameters) {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        maintenance_program: k1, operator_icao_code: k2, user_origin: k3, time_frame_year: k4, ...buildParam
      } = buildParams?.build_parameters as filtersValues;
      const buildDetails = { ...buildParam, ...{ time_frame_year: `${k4}Y` } };
      if (shallowCompare(buildDetails, validObj)) {
        setIsDisabled(true);
      }
    }
  }, [buildParams.build_parameters, filtersObj.selected]);

  /**
   * Helper function to filter out the object after the user's action.
   * If the timeframe_year has changed, no need to readjust anything since these are static values, just return the new object
   * Else, we copy the previous selected filters, and we erase precedent values if need be (because of cascading).
   * Ex: I selected all filters and decide to modify the engine series. ATA_2D and ATA_4D will then be emptied/erased.
   *
   * @param filterId The ID (ac_type, ac_series, etc...) of the ComboBox currently being modified
   * @param newValues New value(s) of the current ComboBox
   * @returns A new filtersValues object which has been filtered
   */
  const cleanFilters = (filterId: string, newValues: string[] | string): filtersValues => {
    if (filterId === TIME_FRAME_YEAR && !Array.isArray(newValues)) {
      return { ...filtersObj.selected, [TIME_FRAME_YEAR]: newValues };
    }

    const newObj: filtersValues = { ...filtersObj.selected };
    newObj[filterId] = newValues;
    // in case of deselect setting state to identify deselect all
    if (architecture() === NEW_ARCH) {
      if (isDeselectAll && newValues.length !== 0) {
        setIsDeselectAll(false);
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const i of allFiltersKeys) {
          if (newValues.length === 0) {
            newObj[i] = [];
            setIsDeselectAll(true);
            break;
          }
        }
      }
    }
    return newObj;
  };

  const prepareExcludeValues = (fetched: Partial<filtersValues>, selected: string[], filterId: string) => {
    const selectedSet = new Set(selected);
    const prevSelected = { ...filtersObj.selected };
    // prevExclusion is previous excluded values , newExclusion is recent deseected value for that filterId
    const prevExclusions = excludedValues[filterId] || [];
    const newExclusions = prevSelected[filterId].filter((item: string) => !selectedSet.has(item));
    const updatedExclusions = Array.from(new Set([...prevExclusions, ...newExclusions]));
    let finalPayload;
    // in case of deselect all exclude is set to empty
    if (isDeselectAll) {
      finalPayload = { ...excludedValues };
      // eslint-disable-next-line no-restricted-syntax
      for (const i of allFiltersKeys) {
        delete finalPayload[i];
      }
    } else {
      finalPayload = { ...excludedValues };
      // Update the current filterId's exclusions
      finalPayload[filterId] = updatedExclusions;
      // if we select deselected value remove from exclude
      const reselectedItems = prevExclusions.filter((item) => selectedSet.has(item));
      if (reselectedItems.length > 0) {
        finalPayload[filterId] = updatedExclusions.filter((item) => !reselectedItems.includes(item));
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(finalPayload)) {
        if (finalPayload[key].length === 0) {
          delete finalPayload[key];
        }
      }
    }
    setExcludedValues(finalPayload);
    return finalPayload;
  };
  /**
   * Helper function to prepare the payload to send to get the next filters.
   * This will help filtering the request.
   * Ex: I already selected A/C Type and A/C series, so I can send them to the API to better filter the next results
   *
   * @param filters The current selected filters
   * @param filterId The ID (ac_type, ac_series, etc...) of the ComboBox currently being modified
   * @returns The right payload to send to the API
   */
  const preparePayloadGetFilters = (filters: filtersValues, filterId: string): filtersValues => {
    const newObj: filtersValues = { ...filters };
    for (let i = 0; i < allFiltersKeys.length; i += 1) {
      // for new architecture we will send only the modified/selected filtervalues as payload
      // for old architecture we will send all the filters which are above the modified/selected filter including modified filter as payload
      if (Array.isArray(newObj[allFiltersKeys[i]]) && (newObj[allFiltersKeys[i]].length === 0 || ((architecture() === NEW_ARCH) ? (allFiltersKeys.indexOf(filterId) !== i) : (allFiltersKeys.indexOf(filterId) < i)))) {
        delete newObj[allFiltersKeys[i]];
      }
    }
    return newObj;
  };

  /**
   * Function to update the selected filters.
   * First, we clean the filters and dispatch an action to update them in Redux store.
   * Then, if the ComboBox is the Timeframe one, we short-circuit and exit early since the values are static, and we don't need to fetch anything
   * Else, we prepare the payload and if the ComboBox is not ATA_4D (the last one), we dispatch a thunk to fetch the corresponding filters
   *
   * @param values Either a string[] or a string, new value to be affected to the ComboBox
   * @param filterId The ID (ac_type, ac_series, etc...) of the ComboBox currently being modified
   */
  const updateSelectedFilters = (values: string[] | string, filterId: string) => {
    setStartId(filterId);
    const newSelectedFilters = cleanFilters(filterId, values);
    dispatch(updatePAFilters(newSelectedFilters));

    if (filterId === TIME_FRAME_YEAR) return;
    const payloadToSend = preparePayloadGetFilters(newSelectedFilters, filterId);
    const exclude = prepareExcludeValues(filtersObj.fetched as filtersValues, values as string[], filterId);
    let selectArray = allFiltersKeys;
    if (architecture() === NEW_ARCH) {
      // Modify selectArray to exclude the current filterId
      selectArray = allFiltersKeys.filter((key) => key !== filterId);
      dispatch(getPAFilters({ ...payloadToSend, select: selectArray, exclude: { ...exclude } })());
    }
  };
  /*
    Simple function to submit filters. It closes the drawer, updates the filters in the Redux store
    and post these filters to the API.
   */
  const submitFilters = () => {
    dispatch(clearBuild());
    setOpen(false);
    dispatch(updatePAFilters(filtersObj.selected as filtersValues));
    dispatch(postPAFilters(filtersObj.selected)());
    // to clear prevbuildId
    dispatch(clearPrevBuildId());
    // to clear the toggle and slider selected for other filter
    dispatch(clearTasks());
  };

  /*
    Function to handle the Select/Deselect all button.
   */
  const selectDeselectAllValues = (filterId: string) => {
    if ((filtersObj.selected as filtersValues)[filterId].length === (filtersObj.fetched as filtersValues)[filterId].length) {
      updateSelectedFilters([], filterId);
    } else {
      updateSelectedFilters((filtersObj.fetched as filtersValues)[filterId], filterId);
    }
  };

  return (
    <div className="filter-drawer-cls">
      <IconButton variant="primary" className="arrow-button" size="small" onClick={() => setOpen(true)} aria-label="open-filter-drawer">
        <KeyboardArrowRight />
      </IconButton>
      <Drawer open={open} anchor="left" disableBackdropClick>
        <div className="filter-drawer-opened">
          <Container>
            <Row alignItems="center">
              <Col>
                <p>Filters</p>
              </Col>
              <Col xs={4} md={1}>
                <IconButton className="arrow-button" variant="primary" size="small" onClick={() => setOpen(false)} aria-label="close-filter-drawer">
                  <KeyboardArrowLeft />
                </IconButton>
              </Col>
            </Row>
          </Container>
          <Stack spacing="2-x" className="w-288">
            {comboOptions.map((item) => {
              return <FilterCombobox optsFromReq={filtersObj.fetched as filtersValues} filterId={item.filterId} filterText={item.filterText} getNextFilters={updateSelectedFilters} selectAllValues={selectDeselectAllValues} startId={startId} key={`key-${item.filterId}`} />;
            })}
          </Stack>
          <Button variant="primary" className="button-margin" disabled={isDisabled} onClick={submitFilters} aria-label="submit-filters">
            Submit
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default FilterDrawer;
