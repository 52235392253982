import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Flex,
  Row,
  Link,
} from '@airbus/components-react';
import { OpenInNew } from '@airbus/icons/react';
import { secureLS } from '../../../utils/localStorageUtil/lsHelper';
import './CardComponent.scss';
import { checkFeaturePermission } from '../../../utils/userPermissionUtils/userPermissionUtils';
import { features } from '../../../utils/userPermissionUtils/userPermissionFeatures';

interface btnProps {
  btn: string;
  routeTo: string;
}

interface CheckboxDetails {
  key: string;
  description: string;
}
interface ActionType {
  checkBox: CheckboxDetails[];
  button: string[];
}

interface Confirmation {
  title: string;
  description: string;
  action: ActionType;
}

interface dataProps {
  data: {
    id: number;
    title: string;
    cardFeatureCode?: string;
    description: string;
    routeTo: string;
    disabled?: boolean;
    links?: Array<btnProps>;
    buttonName?: string;
    confirmation?: Confirmation;
  };
}

const CardComponent: React.FC<dataProps> = function CardComponent(
  props: dataProps,
) {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = props;

  const showMultiBtns = data?.links
    && data.links.map((item) => {
      return (
        <Link
          label={item.btn}
          className="card-buttons"
          href={item.routeTo}
          target="_blank"
        >
          <span>
            {item.btn}
            {' '}
            <OpenInNew className="open-new-page-icon" />
          </span>
        </Link>
      );
    });

  const onClickNavigate = () => {
    navigate(data.routeTo, {
      state: location.pathname,
    });
  };

  // Check for the required permissions if the card has a feature code associated with it.
  const hasRequiredPermission = () : boolean => {
    if (data.cardFeatureCode) {
      const featureCode = data.cardFeatureCode as keyof typeof features.Dashboard;
      return checkFeaturePermission([features.Dashboard[featureCode]]);
    }
    return false;
  };

  return (
    <Flex direction="row" key={data.title}>
      <Card className="homepage-cards">
        <div
          className={data.links ? 'wider-card-comp-outer' : 'card-comp-outer'}
        >
          <CardContent className={data.links ? 'card-content' : ''}>
            <div className="card-comp-title">
              <strong className="card-comp-title-strong">{data.title}</strong>
            </div>
            <div className="card-comp-desc">
              <p className="card-comp-desc-para">{data.description}</p>
            </div>
          </CardContent>
          <CardActions className="card-actions">
            {!data.links ? (
              <Button
                variant="primary"
                name="accessButton"
                disabled={!(
                  secureLS.get('operator') !== ''
                    && secureLS.get('ac_program') !== ''
                ) || data.disabled
                || !hasRequiredPermission()}
                onClick={onClickNavigate}
              >
                {data.buttonName}
              </Button>
            ) : (
              <Row justify="space-evenly" className="btn-row">
                {showMultiBtns}
              </Row>
            )}
          </CardActions>
        </div>
      </Card>
    </Flex>
  );
};

export default CardComponent;
