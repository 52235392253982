import React, { Dispatch, SetStateAction } from 'react';
import DataTableHeader from '../../../Shared/DataTable/DataTableHeader';
import DataTableCell from '../../../Shared/DataTable/DataTableCell';
import EmtlDeltaViewTableCell from './EmtlDeltaViewTableCell';
import { tableColsType } from '../../../Shared/GenericTable/GenericTable';
import TooltipComponent from '../../../Shared/MpdTooltip/mpdTooltip';
import { emtlDeltaViewTooltipData } from '../../../Shared/MpdTooltip/Constants';

const TABLE_TITLE = 'Effective Maintenance Task List';
const NO_SEARCH_COLUMNS = ['Effectivity Calculated', 'Effectivity Adjusted'];
const TABLE_EFFECTIVITY_ACCESSOR = 'effectivity_calculated';

const TableColumnState = {
  Shown: 'shown',
  Hidden: 'hidden',
};

const generateTableColumns: (onClickHandler: Dispatch<SetStateAction<string>>, tableColumns: Array<tableColsType>) => mpdTableColsType[] = (onClickHandler, tableColumns) => (
  tableColumns.map((column) => ({
    Header: <DataTableHeader tooltip={column.accessor === 'effectivity_calculated' ? <TooltipComponent placement="left" title="Effectivity calculated" data={{ data: emtlDeltaViewTooltipData, columnWidth: [1, 11] }} /> : undefined} title={column.Header} id={column.accessor} hideMode={false} onClickHandler={onClickHandler} />,
    title: column.Header,
    accessor: column.accessor,
    id: column.accessor,
    disableSortBy: false,
    width: column.width,
    disableResizing: true,
    Cell: ({ row }: mpdTask) => (column.accessor === TABLE_EFFECTIVITY_ACCESSOR
      ? <EmtlDeltaViewTableCell maxLength={100} deltaValue={row.values[column.accessor]} />
      : <DataTableCell maxLength={100} maxLines={6} cellValue={row.values[column.accessor]} />),
  }))
);

export {
  TABLE_TITLE, NO_SEARCH_COLUMNS, TableColumnState, generateTableColumns,
};
