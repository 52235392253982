export const createDeltaSocTableViewFullfilledReducer = (state: deltaViewSocData, action: deltaViewSocDataFullfilled) => ({
  ...state,
  socTableData: {
    filterData: action.payload.data.filter_data,
    headers: action.payload.data.headers,
    records: action.payload.data.records,
    colorCode: action.payload.data.color_code,
    totalHits: action.payload.totalHits,
  },
  socDeltaViewDataLoading: false,
});

export const createDeltaSocTableViewRejectedReducer = (state: deltaViewSocData) => ({
  ...state,
  socDeltaViewDataLoading: false,
  error: true,
});

export const createDeltaSocTableViewPendingReducer = (state: deltaViewSocData) => ({
  ...state,
  socDeltaViewDataLoading: true,
  error: false,
});

export const createDeltaSocTableViewFilterFullfilledReducer = (state: deltaViewSocData, action: addFilterOptionsFullfilled) => ({
  ...state,
  socDeltaViewFilterData: {
    column_name: action.payload.data.column_name,
    column_value: action.payload.data.column_value,
  },
});

export const clearDeltaSocTableViewFilterReducer = (state: deltaViewSocData) => ({
  ...state,
  socDeltaViewFilterData: {
    column_name: '',
    column_value: [],
  },
});

export const clearAllDeltaSocTableViewDataReducer = (state: deltaViewSocData) => ({
  ...state,
  socTableData: {
    ...state.socTableData,
    records: [],
  },
  socDeltaViewDataLoading: true,
  error: false,
});
