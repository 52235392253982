/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState, ReactElement, SetStateAction, Dispatch, useEffect,
} from 'react';
import {
  Select, Button, Chip, ComboBox, Divider, Row, Col, Typography, IconButton,
} from '@airbus/components-react';
import { Reset } from '@airbus/icons/react';
import { uniqueValues, isColumnUnique } from './utils';
import { taskListFilters, SearchableColumns, filtersValues } from '../../../models/programAnalysis/programAnalysisTypes';
import './TableSearch.scss';

type TableSearchProps = {
  filters: taskListFilters;
  setFilters: Dispatch<SetStateAction<taskListFilters>>;
  data: Record<string, unknown>[];
  columnsOptions: SearchableColumns[];
  updateColumnFilter?(columnName: string): void;
  updateColumnFilterValue?(value: string): void;
  fetchColumnFilterValues?(userInput: string): void;
  updateFilterGroup?(): void;
  deleteFromFilterGroup?(deletePair: searchPair[]): void;
  searchPair?: filtersValues;
  columnFilter?: filtersValues;
  filterGroup?: filtersValues;
  isDataDynamic?: boolean;
  hideFilterChips?: boolean;
  resetFilters?(): void;
  /** If true, the filter chips (which shows the applied filters) will be hidden. */
  noSearchKeySizeLimitColumns?: Array<string>;
  /** List of columns names, for which a keystroke limit to trigger onChange in search component is set to 0.
   *  i.e. The OnChange for the search value will be triggered on every keystroke for these columns.
  */
};

type optionsProps = {
  label: string;
  value: string;
};

const TableSearch = function TableSearch(props: TableSearchProps) {
  const {
    filters, setFilters, data, columnsOptions, isDataDynamic, hideFilterChips, updateColumnFilter, updateColumnFilterValue, fetchColumnFilterValues, updateFilterGroup, deleteFromFilterGroup, searchPair, columnFilter, filterGroup, noSearchKeySizeLimitColumns, resetFilters,
  } = props;
  const usefulFilters = Object.entries(filters).filter((item) => (typeof item[1] === 'string' && item[1] !== '' ? item : null));
  const [select, setSelect] = useState<string | undefined>(columnsOptions[0]?.value || '');
  const [input, setInput] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [options, setOptions] = useState([] as any[]);
  const [searchButton, setSearchButton] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const foundryCol = columnsOptions.filter((item) => item.value === select)[0]?.id;
    if (foundryCol && updateColumnFilter) {
      updateColumnFilter(foundryCol);
    }
    const updateWindowWidht = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateWindowWidht);
    return () => window.removeEventListener('resize', updateWindowWidht);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let result;
    const searchColOptions: string[] = [];
    columnFilter?.forEach((item: any) => {
      const searchValue = item[searchPair?.columnName];
      if (searchValue) {
        searchColOptions.push(String(searchValue));
      }
    });
    result = searchColOptions;
    if (select && !result.length) {
      result = uniqueValues(data, select);
      if (!isDataDynamic || !updateColumnFilterValue) {
        const maxValue = 150;
        result = result.reduce((acc, curr) => {
          const substrValue = Math.min(windowWidth / 20, maxValue);
          const originalLabel = curr.label;
          const substringLabel = originalLabel.substring(0, substrValue);
          const label = originalLabel === substringLabel ? originalLabel : `${substringLabel}...`;
          acc.push({ ...curr, label });
          return acc;
        }, [] as optionsProps[]);
      }
    }
    setOptions(result);
  }, [columnFilter, searchPair?.columnName, data, select, windowWidth, isDataDynamic, updateColumnFilterValue]);

  const displayChips = () => {
    const chipsArr: ReactElement[] = [];
    if (isDataDynamic && deleteFromFilterGroup) {
      filterGroup?.forEach((element: { columnName: string, columnInput: string }, idx: number) => {
        const colLabel = columnsOptions.find((cols) => cols.id === element.columnName);
        chipsArr.push(
          <>
            <Chip
              className="chip-individual"
              label={`${colLabel?.label}: ${element?.columnInput}`}
              onDelete={() => {
                deleteFromFilterGroup([{ columnName: filterGroup[idx].columnName, columnInput: filterGroup[idx].columnInput }]);
              }}
            />
            <Divider vertical />
          </>,
        );
      });
      return chipsArr;
    }
    usefulFilters.forEach(([key, val]) => {
      const columnsObject = columnsOptions.find(({ value }) => key === value);
      chipsArr.push(
        <>
          <Chip
            style={{ whiteSpace: 'normal' }}
            label={`${columnsObject?.label}: ${val}`}
            onDelete={() => {
              setFilters({ ...filters, [key]: '' });
            }}
          />
          <Divider vertical />
        </>,
      );
    });
    return chipsArr;
  };

  const dispatchActionHandler = function rtrTableFilter(delay: number) {
    let waitTimer: any;
    return function setTheTimer(colValue: string) {
      clearTimeout(waitTimer);
      waitTimer = setTimeout(() => {
        if (fetchColumnFilterValues) {
          fetchColumnFilterValues(colValue);
        }
      }, delay);
    };
  };

  const delayDispatch = dispatchActionHandler(300);

  return (
    <>
      <Row className="table-search-cls">
        <Col sm={2} md={2} lg={2} xl={2} xxl={2} className="table-search-legend">Filter by</Col>
        <Col sm={4} md={4} lg={4} xl={4} xxl={4}>
          <Select
            size="small"
            aria-label="Select an option"
            placeholder="Select an option"
            value={select}
            options={columnsOptions}
            onChange={(event) => {
              /* We set the other Combobox to [] if the column is unique
              This is done to prevent the app slowing down significantly */
              if (isDataDynamic && updateColumnFilter) {
                const selectedCol = event?.target.value;
                const foundryCol = columnsOptions.filter((item) => item.value === selectedCol)[0]?.id;
                updateColumnFilter(foundryCol);
                setSelect(selectedCol);
                setSearchButton(false);
                setInput('');
                return;
              }
              setSelect(event?.target.value);
            }}
          />
        </Col>
        <Col style={{ padding: '0px 10px' }} sm={4} md={4} lg={4} xl={4} xxl={4}>
          <ComboBox
            placeholder="Start typing"
            className="table-search-user-input-box"
            size="small"
            options={options}
            aria-label="table-search-searchbox"
            value={input}
            disabled={!select}
            onChange={(val) => {
              /* This is called when the user selects a value from the dropdown */
              if (isDataDynamic && updateColumnFilterValue) {
                updateColumnFilterValue(val);
                setInput(val);
                setSearchButton(val.length !== 0);
                return;
              }
              setInput(val.value);
              setSearchButton(typeof val === 'object' && val !== null);
            }}
            onInput={async (ev) => {
              /* For non-unique columns, we present the user the options he has as he types
              He has to select one otherwise the button will not be available
              For unique columns, we let the user freely type what he wants to search
              in order to not slow down the app significantly by rendering all unique values
              */
              const keySize = noSearchKeySizeLimitColumns?.includes(String(select)) ? 0 : 2;
              if (isDataDynamic) {
                const selectedInput = (ev?.target as HTMLInputElement).value;
                if (selectedInput.length > keySize) {
                  delayDispatch(selectedInput);
                }

                return;
              }
              const castedEv = (ev?.target as HTMLInputElement).value;
              if (castedEv !== '' && isColumnUnique(columnsOptions, select as string)) {
                setSearchButton(true);
                setInput(castedEv);
              } else {
                const isInputInOptions = options.some((item) => {
                  return item.value === castedEv;
                });
                setSearchButton(isInputInOptions);
              }
            }}
          />
        </Col>
        <Col sm={2} md={2} lg={2} xl={2} xxl={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className="table-search-search-button"
            variant="primary"
            size="small"
            disabled={!searchButton}
            onClick={() => {
              setInput('');
              setSearchButton(false);
              if (isDataDynamic && updateFilterGroup) {
                updateFilterGroup();
                setSelect('');
                return;
              }
              setFilters({ ...filters, [select as string]: input });
            }}
          >
            Search
          </Button>
        </Col>
      </Row>
      {((isDataDynamic ? filterGroup?.length : usefulFilters.length) > 0 && !hideFilterChips) ? (
        <Row style={{ marginTop: '10px' }} aria-label="tableSearch-selected-filters">
          <Col sm={2} md={2} lg={2} xl={2} xxl={2} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography>Filter(s):</Typography>
          </Col>
          <Col sm={10} md={10} lg={10} xl={10} xxl={10}>
            <div className="chip-reset-container">
              <div className="chip-style">
                {displayChips()}
              </div>
              {(displayChips().length && (deleteFromFilterGroup || resetFilters)) ? (
                <div className="reset-btn-div">
                  <IconButton
                    variant="ghost"
                    size="small"
                    className="reset-btn"
                    aria-label="Reset-chips"
                    onClick={() => {
                      if (deleteFromFilterGroup) {
                        filterGroup?.forEach(
                          (element: { columnName: string, columnInput: string }) => deleteFromFilterGroup([{
                            columnName: element.columnName,
                            columnInput: element.columnInput,
                          }]),
                        );
                      } else
                        // Mandatory "ignore else" because of Sonar, else case is not possible because of the above condition
                        /* istanbul ignore else */
                        if (resetFilters) {
                          resetFilters();
                        }
                    }}
                  >
                    <Reset />
                    Clear All
                  </IconButton>
                </div>
              ) : (
                <div />
              ) }
            </div>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

TableSearch.defaultProps = {
  isDataDynamic: false,
  hideFilterChips: false,
  updateColumnFilter: undefined,
  updateColumnFilterValue: undefined,
  fetchColumnFilterValues: undefined,
  updateFilterGroup: undefined,
  deleteFromFilterGroup: undefined,
  searchPair: {},
  columnFilter: [],
  filterGroup: [],
  noSearchKeySizeLimitColumns: [],
  resetFilters: undefined,
};

export default TableSearch;
