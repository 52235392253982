import React, {
  useState, useRef, useEffect,
} from 'react';
import { IconButton, Chip, Inline } from '@airbus/components-react';
import { Close } from '@airbus/icons/react';
import './NotificationComponent.scss';
import SpinnerComponent from '../Spinner/SpinnerComponent';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import { RootState } from '../../store/store';
import { fetchNotificationData } from '../../models/BellNotificationModel/BellNotificationAsyncThunk';
import { ReadNotifications, Tag } from '../../models/BellNotificationModel/BellNotificationTypes';
import { stopNotification } from '../../models/BellNotificationModel/BellNotificationSlice';

/* eslint-disable react/no-danger */
type NotificationProps = {
  handleNotificationClose: () => void;
  isClickOutsideBell: (target: EventTarget | null) => boolean;
};

const NOTIFICATION_LIMIT = 5;
const NOTIFICATION_OFFSET = 5;

const NotificationComponent = (props: NotificationProps) => {
  const { handleNotificationClose, isClickOutsideBell } = props;
  const {
    notifications, unreadCount, isScrollStopped,
  } = useAppSelector((state: RootState) => state.BellNotification);
  const [offset, setoffset] = useState(NOTIFICATION_OFFSET);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    // Checks if a click event occurred outside of the referenced elements bellIconRef and popup ref
    const handleClickOutside = (event: MouseEvent) => {
      if ((ref.current && !ref.current.contains(event.target as Node)) && isClickOutsideBell(event.target)) {
        handleNotificationClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, isClickOutsideBell, handleNotificationClose]);

  // Load more notification data, based on new offset and limit value
  const loadMoreNotifications = () => {
    dispatch(fetchNotificationData(offset, NOTIFICATION_LIMIT)())
      .unwrap().then((results: ReadNotifications) => {
        if (!results.notifications.length) {
          dispatch(stopNotification());
        }
        setisLoading(false);
      }).catch(() => {
        setisLoading(false);
      });
    const newOffset = offset + NOTIFICATION_LIMIT;
    setoffset(newOffset);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (event.currentTarget.scrollHeight - event.currentTarget.scrollTop <= event.currentTarget.clientHeight + 1) {
      // load more data when user scroll down
      if (!isScrollStopped && !isLoading) {
        setisLoading(true);
        loadMoreNotifications();
      }
    }
  };

  return (
    <div className="notification-panel" ref={ref}>
      <div className="notification-status">
        <div>
          {unreadCount > 0
            ? `Notifications (${unreadCount})`
            : 'Notifications'}
        </div>
        <IconButton variant="ghost" size="small" aria-label="close-notification-modal" onClick={handleNotificationClose}>
          <Close />
        </IconButton>
      </div>
      <div className="notification-block" onScroll={handleScroll} data-testid="notification-block">
        {notifications.length > 0 ? notifications.map((notification) => (
          <div
            key={notification.notificationId}
            className={`notification-info ${
              notification.unread ? 'unread' : 'read'
            }`}
          >
            <div className="notification-top">
              <span className="title">{notification.title}</span>
              <span>
                <Inline spacing="1-x" className="tags-align">
                  {notification.tags?.map((tag: Tag) => (
                    <Chip
                      key={tag.label}
                      className={`${tag.type}`}
                      aria-label={tag.label}
                      label={tag.label}
                      variant={tag.type}
                      size="xsmall"
                    />
                  ))}
                </Inline>
              </span>
              <span className="timestamp">{notification.publishAt}</span>
            </div>
            <div className="notification-description" dangerouslySetInnerHTML={{ __html: notification.message }} />
          </div>
        )) : <div className="no-notification">You have no new notifications.</div>}
        {isLoading && (
          <div className="loading-indicator" data-testid="spinner-component">
            <SpinnerComponent size="small" label="Loading..." />
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationComponent;
