/* eslint-disable no-param-reassign */
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  fetchTaskInfoFleetLevelFindingChartForTada, fetchTaskInfoFleetLevelGeoRegionChartForTada, fetchTaskInfoOperatorLevelFindingChartForTada, fetchTaskInfoOperatorLevelFindingRateByMsnForTada,
  fetchTaskReportOperatorCardForTada,
  fetchTaskReportWorldWideFleetCardForTada,
} from './taskreportAsyncThunks';

const tadaExtraReducers = (builder: ActionReducerMapBuilder<TaskReportState>) => {
  // msn chart
  builder
    .addCase(fetchTaskInfoOperatorLevelFindingRateByMsnForTada().pending, (state) => {
      state.tada.taskInfoOperatorLevelFindingRateByMsn.loading = true;
      state.tada.taskInfoOperatorLevelFindingRateByMsn.status = 'idle';
    })
    .addCase(fetchTaskInfoOperatorLevelFindingRateByMsnForTada().fulfilled, (state, { payload: { data } }) => {
      state.tada.taskInfoOperatorLevelFindingRateByMsn.loading = false;
      state.tada.taskInfoOperatorLevelFindingRateByMsn.status = 'succeeded';
      state.tada.taskInfoOperatorLevelFindingRateByMsn.error = '';
      state.tada.taskInfoOperatorLevelFindingRateByMsn.httpStatus = 200;
      state.tada.taskInfoOperatorLevelFindingRateByMsn.id_aircraft = data?.id_aircraft || [];
      state.tada.taskInfoOperatorLevelFindingRateByMsn.all_finding_per_id_aircraft_count = data?.all_finding_per_id_aircraft_count || [];
      state.tada.taskInfoOperatorLevelFindingRateByMsn.nil_finding_per_id_aircraft_count = data?.nil_finding_per_id_aircraft_count || [];
      state.tada.taskInfoOperatorLevelFindingRateByMsn.finding_rate_per_id_aircraft_count = data?.finding_rate_per_id_aircraft_count || [];
    })
    .addCase(fetchTaskInfoOperatorLevelFindingRateByMsnForTada().rejected, (state, action: APIRejectedAction) => {
      state.tada.taskInfoOperatorLevelFindingRateByMsn.loading = false;
      state.tada.taskInfoOperatorLevelFindingRateByMsn.status = 'failed';
      state.tada.taskInfoOperatorLevelFindingRateByMsn.error = action.payload?.error;
      state.tada.taskInfoOperatorLevelFindingRateByMsn.httpStatus = action.payload?.httpStatus;
    });
  // geo region chart
  builder
    .addCase(fetchTaskInfoFleetLevelGeoRegionChartForTada().pending, (state) => {
      state.tada.findingRateByGeoRegion.loading = true;
      state.tada.findingRateByGeoRegion.status = 'idle';
    })
    .addCase(fetchTaskInfoFleetLevelGeoRegionChartForTada().fulfilled, (state, { payload: { data } }) => {
      state.tada.findingRateByGeoRegion.loading = false;
      state.tada.findingRateByGeoRegion.status = 'succeeded';
      state.tada.findingRateByGeoRegion.error = '';
      state.tada.findingRateByGeoRegion.httpStatus = 200;
      state.tada.findingRateByGeoRegion.all_climate_regions = data?.all_climate_regions || [];
      state.tada.findingRateByGeoRegion.climate_fleet_finding_rate = data?.climate_fleet_finding_rate || [];
    })
    .addCase(fetchTaskInfoFleetLevelGeoRegionChartForTada().rejected, (state, action: APIRejectedAction) => {
      state.tada.findingRateByGeoRegion.loading = false;
      state.tada.findingRateByGeoRegion.status = 'failed';
      state.tada.findingRateByGeoRegion.error = action.payload?.error;
      state.tada.findingRateByGeoRegion.httpStatus = action.payload?.httpStatus;
    });
  // operator level finding chart
  builder
    .addCase(fetchTaskInfoOperatorLevelFindingChartForTada().pending, (state) => {
      state.tada.taskInfoOperatorLevelFindingChart.loading = true;
      state.tada.taskInfoOperatorLevelFindingChart.status = 'idle';
    })
    .addCase(fetchTaskInfoOperatorLevelFindingChartForTada().fulfilled, (state, { payload: { data } }) => {
      state.tada.taskInfoOperatorLevelFindingChart.loading = false;
      state.tada.taskInfoOperatorLevelFindingChart.status = 'succeeded';
      state.tada.taskInfoOperatorLevelFindingChart.error = '';
      state.tada.taskInfoOperatorLevelFindingChart.httpStatus = 200;
      state.tada.taskInfoOperatorLevelFindingChart.operator_finding_list = data?.operator_finding_list || [];
      state.tada.taskInfoOperatorLevelFindingChart.operator_finding_avg_count = data?.operator_finding_avg_count || [];
    })
    .addCase(fetchTaskInfoOperatorLevelFindingChartForTada().rejected, (state, action: APIRejectedAction) => {
      state.tada.taskInfoOperatorLevelFindingChart.loading = false;
      state.tada.taskInfoOperatorLevelFindingChart.status = 'failed';
      state.tada.taskInfoOperatorLevelFindingChart.error = action.payload?.error;
      state.tada.taskInfoOperatorLevelFindingChart.httpStatus = action.payload?.httpStatus;
    });
  // fleet level finding chart
  builder
    .addCase(fetchTaskInfoFleetLevelFindingChartForTada().pending, (state) => {
      state.tada.fleetWideFindingsByFindingType.loading = true;
      state.tada.fleetWideFindingsByFindingType.status = 'idle';
    })
    .addCase(fetchTaskInfoFleetLevelFindingChartForTada().fulfilled, (state, { payload: { data } }) => {
      state.tada.fleetWideFindingsByFindingType.loading = false;
      state.tada.fleetWideFindingsByFindingType.status = 'succeeded';
      state.tada.fleetWideFindingsByFindingType.error = '';
      state.tada.fleetWideFindingsByFindingType.httpStatus = 200;
      state.tada.fleetWideFindingsByFindingType.fleet_finding_list = data?.fleet_finding_list || [];
      state.tada.fleetWideFindingsByFindingType.fleet_finding_avg_count = data?.fleet_finding_avg_count || [];
    })
    .addCase(fetchTaskInfoFleetLevelFindingChartForTada().rejected, (state, action: APIRejectedAction) => {
      state.tada.fleetWideFindingsByFindingType.loading = false;
      state.tada.fleetWideFindingsByFindingType.status = 'failed';
      state.tada.fleetWideFindingsByFindingType.error = action.payload?.error;
      state.tada.fleetWideFindingsByFindingType.httpStatus = action.payload?.httpStatus;
    });
  // fleet card
  builder
    .addCase(fetchTaskReportWorldWideFleetCardForTada().pending, (state) => {
      state.tada.taskInfoFleetCard.loading = true;
      state.tada.taskInfoFleetCard.status = 'idle';
    })
    .addCase(fetchTaskReportWorldWideFleetCardForTada().fulfilled, (state, { payload: { data } }) => {
      state.tada.taskInfoFleetCard.loading = false;
      state.tada.taskInfoFleetCard.status = 'succeeded';
      state.tada.taskInfoFleetCard.error = '';
      state.tada.taskInfoFleetCard.httpStatus = 200;
      state.tada.taskInfoFleetCard.world_wide_finding_rate = data.length ? data[0].world_wide_finding_rate : '';
      state.tada.taskInfoFleetCard.world_wide_enhanced_finding_rate = data.length ? data[0].world_wide_enhanced_finding_rate : '';
    })
    .addCase(fetchTaskReportWorldWideFleetCardForTada().rejected, (state, action: APIRejectedAction) => {
      state.tada.taskInfoFleetCard.loading = false;
      state.tada.taskInfoFleetCard.status = 'failed';
      state.tada.taskInfoFleetCard.error = action.payload?.error;
      state.tada.taskInfoFleetCard.httpStatus = action.payload?.httpStatus;
    });
  // operator card
  builder
    .addCase(fetchTaskReportOperatorCardForTada().pending, (state) => {
      state.tada.taskReportAnalysisOperatorCard.loading = true;
      state.tada.taskReportAnalysisOperatorCard.status = 'idle';
    })
    .addCase(fetchTaskReportOperatorCardForTada().fulfilled, (state, { payload: { data } }) => {
      state.tada.taskReportAnalysisOperatorCard.loading = false;
      state.tada.taskReportAnalysisOperatorCard.status = 'succeeded';
      state.tada.taskReportAnalysisOperatorCard.error = '';
      state.tada.taskReportAnalysisOperatorCard.httpStatus = 200;
      state.tada.taskReportAnalysisOperatorCard.operator_finding_rate = data.length ? data[0].operator_finding_rate : '';
      state.tada.taskReportAnalysisOperatorCard.total_tr = data.length ? data[0].total_tr : 'PA';
      state.tada.taskReportAnalysisOperatorCard.total_ac_reported = data.length ? data[0].total_ac_reported : 'PA';
      state.tada.taskReportAnalysisOperatorCard.sufficient_reported_data_for_optimization = data.length ? data[0].sufficient_reported_data_for_optimization : 'PA';
      state.tada.taskReportAnalysisOperatorCard.sufficent_reported_bar = data.length ? data[0].sufficent_reported_bar : 'PA';
      state.tada.taskReportAnalysisOperatorCard.sufficient_task_report = data.length ? data[0].sufficient_task_report : 'PA';
      state.tada.taskReportAnalysisOperatorCard.avg_yield = data.length ? data[0].avg_yield : 'PA';
      state.tada.taskReportAnalysisOperatorCard.ffr = data.length ? data[0].ffr : 'PA';
      state.tada.taskReportAnalysisOperatorCard.me = data.length ? data[0].me : 'PA';
    })
    .addCase(fetchTaskReportOperatorCardForTada().rejected, (state, action: APIRejectedAction) => {
      state.tada.taskReportAnalysisOperatorCard.loading = false;
      state.tada.taskReportAnalysisOperatorCard.status = 'failed';
      state.tada.taskReportAnalysisOperatorCard.error = action.payload?.error;
      state.tada.taskReportAnalysisOperatorCard.httpStatus = action.payload?.httpStatus;
    });
};

export default tadaExtraReducers;
